import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    username_verify: { status: app_consts.IDLE },
}

export const username_verify_Slice = createSlice({
    name: "username_verify",
    initialState,
    reducers: {
        success_username_verify: (state, action) => {
            state.username_verify = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_username_verify: (state, action) => {
            state.username_verify = { status: app_consts.FAIL, message: action.payload };
        },
        reset_username_verify: (state) => {
            state.username_verify = { status: app_consts.IDLE }
        },
        loading_username_verify: (state) => {
            state.username_verify = { status: app_consts.LOADING }
        },
    }
})

export const { success_username_verify, fail_username_verify, reset_username_verify, loading_username_verify } = username_verify_Slice.actions;

export default username_verify_Slice.reducer;