import { toast } from "react-toastify";
import * as app_consts from "../constants/react_app_consts";

//url for production
export var url = "";
if (process.env.NODE_ENV === "development")
{
  url = "";
} else
{
  url = window.location.host.split("/")[1];
  if (url)
  {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "")
    {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++)
  {
    if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== " ")
    {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1)
  {
    return extractedString[0] + extractedString[1];
  } else
  {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to set deadline for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);
  return date;
};

//Month Names
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//todays Date
export const todaysDate = new Date();

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

//shortens a long string
export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n) : str;
};

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

// ---------------------------- Toast Messages ---------------------------
export const messageToast = (message, type) => {
  if (type === app_consts.SUCCESS)
  {
    toast.success(message, {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: true,
      theme: "light",
      autoClose: 5000,
      progress: undefined,
    });
  } else if (type === app_consts.FAIL)
  {
    toast.error(message, {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: true,
      theme: "light",
      autoClose: 5000,
      progress: undefined,
    });
  }
};

// Mapping Data for RSelect
export const rSelectMapFun = async (label, value) => {
  return {
    label: label,
    value: value,
  };
};

// Retrieving Page Actions to Dynamically Map Content
export const retrieve_actions_from_one_object = (page_data, client_data) => {
  // Filter Module an Return Actions
  const module_id = page_data.find((data) => data.module_id === client_data.module_id);
  // Search Through Module Actions for the Page
  if (module_id)
  {
    // Retrieve Module Actions as An Object
    let matching_array = [];

    module_id?.module_actions?.map((mod_act) => {
      matching_array.push(mod_act.module_action_id);
    });

    // Filter Action IDs
    const result_filter = client_data?.module_actions?.filter((action) =>
      action.module_action_id.some((id) => matching_array.includes(id))
    );

    return result_filter;
  }
};

// Searching for Data From At Least a Given Amount of Characters
export const onOptionsSearch = (option) => {
  if (option.length > 4)
  {
    return { option: option, setSearch: true };
  } else
  {
    return { option: "", setSearch: false };
  }
};

// Image Compress and COnvert to Base64
export const compressAndConvertToBase64 = async (file, file_name, callback) => {
  const reader = new FileReader();
  const maxWidth = 800;
  const maxHeight = 600;
  const quality = 0.6;

  reader.onload = async (event) => {
    const originalImage = new Image();
    originalImage.src = event.target.result;

    originalImage.onload = async () => {
      const canvas = document.createElement("canvas");
      let width = originalImage.width;
      let height = originalImage.height;

      if (width > maxWidth)
      {
        height *= maxWidth / width;
        width = maxWidth;
      }
      if (height > maxHeight)
      {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(originalImage, 0, 0, width, height);

      const compressedDataURL = canvas.toDataURL("image/jpeg", quality);

      // Convert the compressed data URL to a Blob
      const compressedImageBlob = await fetch(compressedDataURL).then((response) => response.blob());

      // Read the compressed image as Base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const compressedBase64 = reader.result;
        // Convert to Blob and then Return as File
        const compressedFile = new File([compressedImageBlob], file_name, { type: "image/jpeg" });

        callback(null, compressedBase64, compressedFile);
      };
      reader.onerror = () => {
        callback(new Error("Failed to read the compressed image."), null);
      };
      reader.readAsDataURL(compressedImageBlob);
    };

    originalImage.onerror = () => {
      callback(new Error("Failed to load the image."), null);
    };
  };

  reader.onerror = () => {
    callback(new Error("Failed to read the image file."), null);
  };

  reader.readAsDataURL(file);
};

// function App() {
//   const handleImageUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       compressImage(file, (compressedImageBlob) => {
//         // Do something with the compressed image blob here, like uploading to a server or displaying it.
//         const compressedImageUrl = URL.createObjectURL(compressedImageBlob);
//         console.log(compressedImageUrl);
//       });
//     }
//   };

//   return (
//     <div>
//       <input type="file" accept="image/*" onChange={handleImageUpload} />
//     </div>
//   );
// }

// Reding Document as Url
export const document_read = async (file, callback) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (e) => {
    callback(e.target.result);
  };
};
