import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { Alert, Spinner } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewCard,
  Row,
} from "../../../../components/Component";
import PageHeading from "../../components/page_heading/PageHeading";
import { useNavigate, useParams } from "react-router";
import TextInput from "../../../components/inputs/TextInput";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { dashboard_actions_helper } from "../../../../actions/dashboard_actions_helper";
import {
  fail_specific_user_details,
  loading_specific_user_details,
  reset_specific_user_details,
  success_specific_user_details,
} from "../../../../reducers/dashboard_reducers/user/specific_user_details_Slice";
import {
  CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG,
  CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_ERR_MSG,
  CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_SUCCESS_MSG,
  RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ACCESS_DENIED_MSG,
  RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ERR_MSG,
  RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_SUCCESS_MSG,
} from "../../../../constants/success_error_messages_consts";
import { CREATE_TRUCK_DRIVER_ACCOUNT, RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED } from "../../../../api/dashboard_api_data";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../constants/react_app_consts";
import { compressAndConvertToBase64, messageToast } from "../../../../utils/Utils";
import ModalImage from "../../components/modal/modal_image/ModalImage";
import {
  fail_create_truck_driver,
  loading_create_truck_driver,
  reset_create_truck_driver,
  success_create_truck_driver,
} from "../../../../reducers/dashboard_reducers/user_accounts/create_truck_driver_Slice";

const initialState = {
  driver_license_number: "",
  driver_license_front_image: "",
  driver_license_back_image: "",
  phone_number: "",
};
const initialPageImages = { driver_license_front_image: "", driver_license_back_image: "" };

const AssignTruckDriver = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorVal, setError] = useState("");
  const { phone_number } = useParams();
  const [formData, setFormData] = useState(initialState);
  const [driver_license_front_image, setDriverLicenseFrontImage] = useState([]);
  const [driver_license_back_image, setDriverLicenseBackImage] = useState([]);
  const [prev_Images, setPrevImages] = useState(initialPageImages);
  const [loading, setIsLoading] = useState(false);
  const [acc_info, setAccInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [clickedImage, setClickedImage] = useState("");

  // Retrieve User
  useEffect(() => {
    setFormData({ ...formData, ["phone_number"]: phone_number });
    dispatch(
      dashboard_actions_helper(
        loading_specific_user_details,
        success_specific_user_details,
        fail_specific_user_details,
        RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ERR_MSG,
        RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_SUCCESS_MSG,
        app_consts.POST,
        RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED,
        { phone_number: phone_number },
        RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ACCESS_DENIED_MSG
      )
    );
  }, []);

  // UseSelector to Listen to Changes in Retrival of Users
  const retrieved_user_account = useSelector((state) => state.specific_user_details.specific_user_details);
  //
  useEffect(() => {
    if (retrieved_user_account.status !== app_consts.IDLE) {
      if (retrieved_user_account.status !== app_consts.LOADING) {
        if (retrieved_user_account.status === app_consts.FAIL) {
          setError(retrieved_user_account.message);
        } else if (retrieved_user_account.status === app_consts.SUCCESS) {
          setAccInfo(retrieved_user_account.data);
        }
        dispatch(reset_specific_user_details());
      }
    }
  }, [retrieved_user_account]);

  // Function to Handle Image Upload
  const handleDropChange = (acceptedFiles, set, type) => {
    // convert to base 64 and store in State
    const image = acceptedFiles[0];
    if (!image) {
      messageToast("Select valid image (.jpeg or .jpg).", app_consts.FAIL);
      return false;
    }
    if (!image.name.match(/\.(jpg|jpeg)$/)) {
      messageToast("Select valid image (.jpeg or .jpg).", app_consts.FAIL);
      return false;
    } else {
      compressAndConvertToBase64(image, type, (error, compressedBase64, compressedFile) => {
        if (error) {
          console.error(error);
          // Handle the error case
        } else {
          setFormData({ ...formData, [type]: compressedFile });
          setPrevImages({ ...prev_Images, [type]: compressedBase64 });
          set(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        }
      });
    }
  };

  // Image Modal
  const viewImage = () => {
    setOpen(true);
  };

  // Handle Submit
  const handleSubmit = () => {
    setError("");
    setIsLoading(true);
    dispatch(
      dashboard_actions_helper(
        loading_create_truck_driver,
        success_create_truck_driver,
        fail_create_truck_driver,
        CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_ERR_MSG,
        CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_SUCCESS_MSG,
        app_consts.POST,
        CREATE_TRUCK_DRIVER_ACCOUNT,
        formData,
        CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Reducer After Submitting Form
  const create_truck_driver = useSelector((state) => state.create_truck_driver.create_truck_driver);
  // UseEffect
  useEffect(() => {
    if (create_truck_driver.status !== app_consts.IDLE) {
      if (create_truck_driver.status === app_consts.LOADING) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
        if (create_truck_driver.status === app_consts.FAIL) {
          setError(create_truck_driver.message);
        } else if (create_truck_driver.status === app_consts.SUCCESS) {
          messageToast(
            `Truck Driver Account has been Successfully Created for ${acc_info.first_name} ${acc_info.surname}.`,
            app_consts.SUCCESS
          );
          navigate(`/dashboard/onboarding/user/assign/${phone_number}`);
        }
        dispatch(reset_create_truck_driver());
      }
    }
  }, [create_truck_driver]);

  return (
    <>
      <Head title={`Assign Truck Driver Account`} />
      <Content>
        <div className="w-90 m-auto">
          <PageHeading
            title="Assign Truck Driver"
            title_addition={
              acc_info.first_name ? `/ ${acc_info.first_name} ${acc_info.surname} - ${acc_info.phone_number}` : ""
            }
            description="Create Truck Driver Account for User By Filling in and Submiting the form Below."
            backToText="Assign Account"
            backToLink={`/dashboard/onboarding/user/assign/${phone_number}`}
          />
          <Block size="lg">
            <PreviewCard>
              <BlockHead>
                <BlockHeadContent className="text-center">
                  <BlockTitle tag="h4">Truck Driver Form</BlockTitle>
                  <p>Please Complete the Form Below</p>
                </BlockHeadContent>
              </BlockHead>
              {errorVal && (
                <div className="mb-3 w-100">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" /> {errorVal}{" "}
                  </Alert>
                </div>
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Row className="g-gs flex mb-2">
                  <TextInput
                    label="Driver License Number"
                    required
                    type="text"
                    id_val="trailer_plate"
                    maxlength="10"
                    value={formData.driver_license_number}
                    colSize="6"
                    name="trailer_plate"
                    handleChange={(e) => setFormData({ ...formData, ["driver_license_number"]: e.target.value })}
                  />
                  <Col size="12">
                    <Row className="g-gs flex mb-2">
                      <Col sm="6">
                        <label className="form-label">Driver's License Front</label>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleDropChange(acceptedFiles, setDriverLicenseFrontImage, "driver_license_front_image")
                          }
                          maxFiles={1}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                <input {...getInputProps()} />
                                {formData.driver_license_front_image.length === 0 && (
                                  <div className="dz-message">
                                    <span className="dz-message-text">Drag and drop file</span>
                                    <span className="dz-message-or">or</span>
                                    <Button color="primary">SELECT</Button>
                                  </div>
                                )}
                                {driver_license_front_image.map((file) => (
                                  <div
                                    key={file.name}
                                    className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                                  >
                                    <div className="dz-image">
                                      <img src={file.preview} alt="preview" />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {prev_Images.driver_license_front_image ? (
                          <>
                            <div className="form-group mt-2">
                              <div className="form-control-wrap-flex-wrap justify-end">
                                <Button
                                  onClick={(ev) => {
                                    setClickedImage(prev_Images.driver_license_front_image);
                                    viewImage();
                                  }}
                                  color="light"
                                  outline
                                  className="bg-white"
                                  size="md"
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col sm="6">
                        <label className="form-label">Driver's Licesne Back</label>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleDropChange(acceptedFiles, setDriverLicenseBackImage, "driver_license_back_image")
                          }
                          maxFiles={1}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                <input {...getInputProps()} />
                                {formData.driver_license_back_image.length === 0 && (
                                  <div className="dz-message">
                                    <span className="dz-message-text">Drag and drop file</span>
                                    <span className="dz-message-or">or</span>
                                    <Button color="primary">SELECT</Button>
                                  </div>
                                )}
                                {driver_license_back_image.map((file) => (
                                  <div
                                    key={file.name}
                                    className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                                  >
                                    <div className="dz-image">
                                      <img src={file.preview} alt="preview" />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {prev_Images.driver_license_back_image ? (
                          <>
                            <div className="form-group mt-2">
                              <div className="form-control-wrap-flex-wrap justify-end">
                                <Button
                                  onClick={(ev) => {
                                    setClickedImage(prev_Images.driver_license_back_image);
                                    viewImage();
                                  }}
                                  color="light"
                                  outline
                                  className="bg-white"
                                  size="md"
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="12" className="d-flex justify-content-end">
                    <Button
                      onClick={handleSubmit}
                      size="lg"
                      type="submit"
                      color="primary"
                      disabled={
                        loading
                          ? loading
                          : !(
                              formData.driver_license_back_image &&
                              formData.driver_license_front_image &&
                              formData.driver_license_number &&
                              formData.phone_number
                            )
                      }
                    >
                      {loading ? <Spinner size="sm" color="light" /> : "Assign Truck Driver Account"}
                    </Button>
                  </Col>
                </Row>
              </form>
            </PreviewCard>
          </Block>
        </div>
      </Content>
      {/* Image Modal */}
      <ModalImage
        modalOpen={open}
        setModalOpen={setOpen}
        img_type={app_consts.IMAGE_SINGLE}
        selectedImage={clickedImage}
      />
    </>
  );
};

export default AssignTruckDriver;
