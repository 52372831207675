import React, { useEffect, useState } from "react";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../components/Component";
import { Alert, Spinner } from "reactstrap";
import ListText from "../../../text/ListText";
import TextInput from "../../../../../components/inputs/TextInput";
import { Link } from "react-router-dom";
import { dashboard_actions_helper } from "../../../../../../actions/dashboard_actions_helper";
import {
  fail_active_users_list,
  loading_active_users_list,
  reset_active_users_list,
  success_active_users_list,
} from "../../../../../../reducers/dashboard_reducers/user/active_users_list_Slice";
import {
  ADD_COMPANY_REPRESENTATIVE_ACCESS_DENIED_MSG,
  ADD_COMPANY_REPRESENTATIVE_ERR_MSG,
  ADD_COMPANY_REPRESENTATIVE_SUCCESS_MSG,
  RETRIEVE_ACTIVE_USERS_ACCESS_DENIED_MSG,
  RETRIEVE_ACTIVE_USERS_ERR_MSG,
  RETRIEVE_ACTIVE_USERS_SUCCESS_MSG,
  RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_ERR_MSG,
  RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_SUCCESS_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import {
  ADD_COMPANY_REPRESENTATIVE,
  RETRIEVE_ACTIVE_COMPLETE_KYC_LIST,
  RETRIEVE_COMPANY_REPRESENTATIVE_ROLES,
} from "../../../../../../api/dashboard_api_data";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../../../constants/react_app_consts";
import {
  fail_retrieve_company_representatives_role_list,
  loading_retrieve_company_representatives_role_list,
  reset_retrieve_company_representatives_role_list,
  success_retrieve_company_representatives_role_list,
} from "../../../../../../reducers/dashboard_reducers/company_representative/retrieve_company_representatives_role_list_Slice";
import { messageToast } from "../../../../../../utils/Utils";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { name_phone_number_splitter } from "../../../../../../utils/functionalUtils";
import {
  fail_add_company_representative,
  loading_add_company_representative,
  reset_add_company_representative,
  success_add_company_representative,
} from "../../../../../../reducers/dashboard_reducers/company_representative/add_company_representative_Slice";

const initialState = { company_representative_role_id: "", user_account_id: "", phone_number: "" };
const previewState = {
  company_representative_name: "",
  company_representative_role_name: "",
  company_representative_role_phone_number: "",
};

const AddCompanyRepresentativeModal = ({ openOtherModalFunc, openModal, accountId, successFunction }) => {
  const [step, setStep] = useState(1);
  const [errorVal, setError] = useState("");
  const dispatch = useDispatch();
  const [user_list_data, setUserListData] = useState([]);
  const [isSearch, setisSearch] = useState(false);
  const [onSerchText, setOnSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [userLoading, setUserLoading] = useState(true);
  const [comp_rep_data, setCompRepData] = useState([]);
  const [compRepLoading, setCompRepLoading] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const [previewData, setPreviewData] = useState(previewState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (accountId) {
      setFormData({ ...formData, ["user_account_id"]: accountId });
    }
  }, [accountId]);

  const prevStep = () => {
    setStep(1);
    setFormData({ ...formData, ["company_representative_role_id"]: "", ["phone_number"]: "" });
  };

  // UseEffect to Listen to Modal Opening and Closing State
  useEffect(() => {
    if (openModal) {
      retrieveActiveUsersList();
      retrieveCompRepresentativesList();
    }
  }, [openModal]);

  // Function to Receive list of Users
  const retrieveActiveUsersList = () => {
    dispatch(
      dashboard_actions_helper(
        loading_active_users_list,
        success_active_users_list,
        fail_active_users_list,
        RETRIEVE_ACTIVE_USERS_ERR_MSG,
        RETRIEVE_ACTIVE_USERS_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_ACTIVE_COMPLETE_KYC_LIST,
        null,
        RETRIEVE_ACTIVE_USERS_ACCESS_DENIED_MSG,
        app_consts.MAP,
        app_consts.USER_MAP
      )
    );
  };

  // Retrieve Company Representative Roles List
  const retrieveCompRepresentativesList = () => {
    dispatch(
      dashboard_actions_helper(
        loading_retrieve_company_representatives_role_list,
        success_retrieve_company_representatives_role_list,
        fail_retrieve_company_representatives_role_list,
        RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_ERR_MSG,
        RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_COMPANY_REPRESENTATIVE_ROLES,
        null,
        RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_ACCESS_DENIED_MSG,
        app_consts.MAP,
        app_consts.COMPANY_REPRESENTATIVE_ROLES_MAP
      )
    );
  };

  // Use Selector to Listen to User's State
  const active_users_list = useSelector((state) => state.active_users_list.active_users_list);

  useEffect(() => {
    if (active_users_list.status !== app_consts.IDLE) {
      if (active_users_list.status === app_consts.LOADING) {
        setUserLoading(true);
      } else {
        setUserLoading(false);
        if (active_users_list.status === app_consts.FAIL) {
          messageToast(active_users_list.message, app_consts.FAIL);
        } else if (active_users_list.status === app_consts.SUCCESS) {
          setUserListData(active_users_list.data);
        }
        dispatch(reset_active_users_list());
      }
    }
  }, [active_users_list]);

  // Use Selector to Listen to Representatives Role State
  const retrieve_company_representatives_role_list = useSelector(
    (state) => state.retrieve_company_representatives_role_list.retrieve_company_representatives_role_list
  );

  useEffect(() => {
    if (retrieve_company_representatives_role_list.status !== app_consts.IDLE) {
      if (retrieve_company_representatives_role_list.status === app_consts.LOADING) {
        setCompRepLoading(true);
      } else {
        setCompRepLoading(false);
        if (retrieve_company_representatives_role_list.status === app_consts.FAIL) {
          messageToast(retrieve_company_representatives_role_list.message, app_consts.FAIL);
        } else if (retrieve_company_representatives_role_list.status === app_consts.SUCCESS) {
          setCompRepData(retrieve_company_representatives_role_list.data);
        }
        dispatch(reset_retrieve_company_representatives_role_list());
      }
    }
  }, [retrieve_company_representatives_role_list]);

  //function to declare the state change
  const onActionText = (e) => {
    if (e.length > 4) {
      setOnSearchText(e);
      setisSearch(true);
    } else {
      setOnSearchText("");
      setisSearch(false);
    }
  };

  //Function to Initiate Search
  //Changing state value when searching name
  useEffect(() => {
    if (isSearch) {
      const filteredObject = user_list_data.filter((item) => {
        return (
          item.label.toLowerCase().includes(onSerchText.toLowerCase()) ||
          item.value.toLowerCase().includes(onSerchText.toLowerCase())
        );
      });
      setSearchOptions([...filteredObject]);
    } else {
      setSearchOptions([]);
      setOnSearchText("");
    }
  }, [onSerchText]);

  const handlePreview = () => {
    setError("");
    setStep(2);
  };

  const handleSubmit = () => {
    dispatch(
      dashboard_actions_helper(
        loading_add_company_representative,
        success_add_company_representative,
        fail_add_company_representative,
        ADD_COMPANY_REPRESENTATIVE_ERR_MSG,
        ADD_COMPANY_REPRESENTATIVE_SUCCESS_MSG,
        app_consts.POST,
        ADD_COMPANY_REPRESENTATIVE,
        formData,
        ADD_COMPANY_REPRESENTATIVE_ACCESS_DENIED_MSG
      )
    );
  };

  // LIisten to State of Adding a Company Representative
  const add_company_representative = useSelector(
    (state) => state.add_company_representative.add_company_representative
  );

  useEffect(() => {
    if (add_company_representative.status !== app_consts.IDLE) {
      if (add_company_representative.status === app_consts.LOADING) {
        setLoading(true);
      } else {
        setLoading(false);
        if (add_company_representative.status === app_consts.FAIL) {
          setError(add_company_representative.message);
          messageToast(add_company_representative.message, app_consts.FAIL);
        } else if (add_company_representative.status === app_consts.SUCCESS) {
          messageToast(ADD_COMPANY_REPRESENTATIVE_SUCCESS_MSG, app_consts.SUCCESS);
          successFunction(add_company_representative.data);
        }
        dispatch(reset_add_company_representative());
      }
    }
  }, [add_company_representative]);

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h5 className="card-title text-start w-100">Onboard Company Representative</h5>
        {step === 2 && (
          <div
            className="nk-block-head-sub mb-2"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              prevStep();
            }}
          >
            <Icon name="arrow-left" />
            <span>back</span>
          </div>
        )}
      </div>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}

      {step === 1 ? (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Row className="g-4">
              <Col lg="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="province_state">
                    Select User (Enter 5 Characters to Initiate State)
                  </label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={searchOptions}
                      className="w-100"
                      placeholder="Search"
                      id="username"
                      onChange={(e) => {
                        setFormData({ ...formData, ["phone_number"]: e.value });
                        setPreviewData({
                          ...previewData,
                          ["company_representative_name"]: name_phone_number_splitter(
                            e.label,
                            app_consts.FULLNAME_SPLIT
                          ),
                          ["company_representative_role_phone_number"]: name_phone_number_splitter(
                            e.label,
                            app_consts.PHONE_NUMBER_SPLIT
                          ),
                        });
                      }}
                      onInputChange={(e) => onActionText(e)}
                      isDisabled={userLoading}
                    />
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="district_id">
                    Select Representative Role
                  </label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={comp_rep_data}
                      className="w-100%"
                      id="comap_rep"
                      onChange={(e) => {
                        setFormData({ ...formData, ["company_representative_role_id"]: e.value });
                        setPreviewData({ ...previewData, ["company_representative_role_name"]: e.label });
                      }}
                      isDisabled={compRepLoading}
                    />
                  </div>
                  {openOtherModalFunc && !compRepLoading && !userLoading && (
                    <div>
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          openOtherModalFunc();
                        }}
                        className="blue d-flex justify-end mt-4 text-blue underline-text items-center"
                      >
                        Add Representative Role
                      </Link>
                    </div>
                  )}
                </div>
              </Col>
              <Col xl="12" className="d-flex justify-content-center mt-8">
                <Button
                  onClick={handlePreview}
                  size="lg"
                  type="submit"
                  color="primary"
                  disabled={
                    !(formData.company_representative_role_id && formData.phone_number && formData.user_account_id)
                  }
                >
                  Preview Company Representative and Onboard
                </Button>
              </Col>
            </Row>
          </form>
        </>
      ) : step === 2 ? (
        <>
          <ListText
            dataItem="data-item mySmall"
            label="Company Representative Name"
            value={previewData.company_representative_name}
          />
          <ListText
            dataItem="data-item mySmall"
            label="Company Representative Phone Number"
            value={previewData.company_representative_role_phone_number}
          />
          <ListText
            dataItem="data-item mySmall"
            label="Company Representative Role Name"
            value={previewData.company_representative_role_name}
          />
          <Col xl="12" className="d-flex justify-content-center mt-4 mr-2">
            <Button onClick={handleSubmit} disabled={loading} color="primary">
              {loading ? <Spinner size="sm" color="light" /> : "Onboard Company Representative"}
            </Button>
          </Col>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AddCompanyRepresentativeModal;
