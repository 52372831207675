import React, { useEffect, useState } from "react";
import {
  Block,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  PaginationComponent,
} from "../../../../components/Component";
import { read_Only_Columns } from "./table_list_data/table_list_columns";
import * as app_consts from "../../../../constants/react_app_consts";
import { ReadOnlyCompanyRepData, ReadOnlyProperyData } from "./table_components/read_only_row_data";
import ModalDetails from "../modal/modal_details/ModalDetails";

const ReadOnlyTable = ({ data, tableType }) => {
  const [tableData, setTableData] = useState(data);
  const [type, setType] = useState(tableType);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [tableColumns, setTableColumns] = useState(read_Only_Columns(tableType));
  const [openViewModal, setViewModal] = useState(false);
  const [detail, setDetail] = useState([]);
  const [selectedDataID, setSelectedDataID] = useState("");

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setType(tableType);
    setTableColumns(read_Only_Columns(tableType));
  }, [tableType]);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // UseEffect for Specific Data
  useEffect(() => {
    if (selectedDataID) {
      setDetail(filterSpecificUser(selectedDataID));
    }
  }, [selectedDataID]);

  // Filter Specific Data
  const filterSpecificUser = (selected_data) => {
    let filtereredData;
    if (type === app_consts.ONBOARD_USER_PROPERTY) {
      filtereredData = tableData?.filter((data) => data.property_id === selected_data);
    } else if (type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE) {
      filtereredData = tableData?.filter((data) => data.member_access_id === selected_data);
    }
    return filtereredData[0];
  };

  return (
    <>
      <Block>
        <DataTable className="card-stretch">
          <DataTableBody>
            <DataTableHead>
              {tableColumns &&
                tableColumns?.map((column) => {
                  return (
                    <DataTableRow key={column.key}>
                      <span>{column.label}</span>
                    </DataTableRow>
                  );
                })}
              <DataTableRow className="nk-tb-col-tools">&nbsp;</DataTableRow>
            </DataTableHead>
            {/* Table Rows */}
            {
              // Property Data
              type === app_consts.ONBOARD_USER_PROPERTY ? (
                <ReadOnlyProperyData
                  data={tableData}
                  currentItems={currentItems}
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                />
              ) : type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE ? (
                <ReadOnlyCompanyRepData
                  data={tableData}
                  currentItems={currentItems}
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                />
              ) : (
                ""
              )
            }
          </DataTableBody>
          <div className="card-inner">
            {tableData.length > 0 ? (
              <PaginationComponent
                noDown
                itemPerPage={itemPerPage}
                totalItems={tableData.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No Data Found</span>
              </div>
            )}
          </div>
        </DataTable>
      </Block>
      {/* Modal For Viewing Selected Row Details */}
      <ModalDetails
        modalOpen={openViewModal}
        setModalOpen={setViewModal}
        modalTitle={
          type === app_consts.ONBOARD_USER_PROPERTY
            ? `${detail && `${detail.property_name}'s Details`}`
            : type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE
            ? `Company Representative Details`
            : ""
        }
        modalType={type}
        modalData={detail}
      />
    </>
  );
};

export default ReadOnlyTable;
