import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    ownership_status_list: { status: "idle" },
}

export const ownership_status_list_Slice = createSlice({
    name: "ownership_status_list",
    initialState,
    reducers: {
        success_ownership_status_list: (state, action) => {
            state.ownership_status_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_ownership_status_list: (state, action) => {
            state.ownership_status_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_ownership_status_list: (state) => {
            state.ownership_status_list = { status: app_consts.IDLE }
        },
        loading_ownership_status_list: (state) => {
            state.ownership_status_list = { status: app_consts.LOADING }
        },
    }
})

export const { success_ownership_status_list, fail_ownership_status_list, reset_ownership_status_list, loading_ownership_status_list } = ownership_status_list_Slice.actions;

export default ownership_status_list_Slice.reducer;