import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    gender_type_list: { status: "idle" },
}

export const gender_type_list_Slice = createSlice({
    name: "gender_type_list",
    initialState,
    reducers: {
        success_gender_type_list: (state, action) => {
            state.gender_type_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_gender_type_list: (state, action) => {
            state.gender_type_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_gender_type_list: (state) => {
            state.gender_type_list = { status: app_consts.IDLE }
        },
        loading_gender_type_list: (state) => {
            state.gender_type_list = { status: app_consts.LOADING }
        },
    }
})

export const { success_gender_type_list, fail_gender_type_list, reset_gender_type_list, loading_gender_type_list } = gender_type_list_Slice.actions;

export default gender_type_list_Slice.reducer;