// --------------------------------- Module Employee Role Actions URLS -------------------------------------

// Get Employee Role Actions
export const GET_EMP_ROLE_ACTIONS_API = "emp_role_action/retrieve_active_specific_employee_actions";

// --------------------------------- Users URLS -------------------------------------

// Get User List
export const RETRIEVE_USER_LIST_IMG_API = "users/retrieve_user_list_img";
// Create User
export const CREATE_USER_API = "auth/access_account_registration";
// Amend User Status
export const AMEND_USER_STATUS_API = "users/amend_user_account_status";
// Retrieve Active User
export const RETRIEVE_ACTIVE_USERS = "users/retrieve_active_users_list";
// Retrieve User KYC Status
export const RETRIEVE_USER_KYC_STATUS = "user_kyc/retrieve_user_kyc_reg_status";
// Retrieve User KYC Status
export const RETRIEVE_USER_ACTIVE_STATUS_KYC_STATUS = "user_kyc/retrieve_user_active_status_kyc_reg_status";

// --------------------------------- User Accounts URLS -------------------------------------

// Retrieve Active User
export const RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED = "user_accounts/retrieve_user_accs_list_not_assigned";
// Retrieve Active Assigned User Account List
export const RETRIEVE_ACTIVE_USER_ACCOUNT_LIST = "user_accounts/retrieve_act_truck_cust_comp_accs";
// Create Truck Driver Account
export const CREATE_TRUCK_DRIVER_ACCOUNT = "user_accounts/create_truck_driver_account";
// Create Customer Account
export const CREATE_CUSTOMER_ACCOUNT = "user_accounts/create_customer_account";
// Create Company Account
export const CREATE_COMPANY_ACCOUNT = "user_accounts/create_company_account";

// --------------------------------- Gender URLS -------------------------------------

// Retrieve Gender Types List
export const RETRIEVE_GENDER_TYPE_LIST = "gender/retrieve_gender_types";

// --------------------------------- Marital Status URLS -------------------------------------

// Retrieve Gender Types List
export const RETRIEVE_MARITAL_STATUS_LIST = "marital_status/retrieve_marital_statuses";

// --------------------------------- User Title URLS -------------------------------------

// Retrieve Gender Types List
export const RETRIEVE_USER_TITLE_LIST = "user_title/retrieve_user_title";

// --------------------------------- User KYC URLS -------------------------------------
// Update Basic KYC Info
export const UPDATE_BASIC_KYC_INFO = "user_kyc/update_user_kyc_basic_info";
// Update Document KYC Info
export const UPDATE_DOCUMENT_KYC_INFO = "user_kyc/update_user_kyc_document_info";
// Update Address KYC Info
export const UPDATE_ADDRESS_KYC_INFO = "user_kyc/update_user_kyc_address_info";
// UPdate NOK KYC Info
export const UPDATE_NOK_KYC_INFO = "user_kyc/update_user_kyc_nok_info";
// Update Image KYC Info
export const UPDATE_IMAGE_KYC_INFO = "user_kyc/update_user_kyc_image_info";
// Specific KYC Info FOR KYC Completion
export const SPECIFIC_USER_KYC_COMP_KYC_REG = "user_kyc/retrieve_complete_kyc_reg_user_det";
// Uncomplete Active User KYC List
export const UNCOMPLETE_ACTIVE_USER_KYC_LIST = "user_kyc/retrieve_uncomplete_kyc_list";
// Uncomplete Active User KYC List
export const RETRIEVE_ACTIVE_COMPLETE_KYC_LIST = "user_kyc/retreive_active_complete_kyc_list";

// --------------------------------- Branch Location URLS -------------------------------------
// Retrieve Branch Location List
export const RETRIEVE_BRANCH_LOCATION_LIST = "branch_locations/retrieve_branch_locations_list";
// Add Branch Location
export const ADD_BRANCH_LOCATION = "branch_locations/add_branch_location";
// Amend Branch Location Status
export const AMEND_BRANCH_LOCATION_STATUS = "branch_locations/amend_branch_location_status";

// --------------------------------- Location URLS -------------------------------------
// Retrieve Zambian Provinces List
export const RETRIEVE_ZAMBIAN_PROVINCES_LIST = "locations/retrieve_zambian_provinces";

// --------------------------------- Product URLS -------------------------------------

// Retrieve Products List
export const RETRIEVE_PRODUCTS_LIST = "products/retrieve_products_list";
// Amend Products Status
export const AMEND_PRODUCTS_STATUS = "products/amend_product_status";
// Amend Products Status
export const ADD_PRODUCT = "products/add_product";

// --------------------------------- Product Types URLS -------------------------------------

// Retrieve Product Types List
export const RETRIEVE_PRODUCT_TYPES_LIST = "product_types/retrieve_product_types_list";
// Retrieve Product Types List retrieve_active_product_type_list
export const RETRIEVE_ACTIVE_PRODUCT_TYPES_LIST = "product_types/retrieve_active_product_type_list";
// Amend Product Type Status
export const AMEND_PRODUCT_TYPES_STATUS = "product_types/amend_product_type_status";
//Add Product Type
export const ADD_PRODUCT_TYPE = "product_types/add_product_type";

// --------------------------------- Unit Measurement URLS -------------------------------------
// Retrieve Unit Measurements List
export const RETRIEVE_UNIT_MEASUREMENT_LIST = "unit_measurements/retrieve_unit_measurement_list";
// Amend Unit Measurement Status
export const AMEND_UNIT_MEASUREMENT_STATUS = "unit_measurements/amend_unit_measurement_status";
// Amend Unit Measurement Status
export const ADD_UNIT_MEASUREMENT = "unit_measurements/add_unit_measurement";

// --------------------------------- User Account Property URLS -------------------------------------
// Add User Account Property
export const ADD_USER_ACCOUNT_PROPERTY = "user_account_property/add_user_account_property";

// --------------------------------- Company Representative URLS -------------------------------------
// Add Company Representative Role
export const ADD_COMPANY_REPRESENTATIVE_ROLE = "company_representative/add_company_representative_role";
// Retrieve Company Representative Roles
export const RETRIEVE_COMPANY_REPRESENTATIVE_ROLES =
  "company_representative/retrieve_company_representative_roles_list";
// Retrieve Company Representative Roles
export const ADD_COMPANY_REPRESENTATIVE = "company_representative/add_company_representative";

// --------------------------------- Trucks and Trailers URLS -------------------------------------
// Retrieve Trucks and Trailers List
export const RETRIEVE_TRUCKS_TRAILERS_LIST = "trucks/retreive_truck_trailers_list";

// --------------------------------- Weigh Bridge Ticket Types URLS -------------------------------------
// Retrieve Weigh Bridge Ticket Type List
export const RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_LIST = "weigh_bridge_ticke_types/retrieve_weigh_bridge_tickets_type";
