import axios from "axios";

const API_URL = process.env.REACT_APP_EMPLOYEE_DASHBOARD_API_ENDPOINT;

axios.interceptors.request.use((req) => {
    if (localStorage.getItem("access")) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access")).access_token}`;
    }
    return req;
});

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

// Create A Trailer
export const add_trailer_api = (formData) => axios.post(API_URL + "trailers/add_trailer", formData, { withCredentials: false });