import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import TablePageHeading from "../components/page_heading/TablePageHeading";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as app_consts from "../../../constants/react_app_consts";
import {
  fail_retreive_user_list_img,
  loading_retreive_user_list_img,
  reset_retreive_user_list_img,
  success_retreive_user_list_img,
} from "../../../reducers/dashboard_reducers/user/retreive_user_list_img_Slice";
import { Block } from "../../../components/Component";
import TableList from "../components/table/TableList";
import ModalAdd from "../components/modal/modal_add/ModalAdd";
import ModalConfirmCancel from "../components/modal/modal_confirm_cancel/ModalConfirmCancel";
import {
  fail_amend_user_acc_status,
  loading_amend_user_acc_status,
  reset_amend_user_acc_status,
  success_amend_user_acc_status,
} from "../../../reducers/dashboard_reducers/user/amend_user_acc_status_Slice";
import { messageToast } from "../../../utils/Utils";
import ModalDetails from "../components/modal/modal_details/ModalDetails";
import { dashboard_actions_helper } from "../../../actions/dashboard_actions_helper";
import {
  AMEND_USER_STATUS_ACCESS_DENIED_MSG,
  AMEND_USER_STATUS_ERR_MSG,
  AMEND_USER_STATUS_SUCCESS_MSG,
  USER_LIST_IMGS_ACCESS_DENIED_MSG,
  USER_LIST_IMGS_ERR_MSG,
  USER_LIST_IMGS_SUCCESS_MSG,
} from "../../../constants/success_error_messages_consts";
import { AMEND_USER_STATUS_API, RETRIEVE_USER_LIST_IMG_API } from "../../../api/dashboard_api_data";

const initial_actions_data = { onboard_user: false, view_users: false, amend_user_status: false };
const type = app_consts.USER_LIST_TYPE;
const initial_amend_user_status = { user_id: "", status_id: "" };

const UserList = () => {
  const dispatch = useDispatch();
  const [page_data, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page_action_data, setPageActionData] = useState([]);
  const [componentData, setComponentData] = useState(initial_actions_data);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [acc_status, setAccStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [detail, setDetail] = useState([]);
  const [errAmendAccStat, setErrAmendAccStat] = useState("");
  const [amendStatusFormData, setAmendStatusFormData] = useState(initial_amend_user_status);
  const [openModalDetails, setOpendModalDetails] = useState(false);

  useEffect(() => {
    // Retrieve User list
    retrieve_user_list_func();
    setLoading(true);
  }, []);

  // Retrieve Users
  const retrieve_user_list_func = () => {
    dispatch(
      dashboard_actions_helper(
        loading_retreive_user_list_img,
        success_retreive_user_list_img,
        fail_retreive_user_list_img,
        USER_LIST_IMGS_ERR_MSG,
        USER_LIST_IMGS_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_USER_LIST_IMG_API,
        null,
        USER_LIST_IMGS_ACCESS_DENIED_MSG
      )
    );
  };

  // Render Heading Data
  const headingRightData = () => {
    // Export Functionality
  };

  // Refresh List Function
  const refreshList = () => {
    // Retrieve User list
    retrieve_user_list_func();
    setLoading(true);
    setPageData([]);
  };

  // Read Users List State
  const user_list_img = useSelector((state) => state.retreive_user_list_img.retreive_user_list_img);

  useEffect(() => {
    if (user_list_img.status !== app_consts.IDLE) {
      if (user_list_img.status === app_consts.LOADING) {
        setLoading(true);
      } else {
        setLoading(false);
        if (user_list_img.status === app_consts.FAIL) {
          // setError(user_list_img.message)
        } else if (user_list_img.status === app_consts.SUCCESS) {
          setPageData(user_list_img.data);
        }
        dispatch(reset_retreive_user_list_img());
      }
    }
  }, [user_list_img]);

  // Select User Actions Parse Components Accordingly

  // Retrieve Module Actions Data
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);
  // UseEffect to Place Page Data
  useEffect(() => {
    if (action_roles.status === app_consts.SUCCESS) {
      setPageActionData(action_roles.data);
      if (action_roles.data.onboarding || action_roles.data.user_management) {
        // Set Global Return Actions Data
        let page_action_access = {};
        if (action_roles.data.user_management) {
          page_action_access = { ...page_action_access, ...action_roles.data.user_management.module_actions };
        }
        if (action_roles.data.onboarding) {
          page_action_access = { ...page_action_access, ...action_roles.data.onboarding.module_actions };
        }
        setComponentData(page_action_access);
      }
    }
  }, [action_roles]);

  // UseEffect for Specific Data
  useEffect(() => {
    if (selectedUser) {
      setDetail(filterSpecificUser(selectedUser));
    }
  }, [selectedUser]);

  // Open Add to List Modal
  const openAddListModal = () => {
    setOpenAddModal(true);
  };

  // Success Function for Adding User Via Modal
  const successAddFunc = () => {
    setOpenAddModal(false);
    refreshList();
  };

  // Function to Amend Account Status
  const amend_Account_Status_modal = (user_Id, account_status_id, account_status) => {
    setAccStatus(account_status);
    setOpenConfirmCancelModal(true);
    setSelectedUser(user_Id);
    setAmendStatusFormData({ ...amendStatusFormData, ["user_id"]: user_Id, ["status_id"]: account_status_id });
  };

  // Function to Amend User Account Status
  const amend_Account_Status = () => {
    dispatch(
      dashboard_actions_helper(
        loading_amend_user_acc_status,
        success_amend_user_acc_status,
        fail_amend_user_acc_status,
        AMEND_USER_STATUS_ERR_MSG,
        AMEND_USER_STATUS_SUCCESS_MSG,
        app_consts.POST,
        AMEND_USER_STATUS_API,
        amendStatusFormData,
        AMEND_USER_STATUS_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Amend User Account Status Reducer
  const amend_acc_stat = useSelector((state) => state.amend_user_acc_status.amend_user_acc_status);
  // UseEffect for Amend User Account Status
  useEffect(() => {
    if (amend_acc_stat.status !== app_consts.IDLE) {
      if (amend_acc_stat.status !== app_consts.LOADING) {
        if (amend_acc_stat.status === app_consts.FAIL) {
          setErrAmendAccStat(amend_acc_stat.message);
        } else if (amend_acc_stat.status === app_consts.SUCCESS) {
          messageToast(
            `${detail && `${detail.first_name} ${detail.surname}'s `}Account Status has been Successfully Amended.`,
            app_consts.SUCCESS
          );
          setAccStatus("");
          setDetail([]);
          refreshList();
          setSelectedUser("");
          setErrAmendAccStat("");
          setOpenConfirmCancelModal(false);
        }
        dispatch(reset_amend_user_acc_status());
      }
    }
  }, [amend_acc_stat]);

  // Filter Specific User
  const filterSpecificUser = (selected_user) => {
    const filtereredUser = page_data?.filter((data) => data.member_access_id === selected_user);
    return filtereredUser[0];
  };

  return (
    <>
      <Head title="Create User" />
      <Content>
        {/* Table Page Heading */}
        <TablePageHeading
          title="User List"
          description={`Total Users: ${page_data.length}`}
          refresh
          refreshFunc={refreshList}
          csv_export
          add_to_list={componentData.onboard_user}
          page_type={type}
          pageData={page_data}
          openAddList={openAddListModal}
        />
        {/* Table */}
        <Block>
          <TableList
            left_head_select
            type={type}
            data={page_data}
            rowOptionsData={componentData}
            amendFunc={amend_Account_Status_modal}
            setOpendModalDetails={setOpendModalDetails}
            setSelectedData={setSelectedUser}
          />
        </Block>
      </Content>

      {/* Add Modal */}
      <ModalAdd
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        modalTitle="Onboard User"
        modalDescription="Please Complete this form to Onboard a User"
        type={type}
        succFunc={successAddFunc}
      />
      {/* Yes or No Modal */}
      <ModalConfirmCancel
        openModal={openConfirmCancelModal}
        setOpenModal={setOpenConfirmCancelModal}
        modalTitle={`${acc_status} ${detail && `${detail.first_name} ${detail.surname}'s `}Account?`}
        modalDescription={`Are you sure that you want to ${acc_status} ${
          detail && `${detail.first_name} ${detail.surname}'s `
        }Account?`}
        buttonConfirm="Confirm"
        buttonCancel="Cancel"
        succFunc={amend_Account_Status}
        errAmend={errAmendAccStat}
        setErrAmend={setErrAmendAccStat}
      />
      {/* Modal For Viewing User Details */}
      <ModalDetails
        modalOpen={openModalDetails}
        setModalOpen={setOpendModalDetails}
        modalTitle={`${detail && `${detail.first_name} ${detail.surname}'s Details`}`}
        modalType={app_consts.USER_LIST_TYPE}
        modalData={detail}
      />
    </>
  );
};

export default UserList;
