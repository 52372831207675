import React, { useEffect, useState } from "react";
import { Alert, Spinner } from "reactstrap";
import { Button, Col, Icon, Row } from "../../../../../../components/Component";
import TextInput from "../../../../../components/inputs/TextInput";
import ListText from "../../../text/ListText";
import { ADD_PRODUCT_TYPE } from "../../../../../../api/dashboard_api_data";
import {
  fail_add_product_type,
  loading_add_product_type,
  reset_add_product_type,
  success_add_product_type,
} from "../../../../../../reducers/dashboard_reducers/products/add_product_type_Slice";
import {
  ADD_PRODUCT_TYPE_ACCESS_DENIED_MSG,
  ADD_PRODUCT_TYPE_ERR_MSG,
  ADD_PRODUCT_TYPE_SUCCESS_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import { messageToast } from "../../../../../../utils/Utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as app_consts from "../../../../../../constants/react_app_consts";
import { dashboard_actions_helper } from "../../../../../../actions/dashboard_actions_helper";

const insitialState = { product_type: "" };

const AddProductTypeModal = ({ successFunction, openModal, setModalLoading }) => {
  const [formData, setFormData] = useState(insitialState);
  const [step, setStep] = useState(1);
  const [errorVal, setError] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const dispatch = useDispatch();

  // Handle Preview
  const handlePreview = () => {
    setError("");
    setStep(2);
  };

  const handleSubmit = () => {
    dispatch(
      dashboard_actions_helper(
        loading_add_product_type,
        success_add_product_type,
        fail_add_product_type,
        ADD_PRODUCT_TYPE_ERR_MSG,
        ADD_PRODUCT_TYPE_SUCCESS_MSG,
        app_consts.POST,
        ADD_PRODUCT_TYPE,
        formData,
        ADD_PRODUCT_TYPE_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Add Product State
  const add_product_type = useSelector((state) => state.add_product_type.add_product_type);
  // UseEffect
  useEffect(() => {
    if (add_product_type.status !== app_consts.IDLE) {
      if (add_product_type.status === app_consts.LOADING) {
        setAddLoading(true);
        setModalLoading(true);
      } else {
        setAddLoading(false);
        setModalLoading(false);
        if (add_product_type.status === app_consts.FAIL) {
          setError(add_product_type.message);
          prevStep();
        } else if (add_product_type.status === app_consts.SUCCESS) {
          messageToast(`${formData.product_type} has been Successfully Added.`, app_consts.SUCCESS);
          // Close Modal and Other Embedded Functions if Need Be
          successFunction();
        }
        dispatch(reset_add_product_type());
      }
    }
  }, [add_product_type]);

  const prevStep = () => {
    setStep(1);
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h5 className="card-title text-start w-100">Add Product Type Form</h5>
        {step === 2 && (
          <div
            className="nk-block-head-sub mb-2"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              prevStep();
            }}
          >
            <Icon name="arrow-left" />
            <span>back</span>
          </div>
        )}
      </div>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      {step === 1 ? (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Row className="g-4">
              <TextInput
                label="Product Type"
                required
                type="text"
                id_val="product_type"
                maxlength="30"
                value={formData.product_type}
                colSize="10"
                name="product_type"
                handleChange={(e) => {
                  setFormData({ ...formData, ["product_type"]: e.target.value });
                }}
              />
              <Col xl="12" className="d-flex justify-content-center">
                <Button
                  onClick={handlePreview}
                  size="lg"
                  type="submit"
                  color="primary"
                  disabled={!formData.product_type}
                >
                  Preview Product Type and Add
                </Button>
              </Col>
            </Row>
          </form>
        </>
      ) : step === 2 ? (
        <>
          <ListText dataItem="data-item mySmall" label="Product Type" value={formData.product_type} />
          <Col xl="12" className="d-flex justify-content-center mt-4 mr-2">
            <Button onClick={handleSubmit} disabled={addLoading} color="primary">
              {addLoading ? <Spinner size="sm" color="light" /> : "Add Product Type"}
            </Button>
          </Col>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AddProductTypeModal;
