import * as app_consts from "../../../../../constants/react_app_consts";

export const left_select_options = (type) => {
    let options = [];
    switch (type) {
        case app_consts.USER_LIST_TYPE:
            options = [
                { value: 0, label: "All Users" },
                { value: 1, label: "Active" },
                { value: 2, label: "Suspended" },
                { value: 3, label: "Deactivated" }
            ];
            break;
    }
    return options;
}