import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    update_image_info_user_kyc: { status: "idle" },
}

export const update_image_info_user_kyc_Slice = createSlice({
    name: "update_image_info_user_kyc",
    initialState,
    reducers: {
        success_update_image_info_user_kyc: (state, action) => {
            state.update_image_info_user_kyc = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_update_image_info_user_kyc: (state, action) => {
            state.update_image_info_user_kyc = { status: app_consts.FAIL, message: action.payload };
        },
        reset_update_image_info_user_kyc: (state) => {
            state.update_image_info_user_kyc = { status: app_consts.IDLE }
        },
        loading_update_image_info_user_kyc: (state) => {
            state.update_image_info_user_kyc = { status: app_consts.LOADING }
        },
    }
})

export const { success_update_image_info_user_kyc, fail_update_image_info_user_kyc, reset_update_image_info_user_kyc, loading_update_image_info_user_kyc } = update_image_info_user_kyc_Slice.actions;

export default update_image_info_user_kyc_Slice.reducer;