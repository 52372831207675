import { configureStore } from "@reduxjs/toolkit";

// Auth Reducers
import usernameVerifyReducer from '../reducers/website_reducers/auth/username_verify_Slice';
import loginOtpVerifyReducer from '../reducers/website_reducers/auth/login_otp_verify_Slice';
import sendOtpOptionReducer from '../reducers/website_reducers/auth/send_otp_option_Slice';
import verifyOtpReducer from '../reducers/website_reducers/auth/verify_otp_Slice';

// Store For All Reducers
export const website_store = configureStore({
    reducer: {
        // Auth
        username_verify: usernameVerifyReducer,
        login_otp_verify: loginOtpVerifyReducer,
        send_otp_option: sendOtpOptionReducer,
        verify_otp: verifyOtpReducer,
    },
});
