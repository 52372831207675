import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    send_otp_option: { status: "idle" },
}

export const send_otp_option_Slice = createSlice({
    name: "send_otp_option",
    initialState,
    reducers: {
        success_send_otp_option: (state, action) => {
            state.send_otp_option = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_send_otp_option: (state, action) => {
            state.send_otp_option = { status: app_consts.FAIL, message: action.payload };
        },
        reset_send_otp_option: (state) => {
            state.send_otp_option = { status: app_consts.IDLE }
        },
        loading_send_otp_option: (state) => {
            state.send_otp_option = { status: app_consts.LOADING }
        },
    }
})

export const { success_send_otp_option, fail_send_otp_option, reset_send_otp_option, loading_send_otp_option } = send_otp_option_Slice.actions;

export default send_otp_option_Slice.reducer;