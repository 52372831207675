import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  add_unit_measurement: { status: "idle" },
};

export const add_unit_measurement_Slice = createSlice({
  name: "add_unit_measurement",
  initialState,
  reducers: {
    success_add_unit_measurement: (state, action) => {
      state.add_unit_measurement = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_add_unit_measurement: (state, action) => {
      state.add_unit_measurement = { status: app_consts.FAIL, message: action.payload };
    },
    reset_add_unit_measurement: (state) => {
      state.add_unit_measurement = { status: app_consts.IDLE };
    },
    loading_add_unit_measurement: (state) => {
      state.add_unit_measurement = { status: app_consts.LOADING };
    },
  },
});

export const {
  success_add_unit_measurement,
  fail_add_unit_measurement,
  reset_add_unit_measurement,
  loading_add_unit_measurement,
} = add_unit_measurement_Slice.actions;

export default add_unit_measurement_Slice.reducer;
