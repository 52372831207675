import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import * as app_consts from "../constants/react_app_consts";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ModalLogout from "../pages/dashboard_pages/components/modal/ModalLogout";
import LoadingApp from "../pages/dashboard_pages/loading/LoadingApp";
import OnBoardingIndex from "../pages/dashboard_pages/onboarding/OnboardingIndex";
import UserOnboardingIndex from "../pages/dashboard_pages/onboarding/user/UserOnboardingIndex";
import CreateUser from "../pages/dashboard_pages/onboarding/user/CreateUser";
import UserList from "../pages/dashboard_pages/users/UserList";
import AssignUserIndex from "../pages/dashboard_pages/onboarding/user/AssignUserIndex";
import WeighBridgeTicketIndex from "../pages/dashboard_pages/warehouse/WeighBridgeTicketIndex";
import CreateGoodsReceived from "../pages/dashboard_pages/warehouse/CreateGoodsReceived";
import AssignCustomer from "../pages/dashboard_pages/onboarding/user/AssignCustomer";
import CompleteUserKYC from "../pages/dashboard_pages/onboarding/user/CompleteUserKYC";
import ErrorApp from "../pages/dashboard_pages/loading/ErrorApp";
import VehicleOnboardingIndex from "../pages/dashboard_pages/onboarding/vehicle/VehicleOnboardingIndex";
import TruckOnboarding from "../pages/dashboard_pages/onboarding/vehicle/TruckOnboarding";
import TrailerOnboarding from "../pages/dashboard_pages/onboarding/vehicle/TrailerOnboarding";
import AssignTruckDriver from "../pages/dashboard_pages/onboarding/user/AssignTruckDriver";
import {
  fail_auth_routes,
  loading_auth_routes,
  success_auth_routes,
} from "../reducers/dashboard_reducers/auth/auth_routes_Slice";
import { AUTH_ROUTES_ERR_MSG, AUTH_ROUTES_SUCCESS_MSG } from "../constants/success_error_messages_consts";
import { GET_EMP_ROLE_ACTIONS_API } from "../api/dashboard_api_data";
import { dashboard_actions_helper } from "../actions/dashboard_actions_helper";
import EmployeeList from "../pages/dashboard_pages/admin/employees/EmployeeList";
import BranchLocationsList from "../pages/dashboard_pages/admin/branch_locations/BranchLocationsList";
import AddBranchLocation from "../pages/dashboard_pages/admin/branch_locations/AddBranchLocation";
import ProductList from "../pages/dashboard_pages/admin/products/ProductList";
import ProductTypeList from "../pages/dashboard_pages/admin/products/ProductTypeList";
import UnitMeasurementList from "../pages/dashboard_pages/admin/products/UnitMeasurementList";
import CompanyOnboardingIndex from "../pages/dashboard_pages/onboarding/company/CompanyOnboardingIndex";
import CreateCompanyAccount from "../pages/dashboard_pages/onboarding/company/CreateCompanyAccount";
import WeighBridgeTicketList from "../pages/dashboard_pages/warehouse/WeighBridgeTicketList";

const DashboardRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Use States
  const [route_access, setRouteAccess] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [onboardingData, setOnboardingData] = useState([]);
  const [onboardingActions, setOnboardingActions] = useState([]);
  const [warehouseMangeData, setWarehouseMangeData] = useState([]);
  const [userManageData, setUserManageData] = useState([]);
  const [adminManageData, setAdminManageData] = useState([]);
  const [reloadPath, setReloadPath] = useState(window.location.pathname);
  const [app_loading, setAppLoading] = useState(true);
  const [errorVal, setError] = useState("");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  //UseEffect fo Initial Application Load
  useEffect(() => {
    dispatch(
      dashboard_actions_helper(
        loading_auth_routes,
        success_auth_routes,
        fail_auth_routes,
        AUTH_ROUTES_ERR_MSG,
        AUTH_ROUTES_SUCCESS_MSG,
        app_consts.GET,
        GET_EMP_ROLE_ACTIONS_API,
        null,
        null,
        app_consts.MAP,
        app_consts.AUTH_ROUTES_MAP
      )
    );
    setReloadPath(window.location.pathname);
  }, []);

  // UseSelector to Listen to Changes in Getting Action Roles
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);

  // UseEffect
  useEffect(() => {
    if (action_roles.status !== app_consts.IDLE) {
      if (action_roles.status === app_consts.LOADING) {
        setAppLoading(true);
      } else {
        if (action_roles.status === app_consts.FAIL) {
          setError(action_roles.status);
          setAppLoading(false);
        } else if (action_roles.status === app_consts.SUCCESS) {
          setActionData(action_roles.data);
          setError(action_roles.status);
          console.log(action_roles.data);
        }
      }
    }
    // navigate(`/dashboard/onboarding/${username_check.data.username}`);
  }, [action_roles]);
  // UseEffect
  useEffect(() => {
    // console.log(reloadPath);
    // navigate(`/dashboard/onboarding/${username_check.data.username}`);
  }, [reloadPath]);

  // UseSelector for Signing Out
  const signout = useSelector((state) => state.sign_out.sign_out);

  // UseEffect for Sign Out
  useEffect(() => {
    if (signout.status === app_consts.SUCCESS) {
      window.location.assign("/");
    }
  }, [signout]);

  // Logout Code
  const [logout, setLogout] = useState(false);

  // Listen to 401 Error
  const logout_trigger = useSelector((state) => state.sign_out_modal.sign_out_modal);

  useEffect(() => {
    if (logout_trigger.status === app_consts.SUCCESS) {
      setLogout(true);
      setError(app_consts.PAGE_ACCESS_DENIED);
      setAppLoading(false);
      // Remember to Put Code to Recoke the Token In The API
      // console.log(logout_trigger)
    }
  }, [logout_trigger]);

  // Listen to Changes in Action Data
  useEffect(() => {
    if (actionData) {
      if (actionData.onboarding) {
        setOnboardingData(actionData.onboarding);
        console.log(actionData.onboarding);
      }
      setAppLoading(false);
      navigate(reloadPath);
      console.log(reloadPath);
    }
  }, [actionData]);

  // Onboarding Module Actions
  useEffect(() => {
    if (onboardingData.module_actions) {
      console.log(onboardingData.module_actions);
      setOnboardingActions(onboardingData.module_actions);
    }
  }, [onboardingData]);

  return (
    <>
      <ModalLogout logout_var={logout} />
      {/* Routes */}
      <Routes>
        <Route path="/" element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} replace />} />

        <Route path="*" element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} replace />} />
        {
          // App Loading
          app_loading ? (
            <>
              <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<LayoutNoSidebar />}>
                <Route index element={<LoadingApp />}></Route>
                <Route path="*" element={<Navigate to={`/`} replace />} />
              </Route>
            </>
          ) : (
            <>
              {
                // Check If Network Error
                errorVal === app_consts.FAIL ? (
                  <>
                    <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<LayoutNoSidebar />}>
                      {/* Default Pages That Can be Viewed by All */}
                      <Route index element={<ErrorApp />} />
                      <Route path="*" element={<Navigate to={`/`} replace />} />
                    </Route>
                  </>
                ) : errorVal === app_consts.SUCCESS ? (
                  <>
                    <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<Layout />}>
                      {/* Default Pages That Can be Viewed by All */}

                      <Route
                        path="*"
                        element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/weigh_bridge_tickets`} replace />}
                      />

                      {/* Warehouse Mangement */}
                      <Route path="weigh_bridge_tickets">
                        <Route index element={<WeighBridgeTicketList />} />
                        <Route exact path="create_goods_received" element={<CreateGoodsReceived />} />
                        {/* <Route path="user" >
                        <Route index element={<UserOnboardingIndex />} />
                        <Route exact path="create_user" element={<CreateGoodsReceived />} />
                      </Route> */}
                      </Route>

                      {/* ------------------------------------ Onboarding ------------------------------------ */}
                      {onboardingData && onboardingData.onboarding && (
                        <Route path="onboarding">
                          {/* <Route index element={<OnBoardingIndex />} /> */}
                          {/* Nesting User */}
                          <Route path="user">
                            <Route index element={<UserOnboardingIndex />} />
                            {/* Set Onboaring Actions */}
                            {
                              // Onboard User
                              onboardingActions.onboard_user && (
                                <Route exact path="create_user" element={<CreateUser />} />
                              )
                            }
                            {
                              // Assign User User
                              (onboardingActions.assign_customer || onboardingActions.assign_truck_driver) && (
                                <>
                                  {/* Nesting Assign User */}
                                  <Route path="assign/:phone_number">
                                    <Route index element={<AssignUserIndex />} />
                                    {
                                      // Assign Customer
                                      onboardingActions.assign_customer && (
                                        <Route exact path="customer" element={<AssignCustomer />} />
                                      )
                                    }
                                    {
                                      // Assign Truck Driver
                                      onboardingActions.assign_truck_driver && (
                                        <Route exact path="truck_driver" element={<AssignTruckDriver />} />
                                      )
                                    }
                                  </Route>
                                </>
                              )
                            }
                            {
                              // Complete KYC Registration
                              onboardingActions.onboard_user && (
                                <Route exact path="complete_kyc/:phone_number" element={<CompleteUserKYC />} />
                              )
                            }
                          </Route>
                          {/* Nesting Vehicle TrailerOnboarding*/}
                          {
                            // Onboard Truck or Trailer
                            (onboardingActions.onboard_truck || onboardingActions.onboard_trailer) && (
                              <>
                                <Route path="vehicle">
                                  <Route index element={<VehicleOnboardingIndex />} />
                                  {
                                    // Onboard Truck
                                    onboardingActions.onboard_truck && (
                                      <Route exact path="truck" element={<TruckOnboarding />} />
                                    )
                                  }
                                  {
                                    // Onboard Trailer
                                    onboardingActions.onboard_trailer && (
                                      <Route exact path="trailer" element={<TrailerOnboarding />} />
                                    )
                                  }
                                </Route>
                              </>
                            )
                          }
                          {/* Nesting Onboarding*/}
                          {
                            // Onboard Company
                            (onboardingActions.assign_company_representative || onboardingActions.onboard_company) && (
                              <>
                                <Route path="company">
                                  <Route index element={<CompanyOnboardingIndex />} />
                                  {
                                    // Onboard Truck
                                    onboardingActions.onboard_truck && (
                                      <Route exact path=":phone_number" element={<CreateCompanyAccount />} />
                                    )
                                  }
                                  {
                                    // Onboard Trailer
                                    onboardingActions.onboard_trailer && (
                                      <Route exact path=":company_id" element={<TrailerOnboarding />} />
                                    )
                                  }
                                </Route>
                              </>
                            )
                          }
                        </Route>
                      )}

                      {/* Users */}
                      <Route path="user">
                        <Route index element={<UserList />} />
                        {/* <Route path="user" >
                      <Route index element={<UserOnboardingIndex />} />
                      <Route exact path="create_user" element={<CreateUser />} />
                    </Route> */}
                      </Route>

                      {/* Admin  */}
                      <Route path="admin">
                        <Route index exact path="employees" element={<EmployeeList />} />
                        {/* Branch Locations */}
                        <Route path="branch_locations">
                          <Route index exact element={<BranchLocationsList />} />
                          <Route exact path="add" element={<AddBranchLocation />} />
                        </Route>
                        {/* Products */}
                        <Route path="products">
                          <Route index exact element={<ProductList />} />
                          <Route exact path="add_product" element={<AddBranchLocation />} />
                        </Route>
                        {/* Product Types*/}
                        <Route path="product_types">
                          <Route index exact element={<ProductTypeList />} />
                          {/* <Route exact path="add_product" element={<AddBranchLocation />} /> */}
                        </Route>
                        {/* Unit Measurements */}
                        <Route path="unit_measurements">
                          <Route index exact element={<UnitMeasurementList />} />
                          {/* <Route exact path="add_product" element={<AddBranchLocation />} /> */}
                        </Route>
                      </Route>
                    </Route>
                  </>
                ) : (
                  ""
                )
              }
            </>
          )
        }
      </Routes>
    </>
  );
};
export default DashboardRoutes;
