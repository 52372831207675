import React from "react";
import { Col } from "../../../../components/Component";

const RadioInput = ({ md, sm, id, name, label, className, handleChange }) => {
  return (
    <>
      <Col md={md ? md : "3"} sm={sm ? sm : "6"}>
        <div className="preview-block">
          <div className={className ? className : "custom-control-input"}>
            <input
              type="radio"
              id={id}
              name={name ? name : "radio_input"}
              className="custom-control-input"
              onClick={() => handleChange(id)}
            />
            <label className="custom-control-label" htmlFor={id}>
              {label}
            </label>
          </div>
        </div>
      </Col>
    </>
  );
};

export default RadioInput;
