import React from "react";
import { Button, DataTableItem, DataTableRow } from "../../../../../components/Component";

const ReadOnlyTableRow = ({ item, row_type, size, classname, item_id, setViewModal, setSelectedDataID }) => {
  return (
    <>
      {row_type === "first_col" ? (
        <>
          <DataTableRow className="text-primary">{item}</DataTableRow>
        </>
      ) : row_type === "normal_col" ? (
        <>
          <DataTableRow size={size ? size : "md"}>
            <span className="tb-lead-sub">{item}</span>
          </DataTableRow>
        </>
      ) : row_type === "view_col" ? (
        <>
          <DataTableRow className="nk-tb-col-tools">
            <Button
              className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
              href="#view"
              onClick={(ev) => {
                ev.preventDefault();
                setSelectedDataID(item_id);
                setViewModal(true);
              }}
            >
              <span>View</span>
            </Button>
          </DataTableRow>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ReadOnlyTableRow;
