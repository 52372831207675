import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    user_accounts_list: { status: "idle" },
};

export const user_accounts_list_Slice = createSlice({
    name: "user_accounts_list",
    initialState,
    reducers: {
        success_user_accounts_list: (state, action) => {
            state.user_accounts_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_user_accounts_list: (state, action) => {
            state.user_accounts_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_user_accounts_list: (state) => {
            state.user_accounts_list = { status: app_consts.IDLE };
        },
        loading_user_accounts_list: (state) => {
            state.user_accounts_list = { status: app_consts.LOADING };
        },
    }
});

export const { success_user_accounts_list, fail_user_accounts_list, reset_user_accounts_list, loading_user_accounts_list } = user_accounts_list_Slice.actions;

export default user_accounts_list_Slice.reducer;