import React from 'react'
import { Badge } from 'reactstrap'
import { Col } from '../../../../components/Component'

const LabelText = ({ label, text, badgeStat, badgeColor, pill, size, icon, iconStat, setAmendType, openAmendModal }) => {

    return (
        <>
            <Col lg={size ? size : 6}>
                <span className="sub-text">{label}</span>
                {
                    badgeStat ?
                        <>
                            <Badge
                                color={badgeColor}
                                size="md"
                                pill={pill}
                            >
                                {text}
                            </Badge>
                        </>
                        :
                        <>
                            <span className="caption-text text-break">{text}</span>
                        </>
                }
            </Col>
        </>
    )
}

export default LabelText