import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    amend_user_acc_status: { status: "idle" },
}

export const amend_user_acc_status_Slice = createSlice({
    name: "amend_user_acc_status",
    initialState,
    reducers: {
        success_amend_user_acc_status: (state, action) => {
            state.amend_user_acc_status = { status: app_consts.SUCCESS };
        },
        fail_amend_user_acc_status: (state, action) => {
            state.amend_user_acc_status = { status: app_consts.FAIL, message: action.payload };
        },
        reset_amend_user_acc_status: (state) => {
            state.amend_user_acc_status = { status: app_consts.IDLE }
        },
        loading_amend_user_acc_status: (state) => {
            state.amend_user_acc_status = { status: app_consts.LOADING }
        },
    }
})

export const { success_amend_user_acc_status, fail_amend_user_acc_status, reset_amend_user_acc_status, loading_amend_user_acc_status } = amend_user_acc_status_Slice.actions;

export default amend_user_acc_status_Slice.reducer;