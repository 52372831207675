import * as api from "../../../api/dashboard_apis/trailer/trailer_apis"
import { success_sign_out_modal } from "../../../reducers/dashboard_reducers/auth/sign_out_modal_Slice";
import * as app_consts from "../../../constants/react_app_consts"
import { messageToast, rSelectMapFun } from "../../../utils/Utils";
import { fail_create_trailer, loading_create_trailer, success_create_trailer } from "../../../reducers/dashboard_reducers/trailer/create_trailer_Slice";

// Add A Truck to the System
export const add_trailer_action = (formData) => async (dispatch) => {
    setTimeout(
        async function () {
            try {
                //Set Loading
                dispatch(loading_create_trailer())
                //Making API Request
                const { data } = await api.add_trailer_api(formData);

                if (data.message === "Trailer Onboarded Succesfully.") {
                    dispatch(success_create_trailer(data.data))
                }
            } catch (error) {
                console.log(error)
                if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE") {
                    dispatch(fail_create_trailer("Could Not Onboard Trailer."))
                }
                else {
                    if (error.response.status === 400) {
                        dispatch(fail_create_trailer(error.response.data.message))
                    }
                    else if (error.response.status === 401) {
                        dispatch(success_sign_out_modal())
                    }
                    else if (error.response.status === 403) {
                        messageToast(`Could Not Onboard Trailer. Please Reload the page.`, app_consts.FAIL)
                        dispatch(fail_create_trailer("Could Not Onboard Trailer. Please Reload the page."))
                    }
                }
            }
        }
    )
};