import * as api from "../../../api/dashboard_apis/locations/locations_apis";
import { success_sign_out_modal } from "../../../reducers/dashboard_reducers/auth/sign_out_modal_Slice";
import * as app_consts from "../../../constants/react_app_consts";
import { messageToast, rSelectMapFun } from "../../../utils/Utils";
import {
  fail_specific_country_all,
  loading_specific_country_all,
  success_specific_country_all,
} from "../../../reducers/dashboard_reducers/location/specific_country_all_Slice";

// Retrieve List of All Locations Based On User COuntry
export const retrieve_specific_country_all_user_kyc_det_action = (type, formData) => async (dispatch) => {
  setTimeout(async function () {
    try {
      //Set Loading
      dispatch(loading_specific_country_all());
      //Making API Request
      const { data } = await api.retrieve_specific_country_all_user_kyc_det_api(formData);

      if (data.message === "Specific Country Locations Retrieved Succesfully.") {
        if (type === app_consts.MAP) {
          // Map Received Data
          // Only Mapping Provinces At this Stage. Districts Mapped Later On.
          let mappedData = [];

          await Promise.all(
            data.data.provinces?.map(async (province) => {
              const result = await rSelectMapFun(province.province_name, province.province_id);
              mappedData.push(result);
            })
          );

          dispatch(
            success_specific_country_all({
              provinces: mappedData,
              districts: data.data.districts,
              country: data.data.country,
            })
          );
        } else {
          dispatch(success_specific_country_all(data.data));
        }
      }
    } catch (error) {
      console.log(error);
      if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE") {
        messageToast(`Could Not Retrieve Locations.`, app_consts.FAIL);
        dispatch(fail_specific_country_all("Could Not Retrieve Locations."));
      } else {
        if (error.response.status === 400) {
          dispatch(fail_specific_country_all(error.response.data.message));
        } else if (error.response.status === 401) {
          dispatch(success_sign_out_modal());
        } else if (error.response.status === 403) {
          messageToast(`Could Not Retrieve Locations. Please Reload the page.`, app_consts.FAIL);
          dispatch(fail_specific_country_all("Could Not Retrieve Locations. Please Reload the page."));
        }
      }
    }
  });
};
