import { makeWebsiteApiRequest } from "../api/website_api_helper";

// Code to Run All Actions that Are Needed to Communicate With the API Helper
export const website_actions_helper =
  (loadingState, successState, failState, errorMessage, successMessage, apiReqType, apiEndpoint, formData = null) =>
  async (dispatch) => {
    try {
      //Set Loading
      dispatch(loadingState());
      //Making API Request
      const { data } = await makeWebsiteApiRequest(apiReqType, apiEndpoint, formData);
      if (data.message === successMessage) {
        dispatch(successState(data.data));
      }
    } catch (error) {
      console.log(error);
      // Server or Network Error
      if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE") {
        dispatch(failState(errorMessage));
      } else {
        // Bad Response Message
        if (error.response.status === 400) {
          dispatch(failState(error.response.data.message));
        }
      }
    }
  };
