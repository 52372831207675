import React from "react";
import { Card, CardBody, CardText, CardTitle, Col } from "reactstrap";
import { Button, UserAvatar } from "../../../../components/Component";
import { Link } from "react-router-dom";
import * as app_consts from "../../../../constants/react_app_consts";

export const OptionCardLink = ({ linkHeading, linkValue, link_name, setFormData, formData }) => {
  return (
    <Col sm="12" className="mt-2 mb-2">
      <ul className="custom-control-group custom-control-vertical w-100">
        <li>
          <div className="custom-control custom-control-sm custom-checkbox custom-control-pro">
            <input
              type="radio"
              className="custom-control-input"
              id={link_name}
              name="user-choose"
              onClick={() => setFormData({ ...formData, ["send_otp_type"]: link_name })}
            />
            <label className="custom-control-label" htmlFor={link_name}>
              <span className="user-card">
                {/* <UserAvatar className="sm"></UserAvatar> */}
                <div className="flex-column">
                  <span className="lead-text">{linkHeading}</span>
                  <span className="sub-text spacing-md">{linkValue}</span>
                </div>
                {/* <span className="user-name">{linkHeading}</span> */}
              </span>
            </label>
          </div>
        </li>
      </ul>
    </Col>
  );
};
// CardLink Info
export const CardLinkInfo = ({
  colSize,
  iconType,
  icon,
  cardTitle,
  cardTextInfo,
  buttonLink,
  buttonType,
  buttonText,
  buttonModuleFunction,
  comp_data,
}) => {
  return (
    <Col lg={colSize ? colSize : "6"}>
      <Card className="card-bordered">
        <CardBody className="card-inner">
          <div className="user-card">
            {iconType === "custom" ? <div className="user-avatar">{icon}</div> : <UserAvatar icon={icon}></UserAvatar>}
          </div>
          <CardTitle tag="h5" className="mt-2">
            {cardTitle}
          </CardTitle>
          <CardText>{cardTextInfo}</CardText>
          {buttonType === app_consts.BUTTON_OPEN_PAGE_TYPE ? (
            <Link to={buttonLink}>
              <Button className="btn btn-outline-primary">
                <span>{buttonText}</span>
              </Button>
            </Link>
          ) : buttonType === app_consts.BUTTON_OPEN_MODAL_TYPE ? (
            <Button className="btn btn-outline-primary" onClick={() => buttonModuleFunction(comp_data)}>
              <span>{buttonText}</span>
            </Button>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
