import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    retreive_user_list_img: { status: "idle" },
}

export const retreive_user_list_img_Slice = createSlice({
    name: "retreive_user_list_img",
    initialState,
    reducers: {
        success_retreive_user_list_img: (state, action) => {
            state.retreive_user_list_img = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_retreive_user_list_img: (state, action) => {
            state.retreive_user_list_img = { status: app_consts.FAIL, message: action.payload };
        },
        reset_retreive_user_list_img: (state) => {
            state.retreive_user_list_img = { status: app_consts.IDLE }
        },
        loading_retreive_user_list_img: (state) => {
            state.retreive_user_list_img = { status: app_consts.LOADING }
        },
    }
})

export const { success_retreive_user_list_img, fail_retreive_user_list_img, reset_retreive_user_list_img, loading_retreive_user_list_img } = retreive_user_list_img_Slice.actions;

export default retreive_user_list_img_Slice.reducer;