// ---------------------------------------------- Success and Errors for Website Actions ------------------------------------------

// --------------------- Auth -------------------

// Username Verify
export const USERNAME_VERIFY_ERR_MSG = "Sign In Failed";
export const USERNAME_VERIFY_SUCESS_MSG = "User Account Found";

// Send OTP Auth
export const SENT_OTP_AUTH_ERR_MSG = "Failed to Send OTP";
export const SENT_OTP_AUTH_SUCESS_MSG = "OTP Sent Successfully";

// Verify OTP Auth
export const VERIFY_OTP_AUTH_ERR_MSG = "Failed to Verify OTP";
export const VERIFY_OTP_AUTH_SUCESS_MSG = "OTP Verification Successful";

// ------------------------------------------------------------ Success and Errors for Dashboard Actions ------------------------------------------

// ------------------------------------------------------------ Auth --------------------------------------------------
export const AUTH_ROUTES_ERR_MSG = "Could Not Retrieve Employee Roles";
export const AUTH_ROUTES_SUCCESS_MSG = "Active Employee Role Actions Retrieved Successfully";

// ------------------------------------------------------------ Users -------------------------------------------------------

// User List With Images
export const USER_LIST_IMGS_ERR_MSG = "Could Not Retrieve User List.";
export const USER_LIST_IMGS_SUCCESS_MSG = "Users Retrieved Successfully";
export const USER_LIST_IMGS_ACCESS_DENIED_MSG = "Could Not Retrieve User List. Please Reload the page.";

// Create Member Access Account
export const CREATE_USER_ERR_MSG = "Could Not Create User.";
export const CREATE_USERS_SUCCESS_MSG = "User successfully Onboarded";
export const CREATE_USERS_ACCESS_DENIED_MSG = "Could Not Create User. Please Reload the page.";

// Amend User Status
export const AMEND_USER_STATUS_ERR_MSG = "Could Not Amend User Status.";
export const AMEND_USER_STATUS_SUCCESS_MSG = "User Status Successfully Amended";
export const AMEND_USER_STATUS_ACCESS_DENIED_MSG = "Could Not Amend User Status. Please Reload the Page.";

// Retrive Active Users List
export const RETRIEVE_ACTIVE_USERS_ERR_MSG = "Could Not Retrieve Active Users.";
export const RETRIEVE_ACTIVE_USERS_SUCCESS_MSG = "Active Users Retrieved Successfully";
export const RETRIEVE_ACTIVE_USERS_ACCESS_DENIED_MSG = "Could Not Retrieve Active Users. Please Reload the Page";

// Retrive user KYC Status
export const RETRIEVE_USER_KYC_STATUS_ERR_MSG = "Could Not Retrieve User's KYC Status.";
export const RETRIEVE_USER_KYC_STATUS_SUCCESS_MSG = "User Details Successfully Retrieved.";
export const RETRIEVE_USER_KYC_STATUS_ACCESS_DENIED_MSG =
  "Could Not Retrieve User's KYC Status. Please Reload the page.";

// ------------------------------------------------------------ User Accounts ------------------------------------------------------

// Retrive Active Specific Unassigned User Accounts
export const RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ERR_MSG = "Could Not Retrieve User Account List.";
export const RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_SUCCESS_MSG = "User Accounts Successfully Retrieved.";
export const RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ACCESS_DENIED_MSG =
  "Could Not Retrieve User Account List. Please Reload the page.";

// Retrive Active User Accounts List
export const RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ERR_MSG = "Could Not Retrieve User Account List.";
export const RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_SUCCESS_MSG = "User Accounts Successfully Retrieved.";
export const RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve User Account List. Please Reload the page.";

// Create Truck Driver Account
export const CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_ERR_MSG = "Could Not Create Truck Driver User Account.";
export const CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_SUCCESS_MSG = "Truck Driver Account Created Successfully.";
export const CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG =
  "Could Not Create Truck Driver User Account. Please Reload the page.";

// Create Customer Account
export const CREATE_CUSTOMER_USER_ACCOUNT_LIST_ERR_MSG = "Could Not Create Customer Account.";
export const CREATE_CUSTOMER_USER_ACCOUNT_LIST_SUCCESS_MSG = "Customer Account Created Successfully.";
export const CREATE_CUSTOMER_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG =
  "Could Not Create Customer User Account. Please Reload the page.";

// Create Company Account
export const CREATE_COMPANY_USER_ACCOUNT_LIST_ERR_MSG = "Could Not Create Company Account.";
export const CREATE_COMPANY_USER_ACCOUNT_LIST_SUCCESS_MSG = "Company Account Created Successfully.";
export const CREATE_COMPANY_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG =
  "Could Not Create Company User Account. Please Reload the page.";

// ------------------------------------------------------------ User KYC -------------------------------------------------------

// Retrive Gender Types
export const RETRIEVE_GENDER_TYPE_ERR_MSG = "Could Not Retrieve Gender Types.";
export const RETRIEVE_GENDER_TYPE_SUCCESS_MSG = "Gender Types Retrieved Succesfully.";
export const RETRIEVE_GENDER_TYPE_ACCESS_DENIED_MSG = "Could Not Retrieve Gender Types. Please Reload the page.";

// Retrive Marital Status
export const RETRIEVE_MARITAL_STATUS_ERR_MSG = "Could Not Retrieve Marital Statuses.";
export const RETRIEVE_MARITAL_STATUS_SUCCESS_MSG = "Marital Statuses Retrieved Succesfully.";
export const RETRIEVE_MARITAL_STATUS_ACCESS_DENIED_MSG = "Could Not Retrieve Marital Statuses. Please Reload the page.";

// Retrive User Title
export const RETRIEVE_USER_TITLE_ERR_MSG = "Could Not Retrieve User Titles.";
export const RETRIEVE_USER_TITLE_SUCCESS_MSG = "User Titles Retrieved Succesfully.";
export const RETRIEVE_USER_TITLE_ACCESS_DENIED_MSG = "Could Not Retrieve User Titles. Please Reload the page.";

// Update Basic KYC Info
export const UPDATE_BASIC_KYC_INFO_ERR_MSG = "Could Not Update User's Basic Information.";
export const UPDATE_BASIC_KYC_INFO_SUCCESS_MSG = "User Basic Info Successfully Updated.";
export const UPDATE_BASIC_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Update User's Basic Information. Please Reload the page.";

// Update Document KYC Info
export const UPDATE_DOCUMENT_KYC_INFO_ERR_MSG = "Could Not Update User's Document Information.";
export const UPDATE_DOCUMENT_KYC_INFO_SUCCESS_MSG = "User Document Info Successfully Updated.";
export const UPDATE_DOCUMENT_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Update User's Document Information. Please Reload the page.";

// Update Address KYC Info
export const UPDATE_ADDRESS_KYC_INFO_ERR_MSG = "Could Not Update User's Address Information.";
export const UPDATE_ADDRESS_KYC_INFO_SUCCESS_MSG = "User Address Info Successfully Updated.";
export const UPDATE_ADDRESS_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Update User's Address Information. Please Reload the page.";

// Update NOK KYC Info
export const UPDATE_NOK_KYC_INFO_ERR_MSG = "Could Not Update User's Next of Kin Information.";
export const UPDATE_NOK_KYC_INFO_SUCCESS_MSG = "User Next of Kin Info Successfully Updated.";
export const UPDATE_NOK_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Update User's Next of Kin Information. Please Reload the page.";

// Update NOK KYC Info
export const UPDATE_IMAGE_KYC_INFO_ERR_MSG = "Could Not Update User's Image Information.";
export const UPDATE_IMAGE_KYC_INFO_SUCCESS_MSG = "User Image Info Successfully Updated.";
export const UPDATE_IMAGE_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Update User's Image Information. Please Reload the page.";

// Specific User KYC Info
export const SPECIFIC_USER_KYC_INFO_ERR_MSG = "Could Not Retrieve User's KYC Details.";
export const SPECIFIC_USER_KYC_INFO_SUCCESS_MSG = "User Details Successfully Retrieved.";
export const SPECIFIC_USER_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Retrieve User's KYC Details. Please Reload the page.";

// Uncomplete User's KYC List
export const UNCOMPLETE_USER_KYC_LIST_ERR_MSG = "Could Not Retrieve Uncomplete User's KYC List.";
export const UNCOMPLETE_USER_KYC_LIST_SUCCESS_MSG = "Uncompleted KYC User List Retrieved Successfully.";
export const UNCOMPLETE_USER_KYC_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve Uncomplete User's KYC List. Please Reload the page.";

// ------------------------------------------------------------ Branch Location -------------------------------------------------------

// Retrieve Branch Location List
export const RETRIEVE_BRANCH_LOCATION_LIST_ERR_MSG = "Could Not Retrieve Branch Location List.";
export const RETRIEVE_BRANCH_LOCATION_LIST_SUCCESS_MSG = "Branch Location List Retrieved Successfully.";
export const RETRIEVE_BRANCH_LOCATION_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve Branch Location List. Please Reload the page.";

// Add Branch Location
export const ADD_BRANCH_LOCATION_ERR_MSG = "Could Not Add Branch Location.";
export const ADD_BRANCH_LOCATION_SUCCESS_MSG = "Branch Location Added Successfully.";
export const ADD_BRANCH_LOCATION_ACCESS_DENIED_MSG = "Could Not Add Branch Location. Please Reload the page.";

// Amend Branch Location Status
export const AMEND_BRANCH_LOCATION_STATUS_ERR_MSG = "Could Not Amend Branch Location Status.";
export const AMEND_BRANCH_LOCATION_STATUS_SUCCESS_MSG = "Branch Location Status Amended Successfully.";
export const AMEND_BRANCH_LOCATION_STATUS_ACCESS_DENIED_MSG =
  "Could Not Amend Branch Location Status. Please Reload the page.";

// ------------------------------------------------------------ Locations -------------------------------------------------------

// Retrieve Provinces List
export const RETRIEVE_PROVINCES_LIST_ERR_MSG = "Could Not Retrieve Provinces List.";
export const RETRIEVE_PROVINCES_LIST_SUCCESS_MSG = "Provinces Retrieved Succesfully.";
export const RETRIEVE_PROVINCES_LIST_ACCESS_DENIED_MSG = "Could Not Retrieve Provinces List. Please Reload the page.";

// ------------------------------------------------------------ Products -------------------------------------------------------

// Retrieve Products List
export const RETRIEVE_PRODUCTS_LIST_ERR_MSG = "Could Not Retrieve Products List.";
export const RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG = "Product List Retrieved Sucessfully.";
export const RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG = "Could Not Retrieve Products List. Please Reload the page.";

// Amend Product Status
export const AMEND_PRODUCT_STATUS_ERR_MSG = "Could Not Amend Product Status.";
export const AMEND_PRODUCT_STATUS_SUCCESS_MSG = "Product Status Amended Successfully.";
export const AMEND_PRODUCT_STATUS_ACCESS_DENIED_MSG = "Could Not Amend Product Status. Please Reload the page.";

// Add Product
export const ADD_PRODUCT_ERR_MSG = "Could Not Add Product.";
export const ADD_PRODUCT_SUCCESS_MSG = "Product Added Successfully.";
export const ADD_PRODUCT_ACCESS_DENIED_MSG = "Could Not Add Product. Please Reload the page.";

// Amend Product Type Status
export const AMEND_PRODUCT_TYPE_STATUS_ERR_MSG = "Could Not Amend Product Type Status.";
export const AMEND_PRODUCT_TYPE_STATUS_SUCCESS_MSG = "Product Type Status Amended Successfully.";
export const AMEND_PRODUCT_TYPE_STATUS_ACCESS_DENIED_MSG =
  "Could Not Amend Product Type Status. Please Reload the page.";

// Add Product Type
export const ADD_PRODUCT_TYPE_ERR_MSG = "Could Not Add Product Type.";
export const ADD_PRODUCT_TYPE_SUCCESS_MSG = "Product Type Added Successfully.";
export const ADD_PRODUCT_TYPE_ACCESS_DENIED_MSG = "Could Not Add Product Type. Please Reload the page.";

// Retrieve Products Types List
export const RETRIEVE_PRODUCT_TYPES_LIST_ERR_MSG = "Could Not Retrieve Product Types List.";
export const RETRIEVE_PRODUCT_TYPES_LIST_SUCCESS_MSG = "Product Types List Retrieved Sucessfully.";
export const RETRIEVE_PRODUCT_TYPES_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve Product Types List. Please Reload the page.";

// ------------------------------------------------------------ Unit Measurements -------------------------------------------------------

// Unit Measurement Status
export const RETRIEVE_UNIT_MEASUREMENTS_LIST_ERR_MSG = "Could Not Retrieve Unit Measurements List.";
export const RETRIEVE_UNIT_MEASUREMENTS_LIST_SUCCESS_MSG = "Unit Measurements List Retrieved Sucessfully.";
export const RETRIEVE_UNIT_MEASUREMENTS_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve Unit Measurements. Please Reload the page.";

// Amend Unit Measurement Status
export const AMEND_UNIT_MEASUREMENT_STATUS_ERR_MSG = "Could Not Amend Unit Measurement Status.";
export const AMEND_UNIT_MEASUREMENT_STATUS_SUCCESS_MSG = "Unit Measurement Status Amended Sucessfully.";
export const AMEND_UNIT_MEASUREMENT_STATUS_ACCESS_DENIED_MSG =
  "Could Not Amend Unit Measurement Status. Please Reload the page.";

// Add Unit Measurement
export const ADD_UNIT_MEASUREMENT_ERR_MSG = "Could Not Add Unit Measurement.";
export const ADD_UNIT_MEASUREMENT_SUCCESS_MSG = "Unit Measurement Status Added Sucessfully.";
export const ADD_UNIT_MEASUREMENT_ACCESS_DENIED_MSG = "Could Not Add Unit Measurement. Please Reload the page.";

// ------------------------------------------------------------ User Account Property -------------------------------------------------------

// User Account Property
export const ADD_USER_ACCOUNT_PROPERTY_ERR_MSG = "Could Not Add User Account Property.";
export const ADD_USER_ACCOUNT_PROPERTY_SUCCESS_MSG = "User Account Property Added Successfully.";
export const ADD_USER_ACCOUNT_PROPERTY_ACCESS_DENIED_MSG =
  "Could Not Add User Account Property. Please Reload the page.";

// ------------------------------------------------------------ Company Representative -------------------------------------------------------

// Add Company Representative Role
export const ADD_COMPANY_REPRESENTATIVE_ROLE_ERR_MSG = "Could Not Add Company Representative Role.";
export const ADD_COMPANY_REPRESENTATIVE_ROLE_SUCCESS_MSG = "Company Representative Role Added Successfully.";
export const ADD_COMPANY_REPRESENTATIVE_ROLE_ACCESS_DENIED_MSG =
  "Could Not Add Company Representative Role. Please Reload the page.";

// Retrieve Company Representative Role List
export const RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_ERR_MSG = "Could Not Retrieve Company Representative Roles.";
export const RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_SUCCESS_MSG =
  "Company Representative Roles Retrieved Successfully.";
export const RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_ACCESS_DENIED_MSG =
  "Could Not  Retrieve Company Representative Roles. Please Reload the page.";

// Add Company Representative
export const ADD_COMPANY_REPRESENTATIVE_ERR_MSG = "Could Not Add Company Representative.";
export const ADD_COMPANY_REPRESENTATIVE_SUCCESS_MSG = "Company Representative Added Successfully.";
export const ADD_COMPANY_REPRESENTATIVE_ACCESS_DENIED_MSG =
  "Could Not  Add Company Representative. Please Reload the page.";

// ------------------------------------------------------------ Trucks and Trailers -------------------------------------------------------

// Retrieve Truck and Trailers List
export const RETRIEVE_TURCKS_TRAILERS_LIST_ERR_MSG = "Could Not Retrieve Truck and Trailers List.";
export const RETRIEVE_TURCKS_TRAILERS_LIST_SUCCESS_MSG = "Truck and Trailers List Retrieved Successfully.";
export const RETRIEVE_TURCKS_TRAILERS_LIST_ACCESS_DENIED_MSG =
  "Could Not  Retrieve Truck and Trailers List. Please Reload the page.";

// ------------------------------------------------------------ Weigh Bridge Ticket -------------------------------------------------------

// Retrieve Weigh Bridge Ticket Type List
export const RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_ERR_MSG = "Could not Retrieve Weigh Bridge Ticket Type List.";
export const RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_SUCCESS_MSG = "Weigh Bridge Ticket Type List Retrieved Successfully.";
export const RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_ACCESS_DENIED_MSG =
  "Could Not Retrieve Weigh Bridge Ticket Type List. Please Reload the page.";