import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    specific_country_all: { status: "idle" },
}

export const specific_country_all_Slice = createSlice({
    name: "specific_country_all",
    initialState,
    reducers: {
        success_specific_country_all: (state, action) => {
            state.specific_country_all = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_specific_country_all: (state, action) => {
            state.specific_country_all = { status: app_consts.FAIL, message: action.payload };
        },
        reset_specific_country_all: (state) => {
            state.specific_country_all = { status: app_consts.IDLE }
        },
        loading_specific_country_all: (state) => {
            state.specific_country_all = { status: app_consts.LOADING }
        },
    }
})

export const { success_specific_country_all, fail_specific_country_all, reset_specific_country_all, loading_specific_country_all } = specific_country_all_Slice.actions;

export default specific_country_all_Slice.reducer;