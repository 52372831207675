import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { Col, Row } from "../../../../../../components/Component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../../../constants/react_app_consts";
import StepGuideCard from "../../wizard_steps_guide/StepGuide";
import GenerateCustomerIDStep from "./steps/assign_customer_steps/GenerateCustomerIDStep";
import { reset_verify_user_kyc_status } from "../../../../../../reducers/dashboard_reducers/user_kyc/verify_user_kyc_status_Slice";
import OnboardCustomerProperty from "./steps/assign_customer_steps/OnboardCustomerPropertyStep";

const AssignCustomerAccountIndex = ({ wizardType }) => {
  const [step, setStep] = useState(1);
  const [wizardData, setWizardData] = useState([]);
  const [uniqueID, setUniqueID] = useState([]);
  const dispatch = useDispatch();

  // Listen to Changes in User KYC Details Reducer
  const verify_user_kyc_status = useSelector((state) => state.verify_user_kyc_status.verify_user_kyc_status);
  //
  useEffect(() => {
    if (verify_user_kyc_status.status === app_consts.SUCCESS) {
      setWizardData(verify_user_kyc_status.data);
      dispatch(reset_verify_user_kyc_status());
    }
  }, [verify_user_kyc_status]);

  // Next Step
  const nextStep = () => {
    setStep(step + 1);
  };
  return (
    <>
      <Card className="card-bordered">
        <Row className="g-0 col-sep col-sep-md col-sep-xl">
          <StepGuideCard sentStep={step} wizard_type={wizardType} wizard_title="Assign Customer Account" />
          <Col className="col-md-8 col-xl-8">
            {step === 1 ? (
              <GenerateCustomerIDStep
                togglestep={step}
                nextStep={nextStep}
                data={wizardData}
                setUniqueID={setUniqueID}
              />
            ) : step === 2 ? (
              <OnboardCustomerProperty togglestep={step} nextStep={nextStep} data={wizardData} uniqueID={uniqueID} />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default AssignCustomerAccountIndex;
