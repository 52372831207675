import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Spinner } from "reactstrap";
import TextInput from "../../../../../../../components/inputs/TextInput";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../../../components/Component";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { retrieve_specific_country_all_user_kyc_det_action } from "../../../../../../../../actions/dashboard_actions/locations/locations_actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { reset_specific_country_all } from "../../../../../../../../reducers/dashboard_reducers/location/specific_country_all_Slice";
import { messageToast } from "../../../../../../../../utils/Utils";
import {
  fail_update_address_info,
  loading_update_address_info,
  reset_update_address_info,
  success_update_address_info,
} from "../../../../../../../../reducers/dashboard_reducers/user_kyc/update_address_info_Slice";
import { dashboard_actions_helper } from "../../../../../../../../actions/dashboard_actions_helper";
import {
  UPDATE_ADDRESS_KYC_INFO_ACCESS_DENIED_MSG,
  UPDATE_ADDRESS_KYC_INFO_ERR_MSG,
  UPDATE_ADDRESS_KYC_INFO_SUCCESS_MSG,
} from "../../../../../../../../constants/success_error_messages_consts";
import { UPDATE_ADDRESS_KYC_INFO } from "../../../../../../../../api/dashboard_api_data";

const initialState = { phone_number: "", address: "", district_id: "" };

const AddressInfoStep = ({ togglestep, nextStep, data }) => {
  const dispatch = useDispatch();

  const [errorVal, setError] = useState("");
  const [formData, setFormData] = useState(initialState);
  const [user_data, setUserData] = useState([]);
  const [step, setStep] = useState("");
  const [province_list, setProvinceList] = useState([]);
  const [district_list, setDistrictList] = useState([]);
  const [district_data, setDistrictData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState("");
  // Reading Data Passed Along
  useEffect(() => {
    if (data) {
      setFormData({ ...formData, ["phone_number"]: data.phone_number });
      setUserData(data);
      setStep(togglestep);
      // Only Run Code When Step is equal to One - 3
      if (data.iso_code_2 === app_consts.DEFAULT_ISO_CODE_2 && togglestep === 3) {
        dispatch(
          retrieve_specific_country_all_user_kyc_det_action(app_consts.MAP, { phone_number: data.phone_number })
        );
      }
    }
  }, [data, togglestep]);

  // Listen to Changes in Specific Country's Provinces and Districts Reducer
  const specific_country_all = useSelector((state) => state.specific_country_all.specific_country_all);
  //
  useEffect(() => {
    if (specific_country_all.status !== app_consts.IDLE && specific_country_all.status !== app_consts.LOADING) {
      if (specific_country_all.status === app_consts.FAIL) {
        messageToast(specific_country_all.message, app_consts.FAIL);
      } else if (specific_country_all.status === app_consts.SUCCESS) {
        // setGender(specific_country_all.data)
        setProvinceList(specific_country_all.data.provinces);
        setDistrictData(specific_country_all.data.districts);
      }
      dispatch(reset_specific_country_all());
    }
  }, [specific_country_all]);

  useEffect(() => {
    filterDistricts(selectedProvince);
  }, [selectedProvince]);

  // Filter Districts
  const filterDistricts = (received_id) => {
    let filteredDistricts = district_data?.filter((item) => {
      return item.province_id === received_id;
    });
    // Remapped Districts
    const mappedDistricts = filteredDistricts?.map((item) => {
      return {
        label: item.district_name,
        value: item.district_id,
      };
    });
    setDistrictList(mappedDistricts);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };
  // Submit Form
  const onFormSubmit = (e) => {
    e.preventDefault();
    setError("");
    // Update Address Info
    dispatch(
      dashboard_actions_helper(
        loading_update_address_info,
        success_update_address_info,
        fail_update_address_info,
        UPDATE_ADDRESS_KYC_INFO_ERR_MSG,
        UPDATE_ADDRESS_KYC_INFO_SUCCESS_MSG,
        app_consts.POST,
        UPDATE_ADDRESS_KYC_INFO,
        formData,
        UPDATE_ADDRESS_KYC_INFO_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to the Update Address Reducer
  const update_address_info = useSelector((state) => state.update_address_info.update_address_info);
  //
  useEffect(() => {
    if (update_address_info.status !== app_consts.IDLE) {
      if (update_address_info.status === app_consts.LOADING) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
        if (update_address_info.status === app_consts.FAIL) {
          messageToast(update_address_info.message, app_consts.FAIL);
          setError(update_address_info.message, app_consts.FAIL);
        } else if (update_address_info.status === app_consts.SUCCESS) {
          messageToast("User Address Info Successfully Updated", app_consts.SUCCESS);
          nextStep();
        }
        dispatch(reset_update_address_info());
      }
    }
  }, [update_address_info]);

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Address Information</CardTitle>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row className="g-4">
            <TextInput
              label="Country"
              type="text"
              id_val="country"
              disabled
              value={user_data.country ? user_data.country : ""}
              name="country"
            />
            <TextInput
              label="Address"
              type="text"
              id_val="address"
              value={formData.address}
              handleChange={handleChange}
              name="address"
              maxlength="100"
            />
            {user_data.iso_code_2 &&
              (user_data.iso_code_2 === app_consts.DEFAULT_ISO_CODE_2 ? (
                <>
                  <Col lg="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="province_state">
                        Province/ State
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={province_list}
                          className="w-100%"
                          id="province_state"
                          onChange={(e) => {
                            setFormData({ ...formData, ["district_id"]: "" });
                            setSelectedProvince(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="district_id">
                        County/ District
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={district_list}
                          className="w-100%"
                          id="district_id"
                          onChange={(e) => setFormData({ ...formData, ["district_id"]: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                </>
              ) : (
                ""
              ))}
            <Col xl="12">
              <div className="form-group">
                <div className="form-control-wrap-flex-wrap justify-end">
                  <Button
                    onClick={onFormSubmit}
                    color="primary"
                    size="lg"
                    disabled={
                      loading
                        ? loading
                        : user_data.iso_code_2 === app_consts.DEFAULT_ISO_CODE_2
                        ? !(formData.address && formData.district_id)
                        : !formData.address
                    }
                  >
                    {loading ? <Spinner size="sm" color="light" /> : "Next"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default AddressInfoStep;
