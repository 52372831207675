import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  amend_branch_location_status: { status: "idle" },
};

export const amend_branch_location_status_Slice = createSlice({
  name: "amend_branch_location_status",
  initialState,
  reducers: {
    success_amend_branch_location_status: (state, action) => {
      state.amend_branch_location_status = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_amend_branch_location_status: (state, action) => {
      state.amend_branch_location_status = { status: app_consts.FAIL, message: action.payload };
    },
    reset_amend_branch_location_status: (state) => {
      state.amend_branch_location_status = { status: app_consts.IDLE };
    },
    loading_amend_branch_location_status: (state) => {
      state.amend_branch_location_status = { status: app_consts.LOADING };
    },
  },
});

export const {
  success_amend_branch_location_status,
  fail_amend_branch_location_status,
  reset_amend_branch_location_status,
  loading_amend_branch_location_status,
} = amend_branch_location_status_Slice.actions;

export default amend_branch_location_status_Slice.reducer;
