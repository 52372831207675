import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  provinces_list: { status: "idle" },
};

export const provinces_list_Slice = createSlice({
  name: "provinces_list",
  initialState,
  reducers: {
    success_provinces_list: (state, action) => {
      state.provinces_list = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_provinces_list: (state, action) => {
      state.provinces_list = { status: app_consts.FAIL, message: action.payload };
    },
    reset_provinces_list: (state) => {
      state.provinces_list = { status: app_consts.IDLE };
    },
    loading_provinces_list: (state) => {
      state.provinces_list = { status: app_consts.LOADING };
    },
  },
});

export const { success_provinces_list, fail_provinces_list, reset_provinces_list, loading_provinces_list } =
  provinces_list_Slice.actions;

export default provinces_list_Slice.reducer;
