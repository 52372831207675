import axios from "axios";

const API_URL = process.env.REACT_APP_EMPLOYEE_DASHBOARD_API_ENDPOINT;

axios.interceptors.request.use((req) => {
    if (localStorage.getItem("access")) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access")).access_token}`;
    }
    return req;
});

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

// Send otp to Initiate Complete KYC Reg Form 
export const retrieve_ownership_statuses_api = () => axios.get(API_URL + "ownershp_status/retrieve_ownership_statuses", { withCredentials: false });