import { configureStore } from "@reduxjs/toolkit";

// Auth Reducers
import loggedInCredReducer from "../reducers/dashboard_reducers/auth/logged_in_cred_Slice";
import signOutReducer from "../reducers/dashboard_reducers/auth/sign_out_Slice";
import authRoutesReducer from "../reducers/dashboard_reducers/auth/auth_routes_Slice";
import signOutModalReducer from "../reducers/dashboard_reducers/auth/sign_out_modal_Slice";

// User Reducers
import createUserReducer from "../reducers/dashboard_reducers/user/create_user_Slice";
import retrieveUserListReducer from "../reducers/dashboard_reducers/user/retreive_user_list_img_Slice";
import amendUserAccStatusReducer from "../reducers/dashboard_reducers/user/amend_user_acc_status_Slice";
import activeUsersListReducer from "../reducers/dashboard_reducers/user/active_users_list_Slice";
import specificUserDetailsReducer from "../reducers/dashboard_reducers/user/specific_user_details_Slice";

// User Account Reducers
import userAccountListReducer from "../reducers/dashboard_reducers/user_accounts/user_accounts_list_Slice";
import assignUserAccountListReducer from "../reducers/dashboard_reducers/user_accounts/assign_user_account_list_Slice";
import createTruckDriverReducer from "../reducers/dashboard_reducers/user_accounts/create_truck_driver_Slice";
import createCustomerAccountReducer from "../reducers/dashboard_reducers/user_accounts/create_customer_account_Slice";
import createCompanyAccountReducer from "../reducers/dashboard_reducers/user_accounts/create_compnay_account_Slice";

// User KYC Reducers
import verifyKycStatusReducer from "../reducers/dashboard_reducers/user_kyc/verify_user_kyc_status_Slice";
import specificUserKYCDetReducer from "../reducers/dashboard_reducers/user_kyc/specific_user_kyc_det_Slice";
import updateBasicKycInfoReducer from "../reducers/dashboard_reducers/user_kyc/update_basic_kyc_info_Slice";
import updateDocumentInfoReducer from "../reducers/dashboard_reducers/user_kyc/update_document_info_Slice";
import updateAddressInfoReducer from "../reducers/dashboard_reducers/user_kyc/update_address_info_Slice";
import updateNokKycInfoReducer from "../reducers/dashboard_reducers/user_kyc/update_nok_kyc_info_Slice";
import updateimageInfoUserKYCReducer from "../reducers/dashboard_reducers/user_kyc/update_image_info_user_kyc_Slice";

// OTP
import sendOTPReducer from "../reducers/dashboard_reducers/otp/send_otp_Slice";
import verifyOTPReducer from "../reducers/dashboard_reducers/otp/verify_otp_Slice";

// Gender
import genderTypeListReducer from "../reducers/dashboard_reducers/gender/gender_type_list_Slice";

// Marital Status
import maritalStatusListReducer from "../reducers/dashboard_reducers/marital_status/marital_status_list_Slice";

// User Title
import userTitleListReducer from "../reducers/dashboard_reducers/user_title/user_title_list_Slice";

// Locations
import specificCountryAllReducer from "../reducers/dashboard_reducers/location/specific_country_all_Slice";
import provincesListReducer from "../reducers/dashboard_reducers/location/provinces_list_Slice";

// Ownership Status
import onwershipStatusListReducer from "../reducers/dashboard_reducers/ownership_status/ownership_status_list_Slice";

// Trucks
import createTruckReducer from "../reducers/dashboard_reducers/truck/create_truck_Slice";
import retrieveTrucksTrailersReducer from "../reducers/dashboard_reducers/truck/retrieve_trucks_trailers_list_Slice";

// Triler
import createTrailerReducer from "../reducers/dashboard_reducers/trailer/create_trailer_Slice";

// Branch Locations productListReducer
import branchLocationListReducer from "../reducers/dashboard_reducers/branch_locations/branch_location_list_Slice";
import addBranchLocationReducer from "../reducers/dashboard_reducers/branch_locations/add_branch_location_Slice";
import amendBranchLocationStatusReducer from "../reducers/dashboard_reducers/branch_locations/amend_branch_location_status_Slice";

// Products
import productListReducer from "../reducers/dashboard_reducers/products/product_list_Slice";
import amendProductStatusReducer from "../reducers/dashboard_reducers/products/amend_product_status_Slice";
import addProductReducer from "../reducers/dashboard_reducers/products/add_product_Slice";

// Product Type
import productTypeListReducer from "../reducers/dashboard_reducers/products/product_type_list_Slice";
import amendProductTypeStatusReducer from "../reducers/dashboard_reducers/products/amend_product_type_status_Slice";
import addProductTypeReducer from "../reducers/dashboard_reducers/products/add_product_type_Slice";

// Unit Measurements
import unitMeasurementListReducer from "../reducers/dashboard_reducers/unit_measurement/unit_measurements_list_Slice";
import amendUnitMeasurementStatusReducer from "../reducers/dashboard_reducers/unit_measurement/amend_unit_measurement_status_Slice";
import addUnitmeasurementReducer from "../reducers/dashboard_reducers/unit_measurement/add_unit_measurement_Slice";

// User Account Property
import addUserAccountPropertyReducer from "../reducers/dashboard_reducers/user_account_property/add_user_account_property_Slice";

// Company Representative
import addCompanyRepresentativeRoleReducer from "../reducers/dashboard_reducers/company_representative/add_company_representative_role_Slice";
import retrieveCompanyRepresentativeRoleListReducer from "../reducers/dashboard_reducers/company_representative/retrieve_company_representatives_role_list_Slice";
import addCompanyRepresentativeReducer from "../reducers/dashboard_reducers/company_representative/add_company_representative_Slice";

// Weigh Bridge Ticket Type Reducer 
import weighBridgeTicketTypeListReducer from "../reducers/dashboard_reducers/warehuose/weigh_bridge_ticket_type_list_Slice";

// Store For All Reducers
export const dashboard_store = configureStore({
  reducer: {
    // Auth
    logged_in_cred: loggedInCredReducer,
    sign_out: signOutReducer,
    auth_routes: authRoutesReducer,
    sign_out_modal: signOutModalReducer,

    // User
    create_user: createUserReducer,
    retreive_user_list_img: retrieveUserListReducer,
    amend_user_acc_status: amendUserAccStatusReducer,
    specific_user_details: specificUserDetailsReducer,

    // User List ---- KYC List ---
    active_users_list: activeUsersListReducer,

    // User Acconut
    user_accounts_list: userAccountListReducer,
    assign_user_account_list: assignUserAccountListReducer,
    create_truck_driver: createTruckDriverReducer,
    create_customer_account: createCustomerAccountReducer,
    create_compnay_account: createCompanyAccountReducer,

    // User Kyc
    verify_user_kyc_status: verifyKycStatusReducer,
    specific_user_kyc_det: specificUserKYCDetReducer,
    update_basic_kyc_info: updateBasicKycInfoReducer,
    update_document_info: updateDocumentInfoReducer,
    update_address_info: updateAddressInfoReducer,
    update_nok_kyc_info: updateNokKycInfoReducer,
    update_image_info_user_kyc: updateimageInfoUserKYCReducer,

    // OTP
    send_otp: sendOTPReducer,
    verify_otp: verifyOTPReducer,

    // Gender
    gender_type_list: genderTypeListReducer,

    // Marital Status
    marital_status_list: maritalStatusListReducer,

    // User Title
    user_title_list: userTitleListReducer,

    // Loactions
    specific_country_all: specificCountryAllReducer,
    provinces_list: provincesListReducer,

    // Ownership Status
    ownership_status_list: onwershipStatusListReducer,

    // Trucks
    create_truck: createTruckReducer,
    retrieve_trucks_trailers_list: retrieveTrucksTrailersReducer,

    // Trailers
    create_trailer: createTrailerReducer,

    // Branch Locations
    branch_location_list: branchLocationListReducer,
    add_branch_location: addBranchLocationReducer,
    amend_branch_location_status: amendBranchLocationStatusReducer,

    // Products
    product_list: productListReducer,
    amend_product_status: amendProductStatusReducer,
    add_product: addProductReducer,

    // Product Types
    product_type_list: productTypeListReducer,
    amend_product_type_status: amendProductTypeStatusReducer,
    add_product_type: addProductTypeReducer,

    // Unit Measurement
    unit_measurement_list: unitMeasurementListReducer,
    amend_unit_measurement_status: amendUnitMeasurementStatusReducer,
    add_unit_measurement: addUnitmeasurementReducer,

    // User Account Property
    add_user_account_property: addUserAccountPropertyReducer,

    // Company Representative
    add_company_representative_role: addCompanyRepresentativeRoleReducer,
    retrieve_company_representatives_role_list: retrieveCompanyRepresentativeRoleListReducer,
    add_company_representative: addCompanyRepresentativeReducer,

    // Weigh Bridge Ticket
    weigh_bridge_ticket_type_list: weighBridgeTicketTypeListReducer,
  },
});
