import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    create_truck: { status: "idle" },
}

export const create_truck_Slice = createSlice({
    name: "create_truck",
    initialState,
    reducers: {
        success_create_truck: (state, action) => {
            state.create_truck = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_create_truck: (state, action) => {
            state.create_truck = { status: app_consts.FAIL, message: action.payload };
        },
        reset_create_truck: (state) => {
            state.create_truck = { status: app_consts.IDLE }
        },
        loading_create_truck: (state) => {
            state.create_truck = { status: app_consts.LOADING }
        },
    }
})

export const { success_create_truck, fail_create_truck, reset_create_truck, loading_create_truck } = create_truck_Slice.actions;

export default create_truck_Slice.reducer;