import React, { useEffect, useState } from "react";
import { Card, CardTitle } from "reactstrap";
import { Button, Col } from "../../../../../../../../components/Component";
import ReadOnlyTable from "../../../../../table/ReadOnlyTable";
import ModalAdd from "../../../../../modal/modal_add/ModalAdd";
import * as app_consts from "../../../../../../../../constants/react_app_consts";

const type = app_consts.ONBOARD_COMPANY_REPRESENTATIVE;

const OnboardCompanyRepresentativeStep = ({ togglestep, nextStep, data, uniqueID }) => {
  const [user_account_id, setUserAccountId] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [companyRep, setCompanyRep] = useState([]);
  const [openRepModal, setOpenRepModal] = useState(false);

  useEffect(() => {
    if (uniqueID.user_account_id) {
      setUserAccountId(uniqueID.user_account_id);
    }
  }, [uniqueID]);

  //   Open Add Company Representative
  const openCompRepModal = () => {
    setOpenAddModal(true);
    setOpenRepModal(false);
  };

  // Modal Success Function
  const successAddFunc = (company_rep_data = null) => {
    setOpenAddModal(false);
    setCompanyRep([...companyRep, company_rep_data]);
  };

  const openAddCompRepModal = () => {
    setOpenAddModal(false);
    setOpenRepModal(true);
  };

  useEffect(() => {
    console.log(companyRep);
  }, [companyRep]);

  return (
    <>
      <Card className="card-inner">
        <div className="d-flex justify-between items-center">
          <CardTitle tag="h5">Onboard Company Representative</CardTitle>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Col lg="12" className="mb-3">
            <div className="form-group mb-3">
              <div className="form-label-group">
                <label className="form-label text-soft">User Account ID :</label>
              </div>
              <div className="form-control-wrap">
                <label className="form-label text-primary">{user_account_id ? user_account_id : ""}</label>
              </div>
            </div>
          </Col>
          <Col xl="12" className="my-3">
            <div className="form-group">
              <div className="form-control-wrap-flex-wrap justify-start">
                <Button onClick={openCompRepModal} className="btn btn-dim btn-outline-primary" size="md">
                  Add Company Representative
                </Button>
              </div>
            </div>
          </Col>
        </form>
        {/* Table */}
        <div className="my-3">
          <ReadOnlyTable data={companyRep} tableType={type} />
        </div>
        <Col xl="12" className="d-flex justify-content-end">
          <Button onClick={nextStep} size="lg" type="submit" color="primary">
            Onboard Company Property
          </Button>
        </Col>
      </Card>
      {/* Add Modal For Company Representative */}
      <ModalAdd
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        modalTitle="Onboard Company Representative"
        modalDescription="Please Complete this form to Onboard a Company Representative"
        type={type}
        succFunc={successAddFunc}
        accountId={user_account_id}
        openOtherModalFunc={openAddCompRepModal}
      />
      {/* Add Modal For Company Representative Role*/}
      <ModalAdd
        openModal={openRepModal}
        setOpenModal={setOpenRepModal}
        modalTitle="Add Company Representative Role"
        modalDescription="Please Complete this form to Onboard a Company Representative Role"
        type={app_consts.ONBOARD_COMPANY_REPRESENTATIVE_ROLE}
        succFunc={openCompRepModal}
        size="md"
        openOtherModalFunc={openCompRepModal}
        accountId={user_account_id}
      />
    </>
  );
};

export default OnboardCompanyRepresentativeStep;
