import React from "react";
import * as app_consts from "../../../../constants/react_app_consts";
import CreateGoodsReceivedWizardIndex from "./wizard_pages/warehouse/CreateGoodsReceivedWizardIndex";
import CompleteKYCRegWizardIndex from "./wizard_pages/onboarding/CompleteKYCRegWizardIndex";
import AssignCustomerAccountIndex from "./wizard_pages/onboarding/AssignCustomerAccountIndex";
import OnboardCompanyWizardIndex from "./wizard_pages/onboarding/OnboardCompanyWizardIndex";

const WizardComp = ({ type }) => {
  return (
    <>
      {type === app_consts.CREATE_GOODS_RECEIVED_TICKET ? (
        <CreateGoodsReceivedWizardIndex wizardType={type} />
      ) : type === app_consts.COMPLETE_KYC_REGISTRATION ? (
        <CompleteKYCRegWizardIndex wizardType={type} />
      ) : type === app_consts.ASSIGN_CUSTOMER_ACCOUNT ? (
        <AssignCustomerAccountIndex wizardType={type} />
      ) : type === app_consts.ONBOARD_COMPANY ? (
        <OnboardCompanyWizardIndex wizardType={type} />
      ) : (
        ""
      )}
    </>
  );
};

export default WizardComp;
