import * as api from "../../../api/dashboard_apis/otp/otp_apis"
import { success_sign_out_modal } from "../../../reducers/dashboard_reducers/auth/sign_out_modal_Slice";
import * as app_consts from "../../../constants/react_app_consts"
import { messageToast } from "../../../utils/Utils";
import { fail_send_otp, loading_send_otp, success_send_otp } from "../../../reducers/dashboard_reducers/otp/send_otp_Slice";
import { fail_verify_otp, loading_verify_otp, success_verify_otp } from "../../../reducers/dashboard_reducers/otp/verify_otp_Slice";

// Send OTP to User To Enable Completion of KYC Information
export const send_complete_kyc_reg_otp_action = (formData) => async (dispatch) => {
    setTimeout(
        async function () {
            try {
                //Set Loading
                dispatch(loading_send_otp())
                //Making API Request
                const { data } = await api.send_complete_kyc_reg_otp_api(formData);

                if (data.message === "OTP Successfully Sent.") {
                    dispatch(success_send_otp(data.data))
                }
            } catch (error) {
                console.log(error)
                if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE") {
                    dispatch(fail_send_otp("Could Not Send OTP."))
                    messageToast(`Could Not Send OTP.`, app_consts.FAIL)
                }
                else {
                    if (error.response.status === 400) {
                        dispatch(fail_send_otp(error.response.data.message))
                    }
                    else if (error.response.status === 401) {
                        dispatch(success_sign_out_modal())
                    }
                    else if (error.response.status === 403) {
                        messageToast(`Could Not Send OTP. Please Reload the page.`, app_consts.FAIL)
                        dispatch(fail_send_otp("Could Not Send OTP. Please Reload the page."))
                    }
                }
            }
        }
    )
};

// Verify OTP Sent to User To Enable Completion of KYC Information
export const verify_complete_kyc_reg_otp_action = (formData) => async (dispatch) => {
    setTimeout(
        async function () {
            try {
                //Set Loading
                dispatch(loading_verify_otp())
                //Making API Request
                const { data } = await api.verify_complete_kyc_reg_otp_api(formData);

                if (data.message === "OTP Successfully Verified.") {
                    dispatch(success_verify_otp(data.data))
                }
            } catch (error) {
                console.log(error)
                if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE") {
                    dispatch(fail_verify_otp("Could Not Verify OTP."))
                    messageToast(`Could Not Verify OTP.`, app_consts.FAIL)
                }
                else {
                    if (error.response.status === 400) {
                        dispatch(fail_verify_otp(error.response.data.message))
                    }
                    else if (error.response.status === 401) {
                        dispatch(success_sign_out_modal())
                    }
                    else if (error.response.status === 403) {
                        messageToast(`Could Not Verify OTP. Please Reload the page.`, app_consts.FAIL)
                        dispatch(fail_verify_otp("Could Not Verify OTP. Please Reload the page."))
                    }
                }
            }
        }
    )
};