import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    update_address_info: { status: "idle" },
}

export const update_address_info_Slice = createSlice({
    name: "update_address_info",
    initialState,
    reducers: {
        success_update_address_info: (state, action) => {
            state.update_address_info = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_update_address_info: (state, action) => {
            state.update_address_info = { status: app_consts.FAIL, message: action.payload };
        },
        reset_update_address_info: (state) => {
            state.update_address_info = { status: app_consts.IDLE }
        },
        loading_update_address_info: (state) => {
            state.update_address_info = { status: app_consts.LOADING }
        },
    }
})

export const { success_update_address_info, fail_update_address_info, reset_update_address_info, loading_update_address_info } = update_address_info_Slice.actions;

export default update_address_info_Slice.reducer;