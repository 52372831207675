import React, { useEffect, useState } from "react";
import StepGuideCard from "../../wizard_steps_guide/StepGuide";
import { Card, Col, Row } from "reactstrap";
import SelectConsignerStep from "./steps/create_goods_received_steps/SelectConsignerStep";
import { useDispatch } from "react-redux";
import * as app_consts from "../../../../../../constants/react_app_consts";
import { dashboard_actions_helper } from "../../../../../../actions/dashboard_actions_helper";
import {
  fail_user_accounts_list,
  loading_user_accounts_list,
  reset_user_accounts_list,
  success_user_accounts_list,
} from "../../../../../../reducers/dashboard_reducers/user_accounts/user_accounts_list_Slice";
import {
  RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ERR_MSG,
  RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_SUCCESS_MSG,
  RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import { RETRIEVE_ACTIVE_USER_ACCOUNT_LIST } from "../../../../../../api/dashboard_api_data";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { messageToast, rSelectMapFun } from "../../../../../../utils/Utils";
import { retreive_rSelectVariables } from "../../../../../../utils/functionalUtils";
import TruckDetailsStep from "./steps/create_goods_received_steps/TruckDetailsStep";

const initial_actions_data = {
  create_goods_received_weigh_bridge_ticket: false,
};
const intial_weigh_bridge_form_data = { consigner_type: "", unique_identification_number: "", user_account_label: "", weigh_bridge_ticket_type_id: "" };

const CreateGoodsReceivedWizardIndex = ({ wizardType }) => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const [page_action_data, setPageActionData] = useState([]);
  const [componentData, setComponentData] = useState(initial_actions_data);
  const navigate = useNavigate();
  const [user_list_data, setUserListData] = useState([]);
  const [compan_account_data, setCompanyAccountData] = useState([]);
  const [userListLoading, setUserListLoading] = useState(false);

  const [truck_accounts, setTruckAccounts] = useState([]);
  const [company_accounts, setCompanyAccounts] = useState([]);
  const [customer_accounts, setCustomerAccounts] = useState([]);

  const [weighBridgeFormData, setWeighBridgeFormData] = useState(intial_weigh_bridge_form_data);

  useEffect(() => {
    console.log(weighBridgeFormData);
  }, [weighBridgeFormData]);

  useEffect(() => {
    // Fetch All Active Truck, Customer, and Company Accounts
    fetchActiveTruckCustCompAccs();
  }, []);

  // Check if User has Permission to View this Page

  // Retrieve Module Actions Data
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);
  // UseEffect to Place Page Data
  useEffect(() => {
    if (action_roles.status === app_consts.SUCCESS)
    {
      setPageActionData(action_roles.data);
      if (action_roles.data.warehouse_management)
      {
        if (action_roles.data.warehouse_management.module_actions.create_goods_received_weigh_bridge_ticket)
        {
          setComponentData(action_roles.data.warehouse_management.module_actions);
        } else if (action_roles.data.warehouse_management.module_actions)
        {
          messageToast("Access Denied to Create Goods Received Ticket", app_consts.FAIL);
          navigate(`/dashboard/weigh_bridge_tickets`);
        }
      }
    }
  }, [action_roles]);

  const fetchActiveTruckCustCompAccs = () => {
    dispatch(
      dashboard_actions_helper(
        loading_user_accounts_list,
        success_user_accounts_list,
        fail_user_accounts_list,
        RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ERR_MSG,
        RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_ACTIVE_USER_ACCOUNT_LIST,
        null,
        RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to State of user_account_list
  const user_accounts_list = useSelector((state) => state.user_accounts_list.user_accounts_list);

  useEffect(() => {
    if (user_accounts_list.status !== app_consts.IDLE)
    {
      if (user_accounts_list.status === app_consts.LOADING)
      {
        setUserListLoading(true);
      } else
      {
        setUserListLoading(false);
        if (user_accounts_list.status === app_consts.FAIL)
        {
          messageToast(user_accounts_list.message, app_consts.FAIL);
        } else if (user_accounts_list.status === app_consts.SUCCESS)
        {
          setUserListData(user_accounts_list.data);
        }
        dispatch(reset_user_accounts_list());
      }
    }
  }, [user_accounts_list]);

  const refreshList = (type) => {
    console.log(type);
    if (type === app_consts.REFRESH_USER_ACCOUNTS)
    {
      fetchActiveTruckCustCompAccs();
    }
  };

  // Add the Data to A Map for RSelect
  useEffect(() => {
    if (user_list_data.truck_accounts)
    {
      setTruckAccounts(user_list_data.truck_accounts);
    }
    if (user_list_data.customer_accounts)
    {
      setCustomerAccounts(user_list_data.customer_accounts);
    }
    if (user_list_data.company_accounts)
    {
      setCompanyAccounts(user_list_data.company_accounts);
    }
  }, [user_list_data]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <>
      <Card className="card-bordered">
        <Row className="g-0 col-sep col-sep-md col-sep-xl">
          <StepGuideCard sentStep={step} wizard_type={wizardType} wizard_title="Goods Received Ticket Progress" />
          <Col className="col-md-8 col-xl-8">
            {step === 1 ? (
              <SelectConsignerStep
                refreshList={refreshList}
                list_data={user_list_data}
                loadingList={userListLoading}
                company_accounts_list={company_accounts}
                customer_accounts_list={customer_accounts}
                weighBridgeFormData={weighBridgeFormData}
                setWeighBridgeFormData={setWeighBridgeFormData}
                nextStep={nextStep}
              />
            ) : step === 2 ? (
              <TruckDetailsStep
                refreshList={refreshList}
                list_data={user_list_data}
                loadingList={userListLoading}
                truck_accounts_list={truck_accounts}
                customer_accounts_list={customer_accounts}
                weighBridgeFormData={weighBridgeFormData}
                setWeighBridgeFormData={setWeighBridgeFormData}
                sentStep={step}
                prevStep={prevStep}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CreateGoodsReceivedWizardIndex;
