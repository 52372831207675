import React, { useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import TablePageHeading from "../../components/page_heading/TablePageHeading";
import { useDispatch } from "react-redux";
import * as app_consts from "../../../../constants/react_app_consts";

const type = app_consts.USER_LIST_TYPE;

const EmployeeList = () => {
  const dispatch = useDispatch();
  const [page_data, setPageData] = useState([]);

  // Fetch Employee List

  return (
    <>
      <Head title="Employee List" />
      <Content>
        <TablePageHeading
          title="Employee List"
          description={`Total Employees: ${page_data.length}`}
          refresh
          //   refreshFunc={refreshList}
          csv_export
          //   add_to_list={componentData.onboard_user}
          page_type={type}
          pageData={page_data}
        />
      </Content>
    </>
  );
};

export default EmployeeList;
