import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  create_truck_driver: { status: "idle" },
};

export const create_truck_driver_Slice = createSlice({
  name: "create_truck_driver",
  initialState,
  reducers: {
    success_create_truck_driver: (state, action) => {
      state.create_truck_driver = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_create_truck_driver: (state, action) => {
      state.create_truck_driver = { status: app_consts.FAIL, message: action.payload };
    },
    reset_create_truck_driver: (state) => {
      state.create_truck_driver = { status: app_consts.IDLE };
    },
    loading_create_truck_driver: (state) => {
      state.create_truck_driver = { status: app_consts.LOADING };
    },
  },
});

export const {
  success_create_truck_driver,
  fail_create_truck_driver,
  reset_create_truck_driver,
  loading_create_truck_driver,
} = create_truck_driver_Slice.actions;

export default create_truck_driver_Slice.reducer;
