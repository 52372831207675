import * as api from "../../../api/dashboard_apis/ownership_status/ownership_status_apis";
import { success_sign_out_modal } from "../../../reducers/dashboard_reducers/auth/sign_out_modal_Slice";
import * as app_consts from "../../../constants/react_app_consts";
import { messageToast, rSelectMapFun } from "../../../utils/Utils";
import { fail_ownership_status_list, loading_ownership_status_list, success_ownership_status_list } from "../../../reducers/dashboard_reducers/ownership_status/ownership_status_list_Slice";

// Retrieve LIst of All Marital Statuses
export const retrieve_ownership_statuses_action = (type) => async (dispatch) => {
    setTimeout(
        async function () {
            try
            {
                //Set Loading
                dispatch(loading_ownership_status_list());
                //Making API Request
                const { data } = await api.retrieve_ownership_statuses_api();

                if (data.message === "Onwership Statuses Retrieved Succesfully.")
                {
                    if (type === app_consts.MAP)
                    {
                        // Map Received Data
                        let mappedData = [];

                        await Promise.all(data.data?.map(async (ownership_status) => {
                            const result = await rSelectMapFun(ownership_status.ownership_status, ownership_status.ownership_status_id);
                            mappedData.push(result);
                        }));

                        dispatch(success_ownership_status_list(mappedData));
                    }
                    else
                    {
                        dispatch(success_ownership_status_list(data.data));
                    }
                }
            } catch (error)
            {
                console.log(error);
                if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE")
                {
                    dispatch(fail_ownership_status_list("Could Not Retrieve Ownership Statuses."));
                }
                else
                {
                    if (error.response.status === 400)
                    {
                        dispatch(fail_ownership_status_list(error.response.data.message));
                    }
                    else if (error.response.status === 401)
                    {
                        dispatch(success_sign_out_modal());
                    }
                    else if (error.response.status === 403)
                    {
                        messageToast(`Could Not Retrieve Ownership Statuses. Please Reload the page.`, app_consts.FAIL);
                        dispatch(fail_ownership_status_list("Could Not Retrieve Ownership Statuses. Please Reload the page."));
                    }
                }
            }
        }
    );
};