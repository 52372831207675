import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  product_list: { status: "idle" },
};

export const product_list_Slice = createSlice({
  name: "product_list",
  initialState,
  reducers: {
    success_product_list: (state, action) => {
      state.product_list = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_product_list: (state, action) => {
      state.product_list = { status: app_consts.FAIL, message: action.payload };
    },
    reset_product_list: (state) => {
      state.product_list = { status: app_consts.IDLE };
    },
    loading_product_list: (state) => {
      state.product_list = { status: app_consts.LOADING };
    },
  },
});

export const { success_product_list, fail_product_list, reset_product_list, loading_product_list } =
  product_list_Slice.actions;

export default product_list_Slice.reducer;
