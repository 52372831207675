import React from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import PageHeading from "../components/page_heading/PageHeading";
import WizardComp from "../components/wizard/WizardComp";
import * as app_consts from "../../../constants/react_app_consts";

const CreateGoodsReceived = () => {
  return (
    <>
      <Head title="Create Goods Received Ticket" />
      <Content>
        <PageHeading
          title="Create Goods Received Ticket"
          description="Process The Goods Received at your Designated Warehouse."
          backToText="Weigh Bridge Tickets"
          backToLink="/dashboard/weigh_bridge_tickets"
        />
        {/* Multi-Step Form */}

        <WizardComp type={app_consts.CREATE_GOODS_RECEIVED_TICKET} />
      </Content>
    </>
  );
};

export default CreateGoodsReceived;
