import React from 'react'
import { UserAvatar } from '../../../../components/Component';

const AvatarText = ({ avatarText, avatarLabel, step }) => {
    return (
        <>
            <div className="user-card">
                <UserAvatar
                    text={avatarText < step ? null : avatarText}
                    icon={avatarText < step ? "check-thick" : null}
                    className={`sm ${avatarText < step || avatarText > step ? `not_active ${avatarText < step ? "complete" : "not_complete"}` : null}`}
                ></UserAvatar>
                <div className="user-info">
                    <span className={`form-label ${avatarText < step || avatarText > step ? "text-soft" : null}`}>{avatarLabel}</span>
                </div>
            </div>
        </>
    )
}

export default AvatarText;