import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    auth_routes: { status: "idle" },
}

export const auth_routes_Slice = createSlice({
    name: "auth_routes",
    initialState,
    reducers: {
        success_auth_routes: (state, action) => {
            state.auth_routes = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_auth_routes: (state, action) => {
            state.auth_routes = { status: app_consts.FAIL, message: action.payload };
        },
        reset_auth_routes: (state) => {
            state.auth_routes = { status: app_consts.IDLE }
        },
        loading_auth_routes: (state) => {
            state.auth_routes = { status: app_consts.LOADING }
        },
    }
})

export const { success_auth_routes, fail_auth_routes, reset_auth_routes, loading_auth_routes } = auth_routes_Slice.actions;

export default auth_routes_Slice.reducer;