import React, { useEffect, useState } from "react";
import { CardBody, CardText, CardTitle } from "reactstrap";
import AvatarText from "../../text/AvatarText";
import { wizard_steps_guide } from "./wizard_steps_guide_data";

const StepGuideCard = ({ sentStep, wizard_type, wizard_title }) => {
    const [step, setStep] = useState(sentStep);
    const [showSteps, setShowSteps] = useState([])
    const [title, setTitle] = useState("");

    useEffect(() => {
        setShowSteps(wizard_steps_guide(wizard_type))
    }, [])

    useEffect(() => {
        setShowSteps(wizard_steps_guide(wizard_type))
    }, [wizard_type])

    useEffect(() => {
        setTitle(wizard_title)
    }, [wizard_title])

    useEffect(() => {
        setStep(sentStep);
    }, [sentStep])

    return (
        <div className="col-md-4 col-xl-4">
            <CardBody className="card-inner">
                <CardTitle tag="h5">{title}</CardTitle>
                <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                    {
                        showSteps?.map((showStep) => {
                            return (
                                <CardText key={showStep.index}>
                                    <AvatarText avatarText={showStep.index} step={step} avatarLabel={showStep.step} />
                                </CardText>
                            )
                        })
                    }
                </ul>
            </CardBody>
        </div>
    )
}

export default StepGuideCard;