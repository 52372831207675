import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  retrieve_trucks_trailers_list: { status: "idle" },
};

export const retrieve_trucks_trailers_list_Slice = createSlice({
  name: "retrieve_trucks_trailers_list",
  initialState,
  reducers: {
    success_retrieve_trucks_trailers_list: (state, action) => {
      state.retrieve_trucks_trailers_list = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_retrieve_trucks_trailers_list: (state, action) => {
      state.retrieve_trucks_trailers_list = { status: app_consts.FAIL, message: action.payload };
    },
    reset_retrieve_trucks_trailers_list: (state) => {
      state.retrieve_trucks_trailers_list = { status: app_consts.IDLE };
    },
    loading_retrieve_trucks_trailers_list: (state) => {
      state.retrieve_trucks_trailers_list = { status: app_consts.LOADING };
    },
  },
});

export const {
  success_retrieve_trucks_trailers_list,
  fail_retrieve_trucks_trailers_list,
  reset_retrieve_trucks_trailers_list,
  loading_retrieve_trucks_trailers_list,
} = retrieve_trucks_trailers_list_Slice.actions;

export default retrieve_trucks_trailers_list_Slice.reducer;
