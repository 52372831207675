import React, { useEffect, useState } from "react";
import { DataTableItem, DataTableRow, Icon, TooltipComponent, UserAvatar } from "../../../../../components/Component";
import { Link } from "react-router-dom";
import { findUpper } from "../../../../../utils/Utils";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import * as app_consts from "../../../../../constants/react_app_consts";
import {
  BranchLocationOptionsData,
  ProductOptionsData,
  ProductTypeOptionsData,
  UnitMeasurementOptionsData,
  UserListData,
} from "./table_row_data";

const TableRow = ({
  item,
  rowLink,
  avatar,
  type,
  item_color,
  rowOptData,
  page,
  view_details,
  amendFunc,
  setOpendModalDetails,
  setSelectedData,
  table_type,
  className,
}) => {
  // Set User Role
  const [userId, setUserId] = useState("");
  // UseEffect
  useEffect(() => {
    if (localStorage.getItem("log_cred")) {
      setUserId(JSON.parse(localStorage.getItem("log_cred")).user_id);
    }
  }, []);

  return (
    <>
      {type === "rowMain" && (
        <DataTableRow>
          <Link to={rowLink}>
            <div className="user-card">
              {avatar && (
                <UserAvatar
                  // theme={item.avatarBg}
                  text={findUpper(`${item.first_name} ${item.surname}`)}
                  image={item.profile_img}
                ></UserAvatar>
              )}
              <div className="user-info">
                <span className="tb-lead">
                  {table_type === app_consts.BRANCH_LOCATION_LIST ? (
                    <>
                      {item.branch_area
                        ? item.branch_area.length > 20
                          ? `${item.branch_area.substring(0, 20)}...`
                          : item.branch_area
                        : ""}
                    </>
                  ) : table_type === app_consts.PRODUCT_LIST ? (
                    <>
                      {item.product_name
                        ? item.product_name.length > 20
                          ? `${item.product_name.substring(0, 20)}...`
                          : item.product_name
                        : ""}
                    </>
                  ) : table_type === app_consts.PRODUCT_TYPE_LIST ? (
                    <>
                      {item.product_type
                        ? item.product_type.length > 20
                          ? `${item.product_type.substring(0, 20)}...`
                          : item.product_type
                        : ""}
                    </>
                  ) : table_type === app_consts.UNIT_MEASUREMENT_LIST ? (
                    <>
                      {item.unit_measurement_full_name
                        ? item.unit_measurement_full_name.length > 20
                          ? `${item.unit_measurement_full_name.substring(0, 20)}...`
                          : item.unit_measurement_full_name
                        : ""}
                    </>
                  ) : (
                    <>{`${item.first_name} ${item.surname}`} </>
                  )}
                  <span
                    className={`dot dot-${
                      item.status_id === 1 ? "success" : item.status_id === 2 ? "warning" : "danger"
                    } d-md-none ms-1`}
                  ></span>
                </span>
                {/* <span>{item.email_address}</span> */}
              </div>
            </div>
          </Link>
        </DataTableRow>
      )}
      {type === "rowNormal" && (
        <DataTableRow size="md" className={className ? className : ""}>
          <span>{item}</span>
        </DataTableRow>
      )}
      {type === "rowStatus" && (
        <DataTableRow size="md">
          <span className={`tb-status text-${item_color}`}>{item}</span>
        </DataTableRow>
      )}
      {type === "rowOptions" && (
        <>
          <DataTableRow className="nk-tb-col-tools">
            <ul className="nk-tb-actions gx-1">
              {table_type === app_consts.BRANCH_LOCATION_LIST ? (
                <>
                  <BranchLocationOptionsData
                    item={item}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    rowOptData={rowOptData}
                    amendFunc={amendFunc}
                  />
                </>
              ) : table_type === app_consts.PRODUCT_LIST ? (
                <>
                  <ProductOptionsData
                    item={item}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    rowOptData={rowOptData}
                    amendFunc={amendFunc}
                  />
                </>
              ) : table_type === app_consts.PRODUCT_TYPE_LIST ? (
                <>
                  <ProductTypeOptionsData
                    item={item}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    rowOptData={rowOptData}
                    amendFunc={amendFunc}
                  />
                </>
              ) : table_type === app_consts.UNIT_MEASUREMENT_LIST ? (
                <>
                  <UnitMeasurementOptionsData
                    item={item}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    rowOptData={rowOptData}
                    amendFunc={amendFunc}
                  />
                </>
              ) : (
                <>
                  <UserListData
                    item={item}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    view_details
                    rowOptData={rowOptData}
                    userId={userId}
                    amendFunc={amendFunc}
                  />
                </>
              )}
            </ul>
          </DataTableRow>
        </>
      )}
    </>
  );
};

export default TableRow;
