import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import PageHeading from "../../components/page_heading/PageHeading";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard, Row } from "../../../../components/Component";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../constants/react_app_consts";
import { CardLinkInfo } from "../../components/cards/CardLinks";
import { FaTrailer } from "react-icons/fa";

const VehicleOnboardingIndex = () => {
  const [page_data, setPageData] = useState([]);
  const [componentData, setComponentData] = useState([]);

  // Retrieve Module Actions Data
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);

  // UseEffect to Place Page Data
  useEffect(() => {
    if (action_roles.status === app_consts.SUCCESS) {
      setPageData(action_roles.data);
      if (action_roles.data.onboarding) {
        setComponentData(action_roles.data.onboarding.module_actions);
      }
    }
  }, [action_roles]);

  return (
    <>
      <Head title="Vehicle Onboarding" />
      <Content>
        <PageHeading title="Vehicle Onbaording" description="Onboard Trucks and Trailers." />
        <Block size="lg">
          <PreviewCard>
            <BlockHead>
              <BlockHeadContent className="text-center">
                <BlockTitle tag="h4">Select an Option</BlockTitle>
                <p>Onboard a Truck Or Trailer to the System.</p>
              </BlockHeadContent>
            </BlockHead>
            <Row className="g-gs flex mb-2">
              {page_data && page_data.onboarding && (
                <>
                  {/* Truck Onboarding */}
                  {componentData.onboard_truck && (
                    <CardLinkInfo
                      colSize="4"
                      iconType="in-built"
                      icon="truck"
                      cardTitle="Onboard Truck"
                      cardTextInfo="Onboard a Truck that is either United African Grain owned or Third Party Owned."
                      buttonLink="/dashboard/onboarding/vehicle/truck"
                      buttonText="Proceed"
                      buttonType={app_consts.BUTTON_OPEN_PAGE_TYPE}
                    />
                  )}
                  {/* Trailer Onboarding */}
                  {componentData.onboard_trailer && (
                    <CardLinkInfo
                      colSize="4"
                      iconType="custom"
                      icon={<FaTrailer className="text-white icon" />}
                      cardTitle="Onboard Trailer"
                      cardTextInfo="Onboard a Trailer that is either United African Grain owned or Third Party Owned."
                      buttonLink="/dashboard/onboarding/vehicle/trailer"
                      buttonText="Proceed"
                      buttonType={app_consts.BUTTON_OPEN_PAGE_TYPE}
                    />
                  )}
                </>
              )}
            </Row>
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default VehicleOnboardingIndex;
