import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  create_compnay_account: { status: "idle" },
};

export const create_compnay_account_Slice = createSlice({
  name: "create_compnay_account",
  initialState,
  reducers: {
    success_create_compnay_account: (state, action) => {
      state.create_compnay_account = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_create_compnay_account: (state, action) => {
      state.create_compnay_account = { status: app_consts.FAIL, message: action.payload };
    },
    reset_create_compnay_account: (state) => {
      state.create_compnay_account = { status: app_consts.IDLE };
    },
    loading_create_compnay_account: (state) => {
      state.create_compnay_account = { status: app_consts.LOADING };
    },
  },
});

export const {
  success_create_compnay_account,
  fail_create_compnay_account,
  reset_create_compnay_account,
  loading_create_compnay_account,
} = create_compnay_account_Slice.actions;

export default create_compnay_account_Slice.reducer;
