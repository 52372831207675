import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    create_user: { status: "idle" },
}

export const create_user_Slice = createSlice({
    name: "create_user",
    initialState,
    reducers: {
        success_create_user: (state, action) => {
            state.create_user = { status: app_consts.SUCCESS };
        },
        fail_create_user: (state, action) => {
            state.create_user = { status: app_consts.FAIL, message: action.payload };
        },
        reset_create_user: (state) => {
            state.create_user = { status: app_consts.IDLE }
        },
        loading_create_user: (state) => {
            state.create_user = { status: app_consts.LOADING }
        },
    }
})

export const { success_create_user, fail_create_user, reset_create_user, loading_create_user } = create_user_Slice.actions;

export default create_user_Slice.reducer;