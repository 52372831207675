import React, { useEffect, useState } from "react";
import Head from "../../../../../../layout/head/Head";
import Content from "../../../../../../layout/content/Content";
import { Alert, Spinner } from "reactstrap";
import { BackTo, Button, Col, Icon, Row } from "../../../../../../components/Component";
import TextInput from "../../../../../components/inputs/TextInput";
import {
  fail_create_user,
  loading_create_user,
  reset_create_user,
  success_create_user,
} from "../../../../../../reducers/dashboard_reducers/user/create_user_Slice";
import { useSelector } from "react-redux";
import { EMAIL_REGEX, EMPTYNULLREGEX, NOT_NULL_LETTERS_ONLY_STRING } from "../../../../../../utils/RegexUtils";
import { useNavigate } from "react-router";
import moment from "moment";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import * as app_consts from "../../../../../../constants/react_app_consts";
import ListText from "../../../text/ListText";
import { messageToast } from "../../../../../../utils/Utils";
import { dashboard_actions_helper } from "../../../../../../actions/dashboard_actions_helper";
import {
  CREATE_USERS_ACCESS_DENIED_MSG,
  CREATE_USERS_SUCCESS_MSG,
  CREATE_USER_ERR_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import { CREATE_USER_API } from "../../../../../../api/dashboard_api_data";

const insitialState = {
  first_name: "",
  middle_name: "",
  surname: "",
  phone_number: "",
  email_address: "",
  dob: "",
  iso_code_2: "",
};

const CreateUserModal = ({ successFunction, setModalLoading }) => {
  // Variables
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [errorVal, setError] = useState("");
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  // Get 18 Years from Now
  const subtractAgeLimit = moment().subtract(18, "years");
  const ageLimit = moment(subtractAgeLimit).format("YYYY-MM-DD");
  // Form Data
  const [formData, setFormData] = useState(insitialState);
  // Handle Change Function
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "first_name") {
      setFName(value);
    } else if (e.target.name === "middle_name") {
      setMName(value);
    } else if (e.target.name === "surname") {
      setSName(value);
    } else if (e.target.name === "email_address") {
      setemail_Address(value);
    } else if (e.target.name === "dob") {
      setDob(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  //---------------------------------  Validation Parameters ---------------------------------
  //Checking Valid First Name
  const [fName, setFName] = useState("");
  const [validFName, setValidFname] = useState(false);
  const [inputFNameFocus, setInputFNameFocus] = useState(false);

  //Checking Valid Middle Name
  const [mName, setMName] = useState("");
  const [validMName, setValidMName] = useState(false);
  const [inputMNameFocus, setInputMNameFocus] = useState(false);

  //Checking Valid Last Name
  const [sName, setSName] = useState("");
  const [validSName, setValidSName] = useState(false);
  const [inputSNameFocus, setInputSNameFocus] = useState(false);

  //Checking Valid Username
  const [phone_number, setPhoneNumber] = useState("");
  const [validPNumber, setValidPNumber] = useState(false);
  const [inputPNumberFocus, setInputPNumberFocus] = useState(false);

  //Checking Valid Email
  const [email_Address, setemail_Address] = useState("");
  const [validEmail_Address, setValidEmail_Address] = useState(false);
  const [inputEmail_AddressFocus, setInputEmail_AddressFocus] = useState(false);

  //Checking Date DOB
  const [dobInput, setDob] = useState("");
  const [validDob, setValidDob] = useState(false);

  // -------------------------- UseEffect to Check if Inputs are Valid ----------------------
  // Checking States to see if first name empty or not
  useEffect(() => {
    const result = NOT_NULL_LETTERS_ONLY_STRING.test(fName);
    setValidFname(result);
  }, [fName]);

  //Checking States to see if last name empty or not
  useEffect(() => {
    const result = NOT_NULL_LETTERS_ONLY_STRING.test(mName);
    setValidMName(result);
  }, [mName]);

  //Checking States to see if last name empty or not
  useEffect(() => {
    const result = NOT_NULL_LETTERS_ONLY_STRING.test(sName);
    setValidSName(result);
  }, [sName]);

  //Checking States to see if phone Number empty or not
  useEffect(() => {
    const result = EMPTYNULLREGEX.test(phone_number);
    setValidPNumber(!result);
  }, [phone_number]);

  //Checking States to see if email is valid or not
  useEffect(() => {
    const result = EMAIL_REGEX.test(email_Address);
    setValidEmail_Address(result);
  }, [email_Address]);

  //Checking States to see if dob is valid or not
  useEffect(() => {
    const result = EMPTYNULLREGEX.test(dobInput);
    setValidDob(!result);
  }, [dobInput]);

  const handleSubmit = () => {
    setError("");
    setLoading(true);
    dispatch(
      dashboard_actions_helper(
        loading_create_user,
        success_create_user,
        fail_create_user,
        CREATE_USER_ERR_MSG,
        CREATE_USERS_SUCCESS_MSG,
        app_consts.POST,
        CREATE_USER_API,
        formData,
        CREATE_USERS_ACCESS_DENIED_MSG
      )
    );
  };

  const handlePreview = () => {
    setError("");
    setStep(2);
  };

  // Listen to UseSelector for Create User
  const create_user = useSelector((state) => state.create_user.create_user);
  // UseEffect
  useEffect(() => {
    if (create_user.status !== app_consts.IDLE) {
      if (create_user.status === app_consts.LOADING) {
        setLoading(true);
        setModalLoading(true);
      } else {
        setLoading(false);
        setModalLoading(false);
        if (create_user.status === app_consts.FAIL) {
          setError(create_user.message);
          setStep(1);
        } else if (create_user.status === app_consts.SUCCESS) {
          messageToast(
            `${formData.first_name} ${formData.surname} has been Successfully Onboarded.`,
            app_consts.SUCCESS
          );
          // Close Modal and Other Embedded Functions if Need Be
          successFunction();
        }
        dispatch(reset_create_user());
      }
    }
  }, [create_user]);

  // filter country iso from phone NUmber
  const handlePhoneNumberChange = (country, phone) => {
    setPhoneNumber(country);
    setFormData({ ...formData, ["iso_code_2"]: phone.countryCode.toUpperCase(), ["phone_number"]: country });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h5 className="card-title text-start w-100">User Onboarding Form</h5>
        {step === 2 && (
          <div
            className="nk-block-head-sub mb-2"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setStep(1);
            }}
          >
            <Icon name="arrow-left" />
            <span>back</span>
          </div>
        )}
      </div>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      {step === 1 ? (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Row className="g-4">
              <TextInput
                label="First Name"
                required
                type="text"
                id_val="first_name"
                maxlength="30"
                value={formData.first_name}
                colSize="6"
                name="first_name"
                handleChange={handleChange}
                error_stat={inputFNameFocus && fName && !validFName ? true : false}
                err_Msg={"First Name should only be one name and should not contain any whitespace"}
                onFocus={() => setInputFNameFocus(true)}
              />
              <TextInput
                label="Middle Name (Optional)"
                id_val="middle_name"
                maxlength="30"
                type="text"
                value={formData.middle_name}
                colSize="6"
                name="middle_name"
                handleChange={handleChange}
                error_stat={inputMNameFocus && mName && !validMName ? true : false}
                err_Msg={"Middle Name should only be one name and should not contain any whitespace"}
                onFocus={() => setInputMNameFocus(true)}
              />
              <TextInput
                label="Surname"
                id_val="surname"
                maxlength="30"
                type="text"
                value={formData.surname}
                colSize="6"
                name="surname"
                handleChange={handleChange}
                error_stat={inputSNameFocus && sName && !validSName ? true : false}
                err_Msg={"Surname should only be one name and should not contain any whitespace"}
                onFocus={() => setInputSNameFocus(true)}
              />
              <TextInput
                label="Email Address"
                id_val="email_address"
                maxlength="160"
                type="email"
                value={formData.email_address}
                colSize="6"
                name="email_address"
                handleChange={handleChange}
                error_stat={inputEmail_AddressFocus && email_Address && !validEmail_Address ? true : false}
                err_Msg={"Email Address should only be one name and should not contain any whitespace"}
                onFocus={() => setInputEmail_AddressFocus(true)}
              />
              <Col lg="6">
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="phone_number">
                      Phone Number
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <PhoneInput
                      country={"zm"}
                      value={formData.phone_number}
                      onChange={(country, phone) => handlePhoneNumberChange(country, phone)}
                      copyNumbersOnly={false}
                    />
                  </div>
                </div>
              </Col>
              <TextInput
                label="Date of Birth"
                type="date"
                max={ageLimit}
                value={formData.dob}
                colSize="6"
                name="dob"
                handleChange={handleChange}
              />
              <Col xl="12" className="d-flex justify-content-end">
                <Button
                  onClick={handlePreview}
                  size="lg"
                  type="submit"
                  color="primary"
                  disabled={
                    loading ? loading : !(validFName && validSName && validPNumber && validEmail_Address && validDob)
                  }
                >
                  Preview and Onboard
                </Button>
              </Col>
            </Row>
          </form>
        </>
      ) : step === 2 ? (
        <>
          <ListText dataItem="data-item mySmall" label="First Name" value={formData.first_name} />
          <ListText
            dataItem="data-item mySmall"
            label="Middle Name (Optional)"
            value={formData.middle_name ? formData.middle_name : "--"}
          />
          <ListText dataItem="data-item mySmall" label="Surname" value={formData.surname} />
          <ListText dataItem="data-item mySmall" label="Email Address" value={formData.email_address} />
          <ListText dataItem="data-item mySmall" label="Phone Number" value={formData.phone_number} />
          <ListText dataItem="data-item mySmall" label="Date of Birth" value={formData.dob} />
          <Col xl="12" className="d-flex justify-content-end mt-2 mr-2">
            <Button onClick={handleSubmit} disabled={loading} color="primary">
              {loading ? <Spinner size="sm" color="light" /> : "Onboard"}
            </Button>
          </Col>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default CreateUserModal;
