import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Content from "../../../../layout/content/Content";
import TablePageHeading from "../../components/page_heading/TablePageHeading";
import { Block } from "../../../../components/Component";
import TableList from "../../components/table/TableList";
import * as app_consts from "../../../../constants/react_app_consts";
import { dashboard_actions_helper } from "../../../../actions/dashboard_actions_helper";
import {
  fail_product_type_list,
  loading_product_type_list,
  reset_product_type_list,
  success_product_type_list,
} from "../../../../reducers/dashboard_reducers/products/product_type_list_Slice";
import { AMEND_PRODUCT_TYPES_STATUS, RETRIEVE_PRODUCT_TYPES_LIST } from "../../../../api/dashboard_api_data";
import {
  AMEND_PRODUCT_TYPE_STATUS_ACCESS_DENIED_MSG,
  AMEND_PRODUCT_TYPE_STATUS_ERR_MSG,
  AMEND_PRODUCT_TYPE_STATUS_SUCCESS_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_ERR_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_SUCCESS_MSG,
} from "../../../../constants/success_error_messages_consts";
import { useSelector } from "react-redux";
import { messageToast } from "../../../../utils/Utils";
import ModalDetails from "../../components/modal/modal_details/ModalDetails";
import ModalConfirmCancel from "../../components/modal/modal_confirm_cancel/ModalConfirmCancel";
import {
  fail_amend_product_type_status,
  loading_amend_product_type_status,
  reset_amend_product_type_status,
  success_amend_product_type_status,
} from "../../../../reducers/dashboard_reducers/products/amend_product_type_status_Slice";
import ModalAdd from "../../components/modal/modal_add/ModalAdd";

const initial_actions_data = { add_product_type: false, amend_product_type_status: false };
const type = app_consts.PRODUCT_TYPE_LIST;
const initial_amend_user_status = { product_type_id: "", account_status_id: "" };

const ProductTypeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page_data, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page_action_data, setPageActionData] = useState([]);
  const [componentData, setComponentData] = useState(initial_actions_data);
  const [openModalDetails, setOpendModalDetails] = useState(false);
  const [detail, setDetail] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [acc_status, setAccStatus] = useState("");
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [amendStatusFormData, setAmendStatusFormData] = useState(initial_amend_user_status);
  const [errAmendAccStat, setErrAmendAccStat] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);

  //Retrieve Product List on Page Load
  useEffect(() => {
    retrieve_product_type_list();
  }, []);

  // Retrieve Module Actions Data
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);
  // UseEffect to Place Page Data
  useEffect(() => {
    if (action_roles.status === app_consts.SUCCESS) {
      setPageActionData(action_roles.data);
      if (action_roles.data.system_management) {
        console.log(action_roles.data.system_management.module_actions);
        setComponentData(action_roles.data.system_management.module_actions);
      }
    }
  }, [action_roles]);

  // Retrieve Product Type List
  const retrieve_product_type_list = () => {
    dispatch(
      dashboard_actions_helper(
        loading_product_type_list,
        success_product_type_list,
        fail_product_type_list,
        RETRIEVE_PRODUCT_TYPES_LIST_ERR_MSG,
        RETRIEVE_PRODUCT_TYPES_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_PRODUCT_TYPES_LIST,
        null,
        RETRIEVE_PRODUCT_TYPES_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  //Listen to State of Product Type List
  const product_type_list = useSelector((state) => state.product_type_list.product_type_list);

  useEffect(() => {
    if (product_type_list.status !== app_consts.IDLE) {
      if (product_type_list.status === app_consts.LOADING) {
        setLoading(true);
      } else {
        setLoading(false);
        if (product_type_list.status === app_consts.FAIL) {
          messageToast(product_type_list.message, app_consts.FAIL);
        } else if (product_type_list.status === app_consts.SUCCESS) {
          setPageData(product_type_list.data);
        }
        dispatch(reset_product_type_list());
      }
    }
  }, [product_type_list]);

  // RefreshList Function
  const refreshList = () => {
    retrieve_product_type_list();
    setLoading(true);
    setPageData([]);
  };

  // Function to Amend Product Status
  const amend_Account_Status_modal = (product_type_id, account_status_id, account_status) => {
    setAccStatus(account_status);
    setOpenConfirmCancelModal(true);
    setSelectedData(product_type_id);
    setAmendStatusFormData({
      ...amendStatusFormData,
      ["product_type_id"]: product_type_id,
      ["account_status_id"]: account_status_id,
    });
  };

  // Filter Specific Product Type
  const filterSpecificProductType = (selectedData) => {
    const filtereredProduct = page_data?.filter((data) => data.product_type_id === selectedData);
    return filtereredProduct[0];
  };

  // UseEffect for Specific Data
  useEffect(() => {
    if (selectedData) {
      setDetail(filterSpecificProductType(selectedData));
    }
  }, [selectedData]);

  // Function to Amend Product Type Status
  const amend_Account_Status = () => {
    dispatch(
      dashboard_actions_helper(
        loading_amend_product_type_status,
        success_amend_product_type_status,
        fail_amend_product_type_status,
        AMEND_PRODUCT_TYPE_STATUS_ERR_MSG,
        AMEND_PRODUCT_TYPE_STATUS_SUCCESS_MSG,
        app_consts.POST,
        AMEND_PRODUCT_TYPES_STATUS,
        amendStatusFormData,
        AMEND_PRODUCT_TYPE_STATUS_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Amend Product Type Status Reducer
  const amend_product_type_status = useSelector((state) => state.amend_product_type_status.amend_product_type_status);
  // UseEffect for Amend Product Type Status
  useEffect(() => {
    if (amend_product_type_status.status !== app_consts.IDLE) {
      if (amend_product_type_status.status !== app_consts.LOADING) {
        if (amend_product_type_status.status === app_consts.FAIL) {
          setErrAmendAccStat(amend_product_type_status.message);
        } else if (amend_product_type_status.status === app_consts.SUCCESS) {
          messageToast(
            `${detail && `${detail.product_type}'s `} Status has been Successfully Amended.`,
            app_consts.SUCCESS
          );
          setAccStatus("");
          setDetail([]);
          refreshList();
          setSelectedData("");
          setErrAmendAccStat("");
          setOpenConfirmCancelModal(false);
        }
        dispatch(reset_amend_product_type_status());
      }
    }
  }, [amend_product_type_status]);

  // Open Modal to Add to LIst
  const openAddList = () => {
    setOpenAddModal(true);
  };

  // Success Function for Adding Product Type Via Modal
  const successAddFunc = () => {
    setOpenAddModal(false);
    refreshList();
  };

  return (
    <>
      <Head title="Product Type List" />
      <Content>
        <TablePageHeading
          title="Product Type List"
          description={`Total Product Types: ${page_data.length}`}
          refresh
          refreshFunc={refreshList}
          csv_export
          openAddList={openAddList}
          add_to_list={componentData.add_product_type}
          //   page_type={type}
          pageData={page_data}
        />
        <Block>
          <TableList
            type={type}
            data={page_data}
            rowOptionsData={componentData}
            amendFunc={amend_Account_Status_modal}
            setOpendModalDetails={setOpendModalDetails}
            setSelectedData={setSelectedData}
          />
        </Block>
      </Content>
      {/* Add Modal */}
      <ModalAdd
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        modalTitle="Add Product Type"
        modalDescription="Please Complete this form to Add a Product Type"
        type={type}
        size="md"
        succFunc={successAddFunc}
      />
      {/* Yes or No Modal */}
      <ModalConfirmCancel
        openModal={openConfirmCancelModal}
        setOpenModal={setOpenConfirmCancelModal}
        modalTitle={`${acc_status} ${detail && `${detail.product_type}`}?`}
        modalDescription={`Are you sure that you want to ${acc_status} ${detail.product_type}? All Products under ${detail.product_type} will have their Status changed to the selected Account Status.`}
        buttonConfirm="Confirm"
        buttonCancel="Cancel"
        succFunc={amend_Account_Status}
        errAmend={errAmendAccStat}
        setErrAmend={setErrAmendAccStat}
      />
      {/* Modal For Viewing Product Type Details */}
      <ModalDetails
        modalOpen={openModalDetails}
        setModalOpen={setOpendModalDetails}
        modalTitle={`${detail && `${detail.product_type}'s Details`}`}
        modalType={app_consts.PRODUCT_TYPE_LIST}
        modalData={detail}
      />
    </>
  );
};

export default ProductTypeList;
