import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import PageHeading from "../../components/page_heading/PageHeading";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard, Row } from "../../../../components/Component";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../constants/react_app_consts";
import { CardLinkInfo } from "../../components/cards/CardLinks";
import ModalSearch from "../../components/modal/modal_search/ModalSearch";
import { reset_verify_otp } from "../../../../reducers/dashboard_reducers/otp/verify_otp_Slice";
import { useDispatch } from "react-redux";

const UserOnboardingIndex = () => {
  const dispatch = useDispatch();

  const [page_data, setPageData] = useState([]);
  const [componentData, setComponentData] = useState([]);
  const [modalSearchOpen, setOpenSearchModal] = useState(false);
  const [modalSearchType, setModuleSearchType] = useState("");
  // Retrieve Module Actions Data
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);

  // Reseting Verify OTP Status In Case it Was Left with Status Successful
  useEffect(() => {
    dispatch(reset_verify_otp());
  }, []);

  // UseEffect to Place Page Data
  useEffect(() => {
    if (action_roles.status === app_consts.SUCCESS)
    {
      setPageData(action_roles.data);
      if (action_roles.data.onboarding)
      {
        setComponentData(action_roles.data.onboarding.module_actions);
      }
    }
  }, [action_roles]);

  // Funtion to Open User Search Module
  const open_search_module = (search_module_data) => {
    setModuleSearchType(search_module_data.modal_type);
    setOpenSearchModal(true);
  };

  return (
    <>
      <Head title="User Onboarding" />
      <Content>
        <PageHeading title="User Onbaording" description="Onboard Users and Create User Accounts." />
        <Block size="lg">
          <PreviewCard>
            <BlockHead>
              <BlockHeadContent className="text-center">
                <BlockTitle tag="h4">Select an Option</BlockTitle>
                <p>
                  Create a Member Access Account, Complete KYC Registration or Assign an Account to An Existing User
                </p>
              </BlockHeadContent>
            </BlockHead>
            <Row className="g-gs flex mb-2">
              {page_data && page_data.onboarding && (
                <>
                  {componentData && (
                    <>
                      {/* User Onboarding */}
                      {componentData.onboard_user && (
                        <CardLinkInfo
                          colSize="4"
                          iconType="in-built"
                          icon="user-add"
                          cardTitle="User Onboarding"
                          cardTextInfo="Create a Member Access Account for a Non Registered User."
                          buttonLink="/dashboard/onboarding/user/create_user"
                          buttonText="Proceed"
                          buttonType={app_consts.BUTTON_OPEN_PAGE_TYPE}
                        />
                      )}
                      {/* Assiging User */}
                      {(componentData.assign_customer || componentData.assign_truck_driver) && (
                        <CardLinkInfo
                          colSize="4"
                          iconType="in-built"
                          icon="user-check"
                          cardTitle="Assign Existing Account"
                          cardTextInfo="Create a User Account for an Already Existing User."
                          buttonLink="dashboard/onboarding/user/assign"
                          buttonText="Proceed"
                          comp_data={{ modal_type: app_consts.ASSIGN_USER_ACCOUNTS }}
                          buttonType={app_consts.BUTTON_OPEN_MODAL_TYPE}
                          buttonModuleFunction={open_search_module}
                        />
                      )}
                      {/* Completer KYC Registration User */}
                      {(componentData.assign_customer || componentData.assign_truck_driver) && (
                        <CardLinkInfo
                          colSize="4"
                          iconType="in-built"
                          icon="user-check"
                          cardTitle="Complete KYC Registration"
                          cardTextInfo="Complete KYC Registration for an Already Existing User."
                          buttonLink="/dashboard/onboarding/user/kyc_registration"
                          buttonText="Proceed"
                          comp_data={{ modal_type: app_consts.COMPLETE_KYC_REGISTRATION }}
                          buttonType={app_consts.BUTTON_OPEN_MODAL_TYPE}
                          buttonModuleFunction={open_search_module}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Row>
          </PreviewCard>
        </Block>
      </Content>
      {/* Search Module */}
      <ModalSearch
        modalOpen={modalSearchOpen}
        setModalOpen={setOpenSearchModal}
        modalTitle="Search for Active User to Continue"
        modalType={modalSearchType}
      />
    </>
  );
};

export default UserOnboardingIndex;
