import * as app_consts from "../../../../../constants/react_app_consts";

export const wizard_steps_guide = (type) => {
  let steps_obj = [];
  switch (type)
  {
    case app_consts.CREATE_GOODS_RECEIVED_TICKET:
      steps_obj = [
        {
          index: 1,
          step: "Select Consigner",
        },
        {
          index: 2,
          step: "Truck Details",
        },
        {
          index: 3,
          step: "Product Details",
        },
        {
          index: 4,
          step: "Preview and Save Data",
        },
      ];
      break;
    case app_consts.COMPLETE_KYC_REGISTRATION:
      steps_obj = [
        {
          index: 1,
          step: "Basic Information",
        },
        {
          index: 2,
          step: "Document Information",
        },
        {
          index: 3,
          step: "Address Information",
        },
        {
          index: 4,
          step: "Next of Kin Information",
        },
        {
          index: 5,
          step: "Image Upload",
        },
      ];
      break;
    case app_consts.ASSIGN_CUSTOMER_ACCOUNT:
      steps_obj = [
        {
          index: 1,
          step: "Generate Unique Customer Account ID",
        },
        {
          index: 2,
          step: "Onboard Customer Property(s)",
        },
      ];
      break;
    case app_consts.ONBOARD_COMPANY:
      steps_obj = [
        {
          index: 1,
          step: "Company Onboarding",
        },
        {
          index: 2,
          step: "Onboard Company Representative(s)",
        },
        {
          index: 3,
          step: "Onboard Company Property(s)",
        },
      ];
      break;
  }
  return steps_obj;
};
