import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BlockBetween, BlockHeadContent, Button } from '../../../../../components/Component'

const ModalInfo = ({ modalDescription, modalTitle, openInfoModal, modalInfoCloseFunction, modalButtonText }) => {

    const [openModal, setOpenModal] = useState(openInfoModal);
    const [title, setTitle] = useState();
    const [description, setModaldescription] = useState();
    const [buttonText, setButtonText] = useState();

    const toggleModal = () => {
        modalInfoCloseFunction()
    };

    useEffect(() => {
        setOpenModal(openInfoModal);
    }, [openInfoModal])

    useEffect(() => {
        setTitle(modalTitle);
    }, [modalTitle])

    useEffect(() => {
        setModaldescription(modalDescription);
    }, [modalDescription])

    useEffect(() => {
        setButtonText(modalButtonText);
    }, [modalButtonText])

    return (
        <>
            <Modal isOpen={openModal} toggle={toggleModal} className="modal-dialog-center">
                <ModalHeader
                    toggle={toggleModal}
                >
                    {title}
                </ModalHeader>
                <ModalBody>
                    <p>
                        {description}
                    </p>
                </ModalBody>
                <ModalFooter className="bg-light">
                    <BlockBetween>
                        <BlockHeadContent>
                            <Button onClick={toggleModal} color="danger">
                                {buttonText}
                            </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </ ModalFooter>
            </Modal>
        </>
    )
}

export default ModalInfo