import React, { useEffect, useState } from "react";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  OverlineTitle,
} from "../../../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { export_to_csv_fun } from "../table/table_list_data/table_list_function";
import { ExportToCsv } from "export-to-csv";
import * as app_consts from "../../../../constants/react_app_consts";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const TablePageHeading = ({
  title,
  description,
  refresh,
  refreshFunc,
  csv_export,
  add_to_list,
  page_type,
  pageData,
  openAddList,
  componentData,
}) => {
  const [sm, updateSm] = useState(false);
  const [addToList, setAddToList] = useState(add_to_list);
  const [type, setType] = useState(page_type);
  const [data, setData] = useState(pageData);
  const navigate = useNavigate();
  const [compData, setCompData] = useState(componentData);

  useEffect(() => {
    setAddToList(add_to_list);
  }, [add_to_list]);

  useEffect(() => {
    setData(pageData);
  }, [pageData]);

  useEffect(() => {
    setType(page_type);
  }, [page_type]);

  useEffect(() => {
    console.log(componentData);
    setCompData(componentData);
  }, [componentData]);

  const export_to_csv = async (e) => {
    e.preventDefault();
    const { csv_data, options } = await export_to_csv_fun(data, type);
    // Export CSV
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(await csv_data);
  };

  return (
    <>
      <BlockHead size="sm" className="mt-3">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page tag="h3">
              {title}
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>{description}</p>
            </BlockDes>
            {refresh && (
              <div className="mt-2">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    refreshFunc();
                  }}
                >
                  <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                    <span>Refresh List</span>
                  </Button>
                </Link>
              </div>
            )}
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  {csv_export && (
                    <li>
                      <Button color="light" outline className="btn-white" onClick={export_to_csv}>
                        <Icon name="download-cloud"></Icon>
                        <span>Export to CSV</span>
                      </Button>
                    </li>
                  )}
                  {addToList && (
                    <>
                      {type === app_consts.WEIGH_BRIDGE_TICKET_LIST ? (
                        <>
                          {(compData.create_goods_received_weigh_bridge_ticket ||
                            compData.create_delivery_note_weigh_bridge_ticket) && (
                            <>
                              <li className="preview-item">
                                <UncontrolledDropdown>
                                  <div className="btn-group">
                                    <DropdownToggle className="dropdown-toggle-split" color="primary">
                                      <Icon name="plus"></Icon>
                                    </DropdownToggle>
                                  </div>
                                  <DropdownMenu className="mt-3">
                                    <ul className="link-list-opt">
                                      {compData.create_goods_received_weigh_bridge_ticket && (
                                        <>
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="/dashboard/weigh_bridge_tickets/create_goods_received"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                navigate(`/dashboard/weigh_bridge_tickets/create_goods_received`);
                                              }}
                                            >
                                              <span>Create Goods Received Ticket</span>
                                            </DropdownItem>
                                          </li>
                                        </>
                                      )}
                                      {compData.create_delivery_note_weigh_bridge_ticket && (
                                        <>
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#links"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                navigate(`/dashboard/create_delivery_note_ticket`);
                                              }}
                                            >
                                              <span>Create Delivery Note Ticket</span>
                                            </DropdownItem>
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </>
                          )}
                        </>
                      ) : (
                        <li className="nk-block-tools-opt">
                          <Button onClick={openAddList} color="primary" className="btn-icon">
                            <Icon name="plus"></Icon>
                          </Button>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
    </>
  );
};

export default TablePageHeading;
