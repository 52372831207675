import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  add_product: { status: "idle" },
};

export const add_product_Slice = createSlice({
  name: "add_product",
  initialState,
  reducers: {
    success_add_product: (state, action) => {
      state.add_product = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_add_product: (state, action) => {
      state.add_product = { status: app_consts.FAIL, message: action.payload };
    },
    reset_add_product: (state) => {
      state.add_product = { status: app_consts.IDLE };
    },
    loading_add_product: (state) => {
      state.add_product = { status: app_consts.LOADING };
    },
  },
});

export const { success_add_product, fail_add_product, reset_add_product, loading_add_product } =
  add_product_Slice.actions;

export default add_product_Slice.reducer;
