import axios from "axios";

const API_URL = "http://localhost:4000/api/";

// Create a function for making API requests
export const makeWebsiteApiRequest = (method, endpoint, data = null) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const config = {
    method,
    url: API_URL + endpoint,
    headers,
  };
  if (data) {
    if (method === "get") {
      config.params = data;
    } else {
      config.data = data;
    }
  }

  return axios(config);
};
