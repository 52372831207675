import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import TablePageHeading from "../components/page_heading/TablePageHeading";
import { useSelector } from "react-redux";
import * as app_consts from "../../../constants/react_app_consts";

const initial_actions_data = {
  create_goods_received_weigh_bridge_ticket: false,
  create_delivery_note_weigh_bridge_ticket: false,
};
const type = app_consts.WEIGH_BRIDGE_TICKET_LIST;

const WeighBridgeTicketList = () => {
  const [page_data, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page_action_data, setPageActionData] = useState([]);
  const [componentData, setComponentData] = useState(initial_actions_data);

  // Refresh List Function
  const refreshList = () => {
    // Retrieve Weigh Bridge Tickets list
    //   retrieve_user_list_func();
    //   setLoading(true);
    setPageData([]);
  };

  // Select User Actions Parse Components Accordingly

  // Retrieve Module Actions Data
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);
  // UseEffect to Place Page Data
  useEffect(() => {
    if (action_roles.status === app_consts.SUCCESS) {
      setPageActionData(action_roles.data);
      if (action_roles.data.warehouse_management) {
        setComponentData(action_roles.data.warehouse_management.module_actions);
      }
    }
  }, [action_roles]);

  return (
    <>
      <Head title="Weigh Bridge Tickets" />
      <Content>
        {/* Table Page Heading */}
        <TablePageHeading
          title="Weigh Bridge Tickets List"
          description={`Total Weigh Bridge Tickets: ${page_data.length}`}
          refresh
          refreshFunc={refreshList}
          csv_export
          add_to_list={
            componentData.create_goods_received_weigh_bridge_ticket ||
            componentData.create_delivery_note_weigh_bridge_ticket
          }
          componentData={componentData}
          page_type={type}
          pageData={page_data}
          //   openAddList={openAddListModal}
        />
      </Content>
    </>
  );
};

export default WeighBridgeTicketList;
