import axios from "axios";

const API_URL = "https://uag-api.afrocentec.com/emp_dash_users/"; //http://localhost:4000/api/emp_dash_users/

// Create a function for making API requests
export const makeDashboardApiRequest = (method, endpoint, data = null) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json, multipart/form-data",
  };

  if (localStorage.getItem("access"))
  {
    headers["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem("access")).access_token}`;
  }

  const config = {
    method,
    url: API_URL + endpoint,
    headers,
  };

  if (data)
  {
    if (method === "get")
    {
      config.params = data;
    } else
    {
      config.data = data;
    }
  }

  return axios(config);
};
