import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { BackTo, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../../../components/Component';
import { Button } from 'reactstrap';

const PageHeading = ({ title, title_addition, description, backButton, backButtonLink, backButtonText, backToText, backToLink, headingRightData }) => {
    const [sm, updateSm] = useState(false);

    const [title_addon, setTitleAddOn] = useState("");

    //
    useEffect(() => {
        setTitleAddOn(title_addition)
    }, [title_addition])

    return (
        <>
            <BlockHead size="sm" className="mt-3">
                <BlockBetween>
                    <BlockHeadContent>
                        {
                            backToText && (
                                <BackTo link={backToLink} icon="arrow-left">
                                    {backToText}
                                </BackTo>
                            )
                        }
                        <BlockTitle page tag="h3">
                            {title} <strong className="text-primary small">{title_addon && (title_addon)}</strong>
                        </BlockTitle>
                        <BlockDes className="text-soft">
                            <p>{description}</p>
                        </BlockDes>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <Button
                                className={`btn-icon btn-trigger toggle-expand me-n1  ${sm ? "active" : ""} `}
                                onClick={() => updateSm(!sm)}
                            >
                                <Icon name="more-v" />
                            </Button>
                            <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                <ul className="nk-block-tools g-3">
                                    {
                                        headingRightData && (headingRightData)
                                    }
                                    <li className="nk-block-tools-opt">
                                        {
                                            backButton ?
                                                <>
                                                    <Link to={backButtonLink ? backButtonLink : ""}>
                                                        <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                                                            <Icon name="arrow-left"></Icon>
                                                            <span>{backButtonText ? backButtonText : ""}</span>
                                                        </Button>
                                                        <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                                                            <Icon name="arrow-left"></Icon>
                                                        </Button>
                                                    </Link>
                                                </> : ""
                                        }
                                        {/* <Button color="primary">
                                            <Icon name="reports" />
                                            <span>Reports</span>
                                        </Button> */}

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
        </>
    )
}

export default PageHeading