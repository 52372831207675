import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    marital_status_list: { status: "idle" },
}

export const marital_status_list_Slice = createSlice({
    name: "marital_status_list",
    initialState,
    reducers: {
        success_marital_status_list: (state, action) => {
            state.marital_status_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_marital_status_list: (state, action) => {
            state.marital_status_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_marital_status_list: (state) => {
            state.marital_status_list = { status: app_consts.IDLE }
        },
        loading_marital_status_list: (state) => {
            state.marital_status_list = { status: app_consts.LOADING }
        },
    }
})

export const { success_marital_status_list, fail_marital_status_list, reset_marital_status_list, loading_marital_status_list } = marital_status_list_Slice.actions;

export default marital_status_list_Slice.reducer;