import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    send_otp: { status: "idle" },
}

export const send_otp_Slice = createSlice({
    name: "send_otp",
    initialState,
    reducers: {
        success_send_otp: (state, action) => {
            state.send_otp = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_send_otp: (state, action) => {
            state.send_otp = { status: app_consts.FAIL, message: action.payload };
        },
        reset_send_otp: (state) => {
            state.send_otp = { status: app_consts.IDLE }
        },
        loading_send_otp: (state) => {
            state.send_otp = { status: app_consts.LOADING }
        },
    }
})

export const { success_send_otp, fail_send_otp, reset_send_otp, loading_send_otp } = send_otp_Slice.actions;

export default send_otp_Slice.reducer;