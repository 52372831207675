import * as app_consts from "../constants/react_app_consts";

export const retreive_rSelectVariables = (mapType, data) => {
  let label;
  let value;
  //   User Details Map
  if (mapType === app_consts.USER_MAP)
  {
    label = `${data.first_name} ${data.surname} - ${data.phone_number}`;
    value = data.phone_number;
  }
  // Gender Type Map
  else if (mapType === app_consts.GENDER_MAP)
  {
    label = data.gender_type;
    value = data.gender_type_id;
  }
  // Marital Status map
  else if (mapType === app_consts.MARITAL_STATUS_MAP)
  {
    label = data.marital_status;
    value = data.marital_status_id;
  }
  // User Title Map
  else if (mapType === app_consts.USER_TITLE_MAP)
  {
    label = data.user_title;
    value = data.user_title_id;
  }
  // Provinces Map
  else if (mapType === app_consts.PROVINCES_MAP)
  {
    label = data.province_name;
    value = data.province_id;
  }
  // Product Type Map
  else if (mapType === app_consts.PRODUCT_TYPE_MAP)
  {
    label = data.product_type;
    value = data.product_type_id;
  }
  // Company Representative Roles Map
  else if (mapType === app_consts.COMPANY_REPRESENTATIVE_ROLES_MAP)
  {
    label = data.company_representative_role_name;
    value = data.company_representative_role_id;
  }
  else if (mapType === app_consts.WEIGH_BRIDGE_TICKET_TYPE_MAP)
  {
    label = data.weigh_bride_ticket_type;
    value = data.weigh_bridge_ticket_type_id;
  }
  return { label, value };
};

export const map_system_actions = async (res_emp_api_roles) => {
  let return_actions = {};

  if (res_emp_api_roles)
  {
    // RETURN MODULE ACTION IN A MAP
    const module_actions = res_emp_api_roles.module_actions?.reduce((acc, mod_act) => {
      acc[mod_act.module_action_name] = true;
      return acc;
    }, {});

    return_actions = {
      [res_emp_api_roles.module_name]: true,
      module_actions: module_actions,
    };
  }

  return return_actions;
};

// Split Name and Phone Number From Rselect
export const name_phone_number_splitter = (inputString, type) => {
  let return_string;

  const parts = inputString.split("-");

  if (type === app_consts.FULLNAME_SPLIT)
  {
    // Extract the first two words (name)
    return_string = parts[0].trim();
  } else if (type === app_consts.PHONE_NUMBER_SPLIT)
  {
    // Extract the Phone Number
    return_string = parts[1].trim();
  }
  return return_string;
};
