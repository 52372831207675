import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  add_company_representative: { status: "idle" },
};

export const add_company_representative_Slice = createSlice({
  name: "add_company_representative",
  initialState,
  reducers: {
    success_add_company_representative: (state, action) => {
      state.add_company_representative = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_add_company_representative: (state, action) => {
      state.add_company_representative = { status: app_consts.FAIL, message: action.payload };
    },
    reset_add_company_representative: (state) => {
      state.add_company_representative = { status: app_consts.IDLE };
    },
    loading_add_company_representative: (state) => {
      state.add_company_representative = { status: app_consts.LOADING };
    },
  },
});

export const {
  success_add_company_representative,
  fail_add_company_representative,
  reset_add_company_representative,
  loading_add_company_representative,
} = add_company_representative_Slice.actions;

export default add_company_representative_Slice.reducer;
