import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import VerifyOTP from "../pages/auth/VerifyOTP";
import SendOTP from "../pages/auth/SendOTP";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import { useSelector } from "react-redux";
import * as app_consts from "../constants/react_app_consts";
import { useDispatch } from "react-redux";
import { reset_username_verify } from "../reducers/website_reducers/auth/username_verify_Slice";
import { reset_send_otp_option } from "../reducers/website_reducers/auth/send_otp_option_Slice";


const WebsiteRoutes = () => {
    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    const dispatch = useDispatch()
    const [send_otp, setSendOtp] = useState({ status: false, page: "" })
    const [user_det, setUserDet] = useState([])

    // UseSelector to Listen to Any Changes in The Username Request Check
    const username_check = useSelector((state) => state.username_verify.username_verify)

    useEffect(() => {
        if (username_check.status === app_consts.SUCCESS) {
            setSendOtp({ ...send_otp, ["status"]: true, ["page"]: "send_otp" })
            setUserDet(username_check.data)
            dispatch(reset_username_verify())
        }
    }, [username_check])

    // UseSelector to Listen to Any Changes in The OTP Request Check
    const send_otp_req = useSelector((state) => state.send_otp_option.send_otp_option)

    useEffect(() => {
        if (send_otp_req.status === app_consts.SUCCESS) {
            setSendOtp({ ...send_otp, ["status"]: true, ["page"]: "verify_otp" })
            dispatch(reset_send_otp_option())
        }
    }, [send_otp_req])

    return (
        <Routes>
            <Route path="/" element={<Navigate to={`${process.env.PUBLIC_URL}/auth`} replace />} />

            <Route path="*" element={<Navigate to={`${process.env.PUBLIC_URL}/auth`} replace />} />
            <Route path={`${process.env.PUBLIC_URL}/auth`} element={<LayoutNoSidebar />}>
                <Route index element={<Navigate to="signin" replace />} />
                {/* Check if State for Sending OTP is Present */}
                {
                    send_otp.status &&
                    (
                        send_otp.page === "send_otp" ?
                            <Route exact path="send_otp/:username" element={<SendOTP user_details={user_det} />} />
                            :
                            send_otp.page === "verify_otp" ?
                                <Route exact path="verify_otp/:username" element={<VerifyOTP user_details={user_det} />} />
                                :
                                <>
                                    <Route exact path="send_otp/:username" element={<Navigate to={`${process.env.PUBLIC_URL}/auth`} replace />} />
                                    <Route exact path="verify_otp/:username" element={<Navigate to={`${process.env.PUBLIC_URL}/auth`} replace />} />
                                </>
                    )
                }
                <Route exact path="auth-register" element={<Register />} />
                <Route exact path="signin" element={<Login />} />

                <Route path="errors">
                    <Route exact path="404-modern" element={<Error404Modern />} />
                    <Route exact path="404-classic" element={<Error404Classic />} />
                    <Route exact path="504-modern" element={<Error504Modern />} />
                    <Route exact path="504-classic" element={<Error504Classic />} />
                </Route>
                <Route path="*" element={<Error404Modern />} />
            </Route>
        </Routes>
    );
};
export default WebsiteRoutes;