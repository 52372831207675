import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  product_type_list: { status: "idle" },
};

export const product_type_list_Slice = createSlice({
  name: "product_type_list",
  initialState,
  reducers: {
    success_product_type_list: (state, action) => {
      state.product_type_list = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_product_type_list: (state, action) => {
      state.product_type_list = { status: app_consts.FAIL, message: action.payload };
    },
    reset_product_type_list: (state) => {
      state.product_type_list = { status: app_consts.IDLE };
    },
    loading_product_type_list: (state) => {
      state.product_type_list = { status: app_consts.LOADING };
    },
  },
});

export const { success_product_type_list, fail_product_type_list, reset_product_type_list, loading_product_type_list } =
  product_type_list_Slice.actions;

export default product_type_list_Slice.reducer;
