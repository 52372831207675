import * as app_consts from "../../../../../constants/react_app_consts";

export const user_list_column = (type) => {
  let column = [];
  switch (type) {
    case app_consts.USER_LIST_TYPE:
      column = [
        { key: 0, label: "Username" },
        { key: 1, label: "Phone Number" },
        { key: 2, label: "Email Address" },
        { key: 3, label: "Account Status" },
      ];
      break;
    case app_consts.BRANCH_LOCATION_LIST:
      column = [
        { key: 0, label: "Branch Area" },
        { key: 1, label: "Branch Physical Address" },
        { key: 2, label: "Branch Mobile Number" },
        { key: 4, label: "Province Name" },
        { key: 5, label: "Branch Status" },
      ];
      break;
    case app_consts.PRODUCT_LIST:
      column = [
        { key: 0, label: "Product Name" },
        { key: 1, label: "Product Type" },
        { key: 2, label: "Product Status" },
      ];
      break;
    case app_consts.PRODUCT_TYPE_LIST:
      column = [
        { key: 0, label: "Product Type Name" },
        { key: 1, label: "No. of Products" },
        { key: 2, label: "Product Type Status" },
      ];
      break;
    case app_consts.UNIT_MEASUREMENT_LIST:
      column = [
        { key: 0, label: "Unit Measurement Name" },
        { key: 1, label: "Unit Measurement Abbrev" },
        { key: 2, label: "Unit Measurement Status" },
      ];
      break;
  }
  return column;
};

export const read_Only_Columns = (type) => {
  let column = [];
  switch (type) {
    case app_consts.ONBOARD_USER_PROPERTY:
      column = [
        { key: 0, label: "Property Number" },
        { key: 1, label: "Property Name" },
        { key: 3, label: "Province" },
      ];
      break;
    case app_consts.ONBOARD_COMPANY_REPRESENTATIVE:
      column = [
        { key: 0, label: "Fullname" },
        { key: 1, label: "Phone Number" },
        { key: 3, label: "Role" },
      ];
      break;
  }
  return column;
};
