import React, { useEffect } from "react";
import { Alert, Card, CardTitle, Spinner } from "reactstrap";
import { Button, Col, Icon, Row } from "../../../../../../../../components/Component";
import TextInput from "../../../../../../../components/inputs/TextInput";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  fail_update_nok_kyc_info,
  loading_update_nok_kyc_info,
  reset_update_nok_kyc_info,
  success_update_nok_kyc_info,
} from "../../../../../../../../reducers/dashboard_reducers/user_kyc/update_nok_kyc_info_Slice";
import { messageToast } from "../../../../../../../../utils/Utils";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import {
  UPDATE_NOK_KYC_INFO_ACCESS_DENIED_MSG,
  UPDATE_NOK_KYC_INFO_ERR_MSG,
  UPDATE_NOK_KYC_INFO_SUCCESS_MSG,
} from "../../../../../../../../constants/success_error_messages_consts";
import { UPDATE_NOK_KYC_INFO } from "../../../../../../../../api/dashboard_api_data";
import { dashboard_actions_helper } from "../../../../../../../../actions/dashboard_actions_helper";

const initialState = {
  nok_name: "",
  nok_address: "",
  nok_phone_number: "",
  nok_email_address: "",
  nok_id_number: "",
  nok_relation: "",
};

const NokInfoStep = ({ togglestep, nextStep, data }) => {
  const dispatch = useDispatch();

  const [errorVal, setError] = useState("");
  const [formData, setFormData] = useState(initialState);
  const [step, setStep] = useState("");
  const [user_data, setUserData] = useState([]);
  const [loading, setIsLoading] = useState(false);

  // Reading Data Passed Along
  useEffect(() => {
    if (data) {
      setFormData({ ...formData, ["phone_number"]: data.phone_number });
      setUserData(data);
      setStep(togglestep);
    }
  }, [data, togglestep]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onFormSubmit = () => {
    // Update NOK Info
    dispatch(
      dashboard_actions_helper(
        loading_update_nok_kyc_info,
        success_update_nok_kyc_info,
        fail_update_nok_kyc_info,
        UPDATE_NOK_KYC_INFO_ERR_MSG,
        UPDATE_NOK_KYC_INFO_SUCCESS_MSG,
        app_consts.POST,
        UPDATE_NOK_KYC_INFO,
        formData,
        UPDATE_NOK_KYC_INFO_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to the Update NOK Reducer
  const update_nok_kyc_info = useSelector((state) => state.update_nok_kyc_info.update_nok_kyc_info);
  //
  useEffect(() => {
    if (update_nok_kyc_info.status !== app_consts.IDLE) {
      if (update_nok_kyc_info.status === app_consts.LOADING) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
        if (update_nok_kyc_info.status === app_consts.FAIL) {
          messageToast(update_nok_kyc_info.message, app_consts.FAIL);
          setError(update_nok_kyc_info.message, app_consts.FAIL);
        } else if (update_nok_kyc_info.status === app_consts.SUCCESS) {
          messageToast("User Next of Kin Info Successfully Updated", app_consts.SUCCESS);
          nextStep();
        }
        dispatch(reset_update_nok_kyc_info());
      }
    }
  }, [update_nok_kyc_info]);

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Next of Kin Information</CardTitle>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row className="g-4">
            <TextInput
              label="Next of Kin Full Name"
              type="text"
              maxlength="60"
              id_val="nok_name"
              value={formData.nok_name}
              handleChange={handleChange}
              name="nok_name"
            />
            <TextInput
              label="Next of Kin Address"
              type="text"
              maxlength="100"
              id_val="nok_address"
              value={formData.nok_address}
              handleChange={handleChange}
              name="nok_address"
            />
            <TextInput
              label="Next of Kin Phone Number"
              type="text"
              maxlength="20"
              id_val="nok_phone_number"
              value={formData.nok_phone_number}
              handleChange={handleChange}
              name="nok_phone_number"
            />
            <TextInput
              label={`Next of Kin Email Address (Optional)`}
              type="text"
              maxlength="160"
              id_val="nok_email_address"
              value={formData.nok_email_address}
              handleChange={handleChange}
              name="nok_email_address"
            />
            <TextInput
              label="Next of Kin ID Number"
              type="text"
              maxlength="20"
              id_val="nok_id_number"
              value={formData.nok_id_number}
              handleChange={handleChange}
              name="nok_id_number"
            />
            <TextInput
              label="Next of Kin Relation"
              type="text"
              maxlength="60"
              id_val="nok_relation"
              value={formData.nok_relation}
              handleChange={handleChange}
              name="nok_relation"
            />
            <Col xl="12">
              <div className="form-group">
                <div className="form-control-wrap-flex-wrap justify-end">
                  <Button
                    onClick={onFormSubmit}
                    color="primary"
                    size="lg"
                    disabled={
                      loading
                        ? loading
                        : !(
                            formData.nok_address &&
                            formData.nok_name &&
                            formData.nok_phone_number &&
                            formData.nok_relation &&
                            formData.nok_id_number
                          )
                    }
                  >
                    {loading ? <Spinner size="sm" color="light" /> : "Next"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default NokInfoStep;
