import React from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Block, BlockBetween, BlockHead, BlockHeadContent, Icon } from "../../../components/Component";
import UAGLogo from "../../../images/logo.png";
import { BiErrorCircle } from "react-icons/bi";

const ErrorApp = () => {
  return (
    <React.Fragment>
      <Head title="Setting Up Environment" />
      <Content page="component">
        <Block size="lg">
          <BlockBetween className="center">
            <BlockHead>
              <BlockHeadContent className="mt-3 d-flex flex-column align-items-center justify-content-center">
                <div className="brand-logo d-flex align-items-center justify-content-center">
                  <img className="logo-img logo-img-app_loading" src={UAGLogo} alt="logo-dark" />
                </div>
                <div className="text-soft alert-icon text-center fw-bold fs-18px">
                  <BiErrorCircle className="text-danger fs-3" />
                  <p>Could Not Load United African Grain Dashboard.</p>
                  <p>Please Check Network Connection.</p>
                </div>
              </BlockHeadContent>
            </BlockHead>
          </BlockBetween>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ErrorApp;
