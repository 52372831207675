import DashboardRoutes from "./route/dashboard_routes";
import ThemeProvider from "./layout/provider/Theme";
import WebsiteRoutes from "./route/website_routes";
import { useState } from "react";
import { Provider } from "react-redux";
import { website_store } from "./app/website_store";
import { dashboard_store } from "./app/dashboard_store";
import { ToastContainer } from "react-toastify";

// Declaring Login Status and User Type Variables
let login_status;
let user_type;
//Set Login State before React App fully Initializes
if (localStorage.getItem("access")) {
  // Logs in User if Variables are present
  login_status = true;
  if (localStorage.getItem("log_cred")) {
    user_type = JSON.parse(localStorage.getItem("log_cred")).role_id;
  }
} else {
  // Logs out user if Variables aren't present
  login_status = false;
  user_type = null;
}

const App = () => {
  // State that holds Login Status
  const [isSignIn, setIsSignIn] = useState(login_status);
  return (
    <>
      <ThemeProvider>
        {!isSignIn ? (
          <>
            {/* Website Pages */}
            {/* Website Store */}
            <Provider store={website_store}>
              <WebsiteRoutes />
            </Provider>
          </>
        ) : (
          <>
            {/* Employee Dashboard */}
            {/* Wrapping Around Provider */}
            <Provider store={dashboard_store}>
              <DashboardRoutes />
              <ToastContainer />
            </Provider>
          </>
        )}
      </ThemeProvider >
    </>
  );
};
export default App;