import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import PageHeading from "../../components/page_heading/PageHeading";
import { Alert } from "reactstrap";
import { Icon } from "../../../../components/Component";
import * as app_consts from "../../../../constants/react_app_consts";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { reset_verify_otp } from "../../../../reducers/dashboard_reducers/otp/verify_otp_Slice";
import {
  fail_specific_user_kyc_det,
  loading_specific_user_kyc_det,
  reset_specific_user_kyc_det,
  success_specific_user_kyc_det,
} from "../../../../reducers/dashboard_reducers/user_kyc/specific_user_kyc_det_Slice";
import { messageToast } from "../../../../utils/Utils";
import WizardComp from "../../components/wizard/WizardComp";
import { dashboard_actions_helper } from "../../../../actions/dashboard_actions_helper";
import {
  SPECIFIC_USER_KYC_INFO_ACCESS_DENIED_MSG,
  SPECIFIC_USER_KYC_INFO_ERR_MSG,
  SPECIFIC_USER_KYC_INFO_SUCCESS_MSG,
} from "../../../../constants/success_error_messages_consts";
import { SPECIFIC_USER_KYC_COMP_KYC_REG } from "../../../../api/dashboard_api_data";

const CompleteUserKYC = () => {
  const { phone_number } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errorVal, setError] = useState("");
  const [validPage, setValidPage] = useState({ status: "idle" });
  const [page_loading, setPageLoading] = useState(false);
  const [page_data, setPageData] = useState([]);

  // Check if OTP is Verified To Know if Page Should Load or Not
  const verify_otp_listener = useSelector((state) => state.verify_otp.verify_otp);
  //
  useEffect(() => {
    if (verify_otp_listener.status !== app_consts.IDLE) {
      if (verify_otp_listener.status === app_consts.SUCCESS) {
        setValidPage({ ...validPage, ["status"]: "valid" });
      } else {
        setValidPage({ ...validPage, ["status"]: "invalid" });
      }
      dispatch(reset_verify_otp());
    }
  }, [verify_otp_listener]);

  // Listen to Valid Page Params
  useEffect(() => {
    if (validPage.status !== "idle") {
      if (validPage.status === "valid") {
        // Retrieve User's Information
        dispatch(
          dashboard_actions_helper(
            loading_specific_user_kyc_det,
            success_specific_user_kyc_det,
            fail_specific_user_kyc_det,
            SPECIFIC_USER_KYC_INFO_ERR_MSG,
            SPECIFIC_USER_KYC_INFO_SUCCESS_MSG,
            app_consts.POST,
            SPECIFIC_USER_KYC_COMP_KYC_REG,
            { phone_number: phone_number },
            SPECIFIC_USER_KYC_INFO_ACCESS_DENIED_MSG
          )
        );
      } else if (validPage.status === "invalid") {
        navigate(`/dashboard/onboarding/user`);
      }
    }
  }, [validPage]);

  // Listen to Changes in User KYC Details Reducer
  const specific_user_kyc_det = useSelector((state) => state.specific_user_kyc_det.specific_user_kyc_det);
  //
  useEffect(() => {
    if (specific_user_kyc_det.status !== app_consts.IDLE) {
      if (specific_user_kyc_det.status === app_consts.LOADING) {
        setPageLoading(true);
      } else {
        if (specific_user_kyc_det.status === app_consts.FAIL) {
          messageToast(specific_user_kyc_det.message, app_consts.FAIL);
          navigate(`/dashboard/onboarding/user`);
        } else if (specific_user_kyc_det.status === app_consts.SUCCESS) {
          setPageData(specific_user_kyc_det.data);
        }
      }
    }
  }, [specific_user_kyc_det]);

  return (
    <>
      <Head title="Assign Customer Account" />
      <Content>
        <PageHeading
          title="Complete KYC Registration"
          title_addition={
            page_data.first_name ? `/ ${page_data.first_name} ${page_data.surname} - ${page_data.phone_number}` : ""
          }
          description="Complete User KYC Registration by Completing All the Steps."
          backToText="User Onboarding"
          backToLink={`/dashboard/onboarding/user`}
        />
        {errorVal && (
          <div className="mb-3 w-100">
            <Alert color="danger" className="alert-icon">
              <Icon name="alert-circle" /> {errorVal}{" "}
            </Alert>
          </div>
        )}
        {
          /* Multi-Step Form */
          page_loading && <WizardComp type={app_consts.COMPLETE_KYC_REGISTRATION} />
        }
      </Content>
    </>
  );
};

export default CompleteUserKYC;
