import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    assign_user_account_list: { status: "idle" },
}

export const assign_user_account_list_Slice = createSlice({
    name: "assign_user_account_list",
    initialState,
    reducers: {
        success_assign_user_account_list: (state, action) => {
            state.assign_user_account_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_assign_user_account_list: (state, action) => {
            state.assign_user_account_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_assign_user_account_list: (state) => {
            state.assign_user_account_list = { status: app_consts.IDLE }
        },
        loading_assign_user_account_list: (state) => {
            state.assign_user_account_list = { status: app_consts.LOADING }
        },
    }
})

export const { success_assign_user_account_list, fail_assign_user_account_list, reset_assign_user_account_list, loading_assign_user_account_list } = assign_user_account_list_Slice.actions;

export default assign_user_account_list_Slice.reducer;