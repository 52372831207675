import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  specific_user_details: { status: "idle" },
};

export const specific_user_details_Slice = createSlice({
  name: "specific_user_details",
  initialState,
  reducers: {
    success_specific_user_details: (state, action) => {
      state.specific_user_details = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_specific_user_details: (state, action) => {
      state.specific_user_details = { status: app_consts.FAIL, message: action.payload };
    },
    reset_specific_user_details: (state) => {
      state.specific_user_details = { status: app_consts.IDLE };
    },
    loading_specific_user_details: (state) => {
      state.specific_user_details = { status: app_consts.LOADING };
    },
  },
});

export const {
  success_specific_user_details,
  fail_specific_user_details,
  reset_specific_user_details,
  loading_specific_user_details,
} = specific_user_details_Slice.actions;

export default specific_user_details_Slice.reducer;
