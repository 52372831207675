import moment from "moment";
import LabelText from "../../../text/LabelText";

// User Details
export const UserModalDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data.first_name} label="First Name" />
      {data.middle_name && <LabelText text={data.middle_name} label="Middle Name" />}
      <LabelText text={data.surname} label="Surname" />
      {data.id_number && <LabelText text={data.id_number} label="ID Number" />}
      <LabelText text={data.phone_number} label="Phone Number" />
      <LabelText text={data.email_address} label="Email Address" />
      <LabelText
        text={`${data.branch_physical_address}, ${data.branch_area}, ${data.branch_province_name}, ${data.branch_country_name}`}
        label="Registered Branch Location"
      />
      <LabelText text={moment(data.registration_date).format("MMMM Do, YYYY h:mm:ss")} label="Registered Date" />
      <LabelText
        text={data.account_status}
        label="Account Status"
        badgeStat
        badgeColor={
          data.account_status_id === 1
            ? "success"
            : data.account_status_id === 2
            ? "warning"
            : data.account_status_id === 3
            ? "danger"
            : ""
        }
      />
    </>
  );
};

// Branch Location List
export const BranchLocationModalDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data.branch_area} label="Branch Area" />
      <LabelText text={data.branch_physical_address} label="Branch Physical Address" />
      <LabelText text={data.branch_mobile_number} label="Branch Mobile Number" />
      <LabelText text={data.province_name} label="Province Name" />
      <LabelText
        text={moment(data.branch_registered_date).format("MMMM Do, YYYY h:mm:ss")}
        label="Branch Registered Date"
      />
      <LabelText
        text={data.account_status}
        label="Account Status"
        badgeStat
        badgeColor={
          data.account_status_id === 1
            ? "success"
            : data.account_status_id === 2
            ? "warning"
            : data.account_status_id === 3
            ? "danger"
            : ""
        }
      />
    </>
  );
};

// Product List
export const ProductModalDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data.product_name} label="Product Name" />
      <LabelText text={data.product_type} label="Product Type" />
      <LabelText
        text={data.account_status}
        label="Product Status"
        badgeStat
        badgeColor={
          data.account_status_id === 1
            ? "success"
            : data.account_status_id === 2
            ? "warning"
            : data.account_status_id === 3
            ? "danger"
            : ""
        }
      />
    </>
  );
};

// Product List
export const ProductTypeModalDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data.product_type} label="Product Type" />
      <LabelText text={data.products_count} label="Number of Products" />
      <LabelText
        text={data.account_status}
        label="Product Status"
        badgeStat
        badgeColor={
          data.account_status_id === 1
            ? "success"
            : data.account_status_id === 2
            ? "warning"
            : data.account_status_id === 3
            ? "danger"
            : ""
        }
      />
    </>
  );
};

// Unit Measurement
export const UnitMeasurementDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data.unit_measurement_full_name} label="Unit Measurement" />
      <LabelText text={data.unit_measurement_abbrev} label="Unit Measurement Abbreviation" />
      <LabelText
        text={data.account_status}
        label="Product Status"
        badgeStat
        badgeColor={
          data.account_status_id === 1
            ? "success"
            : data.account_status_id === 2
            ? "warning"
            : data.account_status_id === 3
            ? "danger"
            : ""
        }
      />
    </>
  );
};

// User Property
export const UserAccountPropertyDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data.property_number} label="Property Number" />
      <LabelText text={data.property_name} label="Property Name" />
      <LabelText text={data.property_physical_address} label="Physical Address" />
      <LabelText text={data.province_name} label="Province" />
      <LabelText text={data.district_name} label="District" />
    </>
  );
};

// Company Representative
export const CompanyRepresentativeDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data.first_name} label="First Name" />
      <LabelText text={data.surname} label="Surname" />
      <LabelText text={data.email_address} label="Email Address" />
      <LabelText text={data.phone_number} label="Phone Number" />
      <LabelText text={data.company_representative_role_name} label="Company Representative Role " />
    </>
  );
};
