import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Icon, TooltipComponent } from "../../../../../components/Component";
import * as app_consts from "../../../../../constants/react_app_consts";

// Branch Locations Options Data
export const BranchLocationOptionsData = ({ item, setSelectedData, setOpendModalDetails, rowOptData, amendFunc }) => {
  return (
    <>
      <li
        className="nk-tb-action-hidden"
        onClick={() => {
          setOpendModalDetails(true);
          setSelectedData(item.branch_location_id);
        }}
      >
        <TooltipComponent
          tag="a"
          containerClassName="btn btn-trigger btn-icon"
          id={"edit" + item.branch_location_id}
          icon="eye-fill"
          direction="top"
          text="Quick View"
        />
      </li>
      <li>
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu end>
            <ul className="link-list-opt no-bdr">
              <li>
                <DropdownItem
                  tag="a"
                  href="#edit"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setOpendModalDetails(true);
                    setSelectedData(item.branch_location_id);
                  }}
                >
                  <Icon name="eye-fill"></Icon>
                  <span>Quick View</span>
                </DropdownItem>
              </li>
              {rowOptData.amend_branch_location_status && (
                <>
                  <li className="divider"></li>
                  {item.account_status_id !== 1 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(
                              item.branch_location_id,
                              app_consts.ACTIVATE_ACCOUNT_ID,
                              app_consts.ACTIVATE_ACCOUNT
                            );
                          }}
                        >
                          <Icon color="" name="check-round-fill"></Icon>
                          <span>Activate Branch Location</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                  {item.account_status_id !== 2 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(
                              item.branch_location_id,
                              app_consts.SUSPEND_ACCOUNT_ID,
                              app_consts.SUSPEND_ACCOUNT
                            );
                          }}
                        >
                          <Icon name="pause-circle"></Icon>
                          <span>Suspend Branch Location</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                  {item.account_status_id !== 3 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(
                              item.branch_location_id,
                              app_consts.DEACTIVATE_ACCOUNT_ID,
                              app_consts.DEACTIVATE_ACCOUNT
                            );
                          }}
                        >
                          <Icon name="cross-c"></Icon>
                          <span>Deactivate Branch Location</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
    </>
  );
};

// Product Options Data
export const ProductOptionsData = ({ item, setSelectedData, setOpendModalDetails, rowOptData, amendFunc }) => {
  return (
    <>
      <li
        className="nk-tb-action-hidden"
        onClick={() => {
          setOpendModalDetails(true);
          setSelectedData(item.product_id);
        }}
      >
        <TooltipComponent
          tag="a"
          containerClassName="btn btn-trigger btn-icon"
          id={"edit" + item.product_id}
          icon="eye-fill"
          direction="top"
          text="Quick View"
        />
      </li>
      <li>
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu end>
            <ul className="link-list-opt no-bdr">
              <li>
                <DropdownItem
                  tag="a"
                  href="#edit"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setOpendModalDetails(true);
                    setSelectedData(item.product_id);
                  }}
                >
                  <Icon name="eye-fill"></Icon>
                  <span>Quick View</span>
                </DropdownItem>
              </li>
              {rowOptData.amend_product_Status && (
                <>
                  <li className="divider"></li>
                  {item.account_status_id !== 1 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(item.product_id, app_consts.ACTIVATE_ACCOUNT_ID, app_consts.ACTIVATE_ACCOUNT);
                          }}
                        >
                          <Icon color="" name="check-round-fill"></Icon>
                          <span>Activate Product</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                  {item.account_status_id !== 2 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(item.product_id, app_consts.SUSPEND_ACCOUNT_ID, app_consts.SUSPEND_ACCOUNT);
                          }}
                        >
                          <Icon name="pause-circle"></Icon>
                          <span>Suspend Product</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                  {item.account_status_id !== 3 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(item.product_id, app_consts.DEACTIVATE_ACCOUNT_ID, app_consts.DEACTIVATE_ACCOUNT);
                          }}
                        >
                          <Icon name="cross-c"></Icon>
                          <span>Deactivate Product</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
    </>
  );
};

// Product Type Options Data
export const ProductTypeOptionsData = ({ item, setSelectedData, setOpendModalDetails, rowOptData, amendFunc }) => {
  return (
    <>
      <li
        className="nk-tb-action-hidden"
        onClick={() => {
          setOpendModalDetails(true);
          setSelectedData(item.product_type_id);
        }}
      >
        <TooltipComponent
          tag="a"
          containerClassName="btn btn-trigger btn-icon"
          id={"edit" + item.product_type_id}
          icon="eye-fill"
          direction="top"
          text="Quick View"
        />
      </li>
      <li>
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu end>
            <ul className="link-list-opt no-bdr">
              <li>
                <DropdownItem
                  tag="a"
                  href="#edit"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setOpendModalDetails(true);
                    setSelectedData(item.product_type_id);
                  }}
                >
                  <Icon name="eye-fill"></Icon>
                  <span>Quick View</span>
                </DropdownItem>
              </li>
              {rowOptData.amend_product_type_status && (
                <>
                  <li className="divider"></li>
                  {item.account_status_id !== 1 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(
                              item.product_type_id,
                              app_consts.ACTIVATE_ACCOUNT_ID,
                              app_consts.ACTIVATE_ACCOUNT
                            );
                          }}
                        >
                          <Icon color="" name="check-round-fill"></Icon>
                          <span>Activate Product</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                  {item.account_status_id !== 2 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(item.product_type_id, app_consts.SUSPEND_ACCOUNT_ID, app_consts.SUSPEND_ACCOUNT);
                          }}
                        >
                          <Icon name="pause-circle"></Icon>
                          <span>Suspend Product</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                  {item.account_status_id !== 3 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(
                              item.product_type_id,
                              app_consts.DEACTIVATE_ACCOUNT_ID,
                              app_consts.DEACTIVATE_ACCOUNT
                            );
                          }}
                        >
                          <Icon name="cross-c"></Icon>
                          <span>Deactivate Product</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
    </>
  );
};

// Unit Measurements Options Data
export const UnitMeasurementOptionsData = ({ item, setSelectedData, setOpendModalDetails, rowOptData, amendFunc }) => {
  return (
    <>
      <li
        className="nk-tb-action-hidden"
        onClick={() => {
          setOpendModalDetails(true);
          setSelectedData(item.unit_measurement_id);
        }}
      >
        <TooltipComponent
          tag="a"
          containerClassName="btn btn-trigger btn-icon"
          id={"edit" + item.unit_measurement_id}
          icon="eye-fill"
          direction="top"
          text="Quick View"
        />
      </li>
      <li>
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu end>
            <ul className="link-list-opt no-bdr">
              <li>
                <DropdownItem
                  tag="a"
                  href="#edit"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setOpendModalDetails(true);
                    setSelectedData(item.unit_measurement_id);
                  }}
                >
                  <Icon name="eye-fill"></Icon>
                  <span>Quick View</span>
                </DropdownItem>
              </li>
              {rowOptData.amend_unit_measurement_status && (
                <>
                  <li className="divider"></li>
                  {item.account_status_id !== 1 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(
                              item.unit_measurement_id,
                              app_consts.ACTIVATE_ACCOUNT_ID,
                              app_consts.ACTIVATE_ACCOUNT
                            );
                          }}
                        >
                          <Icon color="" name="check-round-fill"></Icon>
                          <span>Activate Unit Measurement</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                  {item.account_status_id !== 2 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(
                              item.unit_measurement_id,
                              app_consts.SUSPEND_ACCOUNT_ID,
                              app_consts.SUSPEND_ACCOUNT
                            );
                          }}
                        >
                          <Icon name="pause-circle"></Icon>
                          <span>Suspend Unit Measurement</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                  {item.account_status_id !== 3 && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(
                              item.unit_measurement_id,
                              app_consts.DEACTIVATE_ACCOUNT_ID,
                              app_consts.DEACTIVATE_ACCOUNT
                            );
                          }}
                        >
                          <Icon name="cross-c"></Icon>
                          <span>Deactivate Unit Measurement</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
    </>
  );
};

// User List Data
export const UserListData = ({
  item,
  setSelectedData,
  setOpendModalDetails,
  userId,
  rowOptData,
  view_details,
  amendFunc,
}) => {
  return (
    <>
      <li
        className="nk-tb-action-hidden"
        onClick={() => {
          setOpendModalDetails(true);
          setSelectedData(item.member_access_id);
        }}
      >
        <TooltipComponent
          tag="a"
          containerClassName="btn btn-trigger btn-icon"
          id={"edit" + item.id}
          icon="eye-fill"
          direction="top"
          text="Quick View"
        />
      </li>
      <li>
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu end>
            <ul className="link-list-opt no-bdr">
              <li>
                <DropdownItem
                  tag="a"
                  href="#edit"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setOpendModalDetails(true);
                    setSelectedData(item.member_access_id);
                  }}
                >
                  <Icon name="eye-fill"></Icon>
                  <span>Quick View</span>
                </DropdownItem>
              </li>
              {view_details && (
                <li>
                  <DropdownItem
                    tag="a"
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="focus"></Icon>
                    <span>View Details</span>
                  </DropdownItem>
                </li>
              )}
              {rowOptData.amend_user_Status && (
                <>
                  <li className="divider"></li>
                  {item.account_status_id !== 1 && userId !== item.member_access_id && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(
                              item.member_access_id,
                              app_consts.ACTIVATE_ACCOUNT_ID,
                              app_consts.ACTIVATE_ACCOUNT
                            );
                          }}
                        >
                          <Icon name="check-round-fill"></Icon>
                          <span>Activate Account</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                  {item.account_status_id !== 2 && userId !== item.member_access_id && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(item.member_access_id, app_consts.SUSPEND_ACCOUNT_ID, app_consts.SUSPEND_ACCOUNT);
                          }}
                        >
                          <Icon name="pause-circle"></Icon>
                          <span>Suspend Account</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                  {item.account_status_id !== 3 && userId !== item.member_access_id && (
                    <>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            amendFunc(
                              item.member_access_id,
                              app_consts.DEACTIVATE_ACCOUNT_ID,
                              app_consts.DEACTIVATE_ACCOUNT
                            );
                          }}
                        >
                          <Icon name="cross-c"></Icon>
                          <span>Deactivate Account</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
    </>
  );
};
