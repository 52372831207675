import React, { useEffect, useState } from 'react'
import Head from '../../../../layout/head/Head';
import { Block, Button, Col, Icon, PreviewCard, RSelect, Row } from '../../../../components/Component';
import Content from '../../../../layout/content/Content';
import PageHeading from '../../components/page_heading/PageHeading';
import { Alert, Spinner } from 'reactstrap';
import TextInput from '../../../components/inputs/TextInput';
import { useDispatch } from 'react-redux';
import { retrieve_ownership_statuses_action } from '../../../../actions/dashboard_actions/ownership_status/ownership_status_actions';
import { reset_ownership_status_list } from '../../../../reducers/dashboard_reducers/ownership_status/ownership_status_list_Slice';
import { messageToast } from '../../../../utils/Utils';
import { useSelector } from 'react-redux';
import * as app_consts from "../../../../constants/react_app_consts";
import { EMPTYNULLREGEX, NO_WHITESPACE_LETTERS_NUMBERS_ONLY_REGEX } from '../../../../utils/RegexUtils';
import ModalPreview from '../../components/modal/modal_preview/ModalPreview';
import { add_truck_action } from '../../../../actions/dashboard_actions/truck/truck_actions';
import { reset_create_truck } from '../../../../reducers/dashboard_reducers/truck/create_truck_Slice';
import { useNavigate } from 'react-router';

const initialState = {truck_license_plate: "", ownership_status_id: ""}

const TruckOnboarding = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [errorVal, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [onwershipStatus, setOwnershipStatus] = useState();
    const [formData, setFormData] = useState(initialState);
    const [selectedOwnershipStatus, setSelectedOwnershipStatus] = useState("");
    const [openPreview, setOpenPreview] = useState();


    // Load Ownership Statuses On Page Load
    useEffect(() => {
        dispatch(retrieve_ownership_statuses_action(app_consts.MAP));

    }, [])

    // Listen to Changes in Ownership Statuses List Retrieval
    const ownership_status_list = useSelector((state) => state.ownership_status_list.ownership_status_list);
    // 
    useEffect(() => {
        if (ownership_status_list.status !== app_consts.IDLE && ownership_status_list.status !== app_consts.LOADING) {
            if (ownership_status_list.status === app_consts.FAIL) {
                messageToast(ownership_status_list.message, app_consts.FAIL)
            }
            else if (ownership_status_list.status === app_consts.SUCCESS) {
                setOwnershipStatus(ownership_status_list.data)
            }
            dispatch(reset_ownership_status_list());
        }
    }, [ownership_status_list]);

    const handlePreview = () => {
        setOpenPreview(true)
    }

    const handlePlateChange = (e) => {
        const value = e.target.value
        if (value.length === 0 )
        {
                setFormData({ ...formData, ["truck_license_plate"]: "" })
        }
        else
        {
            const empt_null = NO_WHITESPACE_LETTERS_NUMBERS_ONLY_REGEX.test(value);
            if (empt_null) 
            {
                const resultString = value.replace(/\s+/g, '').toUpperCase();
                setFormData({ ...formData, ["truck_license_plate"]: resultString })
            }
            else
            {
                messageToast('Whitespace and Special Characters are Not Allowed!', app_consts.FAIL);
            }
        }
    } 

    const handleSubmit = () => {
        setError("")
        dispatch(add_truck_action(formData))
        setOpenPreview(false)
    }

    // Listen to UseSelector for Create User
    const create_truck = useSelector((state) => state.create_truck.create_truck)
    // UseEffect
    useEffect(() => {
        if (create_truck.status !== app_consts.IDLE) {
            if (create_truck.status === app_consts.LOADING) {
                setLoading(true)
            }
            else {
                setLoading(false)
                if (create_truck.status === app_consts.FAIL) {
                    setError(create_truck.message)
                }
                else if (create_truck.status === app_consts.SUCCESS) {
                    messageToast(`${formData.truck_license_plate} has been Successfully Onboarded.`, app_consts.SUCCESS)
                    navigate(`/dashboard/onboarding/vehicle`);
                }
                dispatch(reset_create_truck())
            }
        }
    }, [create_truck])
    

    return (
        <>
            <Head title='Onboard Truck' />
            <Content>
                <div className="w-90 m-auto">
                <PageHeading title="Onboard Truck" description="Onboard Truck and Assign Ownership Status." backToText="Vehicle Onboarding" backToLink="/dashboard/onboarding/vehicle" />
                    <Block size="lg">
                        <PreviewCard>
                            <div className="card-head">
                                <h5 className="card-title text-start w-100">Truck Onboarding Form</h5>
                                {errorVal && (
                                    <div className="mb-3 w-100">
                                        <Alert color="danger" className="alert-icon">
                                            <Icon name="alert-circle" /> {errorVal}{" "}
                                        </Alert>
                                    </div>
                                )}
                                <form className='w-100' onSubmit={e => { e.preventDefault(); }}>
                                    <Row className="g-4">
                                        <TextInput
                                            label="Truck License Plate Number"
                                            required
                                            type="text"
                                            id_val="truck_license_plate"
                                            maxlength="10"
                                            value={formData.truck_license_plate}
                                            colSize="6"
                                            name="first_name"
                                            handleChange={handlePlateChange}
                                        />
                                        <Col lg="6" className="mb-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="ownership_status_id">
                                                    Truck Ownership Status
                                                </label>
                                                <div className="form-control-wrap">
                                                    <RSelect
                                                        options={onwershipStatus}
                                                        className="w-100%"
                                                        id="ownership_status_id"
                                                        onChange={(e) => { setFormData({ ...formData, ["ownership_status_id"]: e.value  }); setSelectedOwnershipStatus(e.label) }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl="12" className="d-flex justify-content-end">
                                            <Button onClick={handlePreview} size="lg" type="submit" color="primary" disabled={loading ? loading : !(formData.truck_license_plate && formData.ownership_status_id)}>
                                                {loading ? <Spinner size="sm" color="light" /> : "Preview and Onboard"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </PreviewCard>
                    </Block>
                </div>
            </Content>
            {/* Modal For Previewing Before Onboarding */}
            <ModalPreview openPreview={openPreview} setOpenPreview={setOpenPreview} modalTitle={`Onboard Truck Preview`} modalDescription={`Please Confirm Details and Select 'Onboard Truck' to Onboard ${formData.truck_license_plate}.`} moduleButtonText="Onboard Truck" prevType={app_consts.PREVIEW_ONBOARD_TRUCK} prevData={{ownership_status: selectedOwnershipStatus, truck_license_plate: formData.truck_license_plate}} buttonFunction={handleSubmit} />
        </>
    )
}

export default TruckOnboarding