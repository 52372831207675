import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Spinner } from "reactstrap";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../../../components/Component";
import RadioInput from "../../../../../input/RadioInput";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dashboard_actions_helper } from "../../../../../../../../actions/dashboard_actions_helper";
import { fail_weigh_bridge_ticket_type_list, loading_weigh_bridge_ticket_type_list, reset_weigh_bridge_ticket_type_list, success_weigh_bridge_ticket_type_list } from "../../../../../../../../reducers/dashboard_reducers/warehuose/weigh_bridge_ticket_type_list_Slice";
import { RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_LIST } from "../../../../../../../../api/dashboard_api_data";
import { RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_ACCESS_DENIED_MSG, RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_ERR_MSG, RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_SUCCESS_MSG } from "../../../../../../../../constants/success_error_messages_consts";
import { useSelector } from "react-redux";

const SelectConsignerStep = ({
  refreshList,
  list_data,
  loadingList,
  company_accounts_list,
  customer_accounts_list,
  weighBridgeFormData,
  setWeighBridgeFormData,
  nextStep,
}) => {

  const dispatch = useDispatch();
  const [errorVal, setErrorVal] = useState("");
  const [loading, setLoading] = useState();
  const [consignerType, setConsignerType] = useState("");
  const [user_list_data, setUserListData] = useState([]);
  const [listLoading, setListLoading] = useState();
  const [company_accounts, setCompanyAccounts] = useState([]);
  const [customer_accounts, setCustomerAccounts] = useState([]);
  const [weigh_bridge_ticket_type_list_data, setwighBridgeTicketTypeListData] = useState([]);
  const [formData, setFormData] = useState(weighBridgeFormData);

  useEffect(() => {
    dispatch(dashboard_actions_helper(
      loading_weigh_bridge_ticket_type_list,
      success_weigh_bridge_ticket_type_list,
      fail_weigh_bridge_ticket_type_list,
      RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_ERR_MSG,
      RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_SUCCESS_MSG,
      app_consts.GET,
      RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_LIST,
      null,
      RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_ACCESS_DENIED_MSG,
      app_consts.MAP,
      app_consts.WEIGH_BRIDGE_TICKET_TYPE_MAP
    ));
  }, []);

  // Listen to State of Weigh Bridge Ticket Type
  const weigh_bridge_ticket_type_listener = useSelector((state) => state.weigh_bridge_ticket_type_list.weigh_bridge_ticket_type_list);

  useEffect(() => {
    if (weigh_bridge_ticket_type_listener.status !== app_consts.IDLE)
    {
      if (weigh_bridge_ticket_type_listener.status === app_consts.LOADING)
      {
      } else
      {
        if (weigh_bridge_ticket_type_listener.status === app_consts.SUCCESS)
        {
          setwighBridgeTicketTypeListData(weigh_bridge_ticket_type_listener.data);
        }
        dispatch(reset_weigh_bridge_ticket_type_list());
      }
    }
  }, [weigh_bridge_ticket_type_listener]);

  useEffect(() => {
    setFormData(weighBridgeFormData);
  }, [weighBridgeFormData]);

  const consigner_type_selection = (typeSelection) => {
    setWeighBridgeFormData({
      ...weighBridgeFormData,
      ["consigner_type"]: typeSelection,
      ["unique_identification_number"]: "",
      ["user_account_label"]: "",
    });
    setConsignerType(typeSelection);
  };

  useEffect(() => {
    setUserListData(list_data);
  }, [list_data]);

  useEffect(() => {
    setListLoading(loadingList);
  }, [loadingList]);

  useEffect(() => {
    const mappedList = company_accounts_list?.map((comp_acc) => {
      return {
        value: comp_acc.unique_identification_number,
        label: `${comp_acc.unique_identification_number} - ${comp_acc.company_name}`,
      };
    });
    setCompanyAccounts(mappedList);
  }, [company_accounts_list]);

  useEffect(() => {
    const mappedList = customer_accounts_list?.map((cust_acc) => {
      return {
        value: cust_acc.unique_identification_number,
        label: `${cust_acc.unique_identification_number} - ${cust_acc.first_name} ${cust_acc.surname}`,
      };
    });
    setCustomerAccounts(mappedList);
  }, [customer_accounts_list]);

  // Handle Selection Check
  const handleUserAccountSelection = (value, label) => {
    setWeighBridgeFormData({
      ...weighBridgeFormData,
      ["unique_identification_number"]: value,
      ["user_account_label"]: label,
    });
  };

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Select Good Received Consigner</CardTitle>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <div className="g-gs">
              <Col className="p-4">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Consigner Type
                  </label>
                  <div className="w-80 form-control-wrap d-flex flex-row">
                    <RadioInput
                      id={app_consts.UAG_CONSIGNER_TYPE}
                      label="UAG"
                      className="custom-control custom-control-md custom-radio"
                      handleChange={consigner_type_selection}
                    />
                    <RadioInput
                      id={app_consts.COMPANY_CONSIGNER_TYPE}
                      label="Company"
                      className="custom-control custom-control-md custom-radio"
                      handleChange={consigner_type_selection}
                    />
                    <RadioInput
                      id={app_consts.CUSTOMER_CONSIGNER_TYPE}
                      label="Customer"
                      className="custom-control custom-control-md custom-radio"
                      handleChange={consigner_type_selection}
                    />
                  </div>
                </div>
              </Col>
              <div className="w-55">
                <label className="form-label mb-1 text-md" htmlFor="weigh_bridge_ticket_type_id">
                  Ticket Type
                </label>
                <RSelect
                  options={weigh_bridge_ticket_type_list_data}
                  className="w-100"
                  placeholder="Search"
                  id="weigh_bridge_ticket_type_id"
                  onChange={(e) => setWeighBridgeFormData({ ...weighBridgeFormData, ["weigh_bridge_ticket_type_id"]: e.value })}
                  // onInputChange={(e) => onActionText(e)}
                  isDisabled={listLoading}
                />
              </div>
            </div>
            {(consignerType === 2 || consignerType === 3) && (
              <>
                <div className="mt-2 mb-2">
                  <hr />
                </div>
                <Col>
                  <div className="form-group">
                    <label className="form-label mb-1 text-md" htmlFor="username">
                      {`Search for ${consignerType === 2 ? ` Company ` : consignerType === 3 ? `Customer ` : ""} by ${consignerType === 2 ? `Customer ` : consignerType === 5 ? `Company ` : ""
                        } Account Number or Name`}
                    </label>
                    {consignerType === 2 && (
                      <div className="form-control-wrap align-items-center w-65 d-flex flex-row mb-2 justify-between">
                        <div className="w-80">
                          <RSelect
                            options={company_accounts}
                            className="w-100"
                            placeholder="Search"
                            id="username"
                            onChange={(e) => setFormData({ ...weighBridgeFormData, ["weigh_bridge_ticket_type_id"]: e.value })}
                            // onInputChange={(e) => onActionText(e)}
                            isDisabled={listLoading}
                          />
                        </div>
                        <div className="w-15">
                          {/* // Waiting for List to Load */}
                          {listLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            <Link
                              to="#reload"
                              onClick={(e) => {
                                e.preventDefault();
                                refreshList(app_consts.REFRESH_USER_ACCOUNTS);
                              }}
                            >
                              <Icon name="reload-alt btn-icon fs-22px"></Icon>
                            </Link>
                          )}
                          {/*  } */}
                        </div>
                      </div>
                    )}
                    {consignerType === 3 && (
                      <div className="form-control-wrap align-items-center w-65 d-flex flex-row mb-2 justify-between">
                        <div className="w-80">
                          <RSelect
                            options={customer_accounts}
                            className="w-100"
                            placeholder="Search"
                            id="username"
                            onChange={(e) => handleUserAccountSelection(e.value, e.label)}
                            // onInputChange={(e) => onActionText(e)}
                            isDisabled={listLoading}
                          />
                        </div>
                        <div className="w-15">
                          {/* // Waiting for List to Load */}
                          {listLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            <Link
                              to="#reload"
                              onClick={(e) => {
                                e.preventDefault();
                                refreshList(app_consts.REFRESH_USER_ACCOUNTS);
                              }}
                            >
                              <Icon name="reload-alt btn-icon fs-22px"></Icon>
                            </Link>
                          )}
                          {/*  } */}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </>
            )}
            <Col xl="12" className="mb-3 mt-5">
              <div className="form-group">
                <div className="form-control-wrap-flex-wrap justify-start">
                  <Button
                    onClick={nextStep}
                    color="primary"
                    size="lg"
                    disabled={
                      consignerType === 1
                        ? !formData.consigner_type
                        : !(
                          formData.consigner_type &&
                          formData.unique_identification_number &&
                          formData.user_account_label
                        )
                    }
                  >
                    {loading ? <Spinner size="sm" color="light" /> : "Next"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default SelectConsignerStep;
