// ------------------------------------------------- Auth APIs --------------------------------------------

// Username Verification
export const USERNAME_VERIFY_API = "auth/username_verify";

// Send OTP
export const SEND_OTP_AUTH = "auth/send_otp_option";

// Verify OTP
export const VERIFY_OTP_AUTH = "auth/verify_session_otp";
