import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Label, Spinner } from "reactstrap";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../../../components/Component";
import { Link } from "react-router-dom";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import RadioInput from "../../../../../input/RadioInput";
import { dashboard_actions_helper } from "../../../../../../../../actions/dashboard_actions_helper";
import { RETRIEVE_TRUCKS_TRAILERS_LIST } from "../../../../../../../../api/dashboard_api_data";
import {
  RETRIEVE_TURCKS_TRAILERS_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_TURCKS_TRAILERS_LIST_ERR_MSG,
  RETRIEVE_TURCKS_TRAILERS_LIST_SUCCESS_MSG,
} from "../../../../../../../../constants/success_error_messages_consts";
import {
  fail_retrieve_trucks_trailers_list,
  loading_retrieve_trucks_trailers_list,
  reset_retrieve_trucks_trailers_list,
  success_retrieve_trucks_trailers_list,
} from "../../../../../../../../reducers/dashboard_reducers/truck/retrieve_trucks_trailers_list_Slice";
import { messageToast } from "../../../../../../../../utils/Utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TextInput from "../../../../../../../components/inputs/TextInput";

const initialState = {
  truck_driver_id: "",
  truck_id: "",
  truck_trailer_id_one: "",
  truck_trailer_id_two: "",
  comments: "",
  first_weight: "",
  unique_identification_number: "",
};

const TruckDetailsStep = ({
  weighBridgeFormData,
  setWeighBridgeFormData,
  truck_accounts_list,
  loadingList,
  refreshList,
  sentStep,
  prevStep,
}) => {
  const [errorVal, setErrorVal] = useState("");
  const [formData, setFormData] = useState(initialState);
  const [truck_accounts, setTruckAccounts] = useState([]);
  const [listLoading, setListLoading] = useState(loadingList);
  const [truckListLoading, setTruckListLoading] = useState(loadingList);
  const [addTrailer, setAddTrailer] = useState(false);
  const dispatch = useDispatch();
  const [vehicle_list, setVehicleList] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [trailers_one, setTrailersOne] = useState([]);
  const [trailers_two, setTrailersTwo] = useState([]);

  useEffect(() => {
    setFormData({ ...formData, ["unique_identification_number"]: weighBridgeFormData.unique_identification_number });
  }, [weighBridgeFormData]);

  useEffect(() => {
    const mappedList = truck_accounts_list?.map((truck_acc) => {
      return {
        value: truck_acc.unique_identification_number,
        label: `${truck_acc.unique_identification_number} - ${truck_acc.first_name} ${truck_acc.surname}`,
      };
    });
    setTruckAccounts(mappedList);
  }, [truck_accounts_list]);

  useEffect(() => {
    setListLoading(loadingList);
  }, [loadingList]);

  useEffect(() => {
    if (sentStep === 2)
    {
      fetchActiveTrucksTrailers();
    }
  }, [sentStep]);

  useEffect(() => {
    console.log(vehicle_list);
    if (vehicle_list.trucks_list)
    {
      let mappedList = vehicle_list.trucks_list?.map((truck) => {
        return {
          label: `${truck.truck_license_plate} - ${truck.ownership_status}`,
          value: truck.truck_id,
        };
      });
      setTrucks(mappedList);
    }
    if (vehicle_list.trailer_list)
    {
      let mappedList = vehicle_list.trailer_list?.map((trailer) => {
        return {
          label: `${trailer.trailer_plate} - ${trailer.ownership_status}`,
          value: trailer.trailer_id,
        };
      });
      setTrailersOne(mappedList);
    }
  }, [vehicle_list]);

  const fetchActiveTrucksTrailers = () => {
    dispatch(
      dashboard_actions_helper(
        loading_retrieve_trucks_trailers_list,
        success_retrieve_trucks_trailers_list,
        fail_retrieve_trucks_trailers_list,
        RETRIEVE_TURCKS_TRAILERS_LIST_ERR_MSG,
        RETRIEVE_TURCKS_TRAILERS_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_TRUCKS_TRAILERS_LIST,
        null,
        RETRIEVE_TURCKS_TRAILERS_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  useEffect(() => {
    setListLoading(loadingList);
  }, [loadingList]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  // Listen to Retrieval Reducer
  const retrieve_trucks_trailers_list = useSelector(
    (state) => state.retrieve_trucks_trailers_list.retrieve_trucks_trailers_list
  );

  useEffect(() => {
    if (retrieve_trucks_trailers_list.status !== app_consts.IDLE)
    {
      if (retrieve_trucks_trailers_list.status === app_consts.LOADING)
      {
        setTruckListLoading(true);
      } else
      {
        setTruckListLoading(false);
        if (retrieve_trucks_trailers_list.status === app_consts.FAIL)
        {
          messageToast(retrieve_trucks_trailers_list.message, app_consts.FAIL);
        } else if (retrieve_trucks_trailers_list.status === app_consts.SUCCESS)
        {
          setVehicleList(retrieve_trucks_trailers_list.data);
        }
        dispatch(reset_retrieve_trucks_trailers_list());
      }
    }
  }, [retrieve_trucks_trailers_list]);

  const refreshTrucksTrailersList = () => {
    fetchActiveTrucksTrailers();
  };

  // Previous Button
  const previousStep = () => {
    setFormData({ ...formData, ["truck_driver_id"]: "", ["truck_id"]: "" });
    prevStep();
  };

  // Filter Trailer 2
  const filterTrailers = (received_id) => {
    let filteredTrailer = trailers_one?.filter((item) => {
      return item.value !== received_id;
    });
    setTrailersTwo(filteredTrailer);
  };

  // Filter Trailer Two Options
  useEffect(() => {
    if (formData.truck_trailer_id_one)
    {
      filterTrailers(formData.truck_trailer_id_one);
    }
  }, [formData.truck_trailer_id_one]);

  // Handle Change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Select Truck Details</CardTitle>
        {errorVal && (
          <div className="mb-2">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <Col lg="12">
          <div className="form-group">
            {weighBridgeFormData.consigner_type === app_consts.UAG_CONSIGNER_TYPE ? (
              <>
                <div className="form-label-group">
                  <label className="form-label text-soft">User Account ID :</label>
                </div>
                <div className="form-control-wrap">
                  <label className="form-label text-primary">UAG</label>
                </div>
              </>
            ) : (
              <>
                <div className="form-label-group">
                  <label className="form-label text-soft">User Account ID :</label>
                </div>
                <div className="form-control-wrap">
                  <label className="form-label text-primary">{weighBridgeFormData.user_account_label}</label>
                </div>
              </>
            )}
          </div>
        </Col>
        <hr />
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row className="g-4">
            <Col size={6}>
              <div className="form-group">
                <label className="form-label mb-1 text-md" htmlFor="username">
                  Truck Driver
                </label>
                <div className="form-control-wrap align-items-center w-100 d-flex flex-row mb-2 justify-between">
                  <div className="w-80">
                    <RSelect
                      options={truck_accounts}
                      className="w-100"
                      placeholder="Search"
                      id="username"
                      onChange={(e) => setFormData({ ...formData, ["truck_driver_id"]: e.value })}
                      // onInputChange={(e) => onActionText(e)}
                      isDisabled={listLoading}
                    />
                  </div>
                  <div className="w-15">
                    {/* // Waiting for List to Load */}
                    {listLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <Link
                        to="#reload"
                        onClick={(e) => {
                          e.preventDefault();
                          refreshList(app_consts.REFRESH_USER_ACCOUNTS);
                        }}
                      >
                        <Icon name="reload-alt btn-icon fs-22px"></Icon>
                      </Link>
                    )}
                    {/*  } */}
                  </div>
                </div>
              </div>
            </Col>
            {formData.truck_driver_id && (
              <>
                <Col size={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="">
                      Truck License Plate
                    </label>
                    <div className="w-100 form-control-wrap d-flex flex-row justify-between items-center">
                      <div className="w-80">
                        <RSelect
                          options={trucks}
                          className="w-100"
                          placeholder="Search"
                          id="username"
                          onChange={(e) => setFormData({ ...formData, ["truck_id"]: e.value })}
                          // onInputChange={(e) => onActionText(e)}
                          isDisabled={truckListLoading}
                        />
                      </div>
                      <div className="w-15">
                        {/* // Waiting for List to Load */}
                        {truckListLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          <Link
                            to="#reload"
                            onClick={(e) => {
                              e.preventDefault();
                              refreshTrucksTrailersList();
                            }}
                          >
                            <Icon name="reload-alt btn-icon fs-22px"></Icon>
                          </Link>
                        )}
                        {/*  } */}
                      </div>
                    </div>
                  </div>
                </Col>
                <TextInput
                  colSize={5}
                  label="Truck First Weight (Metric Tonnes)"
                  type="number"
                  id_val="first_weight"
                  value={formData.first_weight}
                  handleChange={handleChange}
                  name="first_weight"
                />
                {formData.truck_id && (
                  <>
                    <hr />
                    <Col size="12">
                      <div className="form-group">
                        <div className="form-control-wrap-flex-wrap justify-start">
                          <Button
                            onClick={() => {
                              setAddTrailer(!addTrailer);
                              setFormData({ ...formData, ["truck_trailer_id_one"]: "", ["truck_trailer_id_two"]: "" });
                            }}
                            className="btn btn-dim btn-outline-primary"
                            size="md"
                          >
                            {addTrailer ? "Remove Trailers" : "Add Trailers (Optional)"}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </>
                )}
                {addTrailer && (
                  <>
                    <Col size={6}>
                      <div className="form-group">
                        <label className="form-label" htmlFor="">
                          Trailer 1
                        </label>
                        <div className="w-80 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                          <div className="w-80">
                            <RSelect
                              options={trailers_one}
                              className="w-100"
                              placeholder="Search"
                              id="username"
                              onChange={(e) => {
                                setFormData({ ...formData, ["truck_trailer_id_one"]: e.value });
                                setTrailersTwo([]);
                              }}
                              // onInputChange={(e) => onActionText(e)}
                              isDisabled={listLoading}
                            />
                          </div>
                          <div className="w-15">
                            {/* // Waiting for List to Load */}
                            {listLoading ? (
                              <Spinner size="sm" />
                            ) : (
                              <Link
                                to="#reload"
                                onClick={(e) => {
                                  e.preventDefault();
                                  refreshList(app_consts.REFRESH_USER_ACCOUNTS);
                                }}
                              >
                                <Icon name="reload-alt btn-icon fs-22px"></Icon>
                              </Link>
                            )}
                            {/*  } */}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col size={6}>
                      <div className="form-group">
                        <label className="form-label" htmlFor="">
                          Trailer 2 (Optional)
                        </label>
                        <div className="w-80 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                          <div className="w-80">
                            <RSelect
                              options={trailers_two}
                              className="w-100"
                              placeholder="Search"
                              id="username"
                              onChange={(e) => setFormData({ ...formData, ["truck_trailer_id_two"]: e.value })}
                              // onInputChange={(e) => onActionText(e)}
                              isDisabled={truckListLoading}
                            />
                          </div>
                          <div className="w-15">
                            {/* // Waiting for List to Load */}
                            {listLoading ? (
                              <Spinner size="sm" />
                            ) : (
                              <Link
                                to="#reload"
                                onClick={(e) => {
                                  e.preventDefault();
                                  refreshList(app_consts.REFRESH_USER_ACCOUNTS);
                                }}
                              >
                                <Icon name="reload-alt btn-icon fs-22px"></Icon>
                              </Link>
                            )}
                            {/*  } */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                )}
                <hr />
                <Col sm="10">
                  <div className="form-group">
                    <Label htmlFor="default-textarea" className="form-label">
                      WeighBridge Ticket Comments
                    </Label>
                    <div className="form-control-wrap">
                      <textarea
                        className="no-resize form-control"
                        type="textarea"
                        id="default-textarea"
                        value={formData.comments}
                        onChange={(e) => setFormData({ ...formData, ["first_weight"]: e.value })}
                      />
                    </div>
                  </div>
                </Col>
              </>
            )}
            <Col xl="12">
              <div className="form-group">
                <div className="form-control-wrap-flex-wrap justify-between items center">
                  <Button className="btn btn-dim btn-outline-primary" size="md" onClick={previousStep}>
                    Previous
                  </Button>
                  <Button
                    disabled={
                      addTrailer
                        ? !(
                          formData.truck_driver_id &&
                          formData.truck_id &&
                          formData.truck_trailer_id_one &&
                          formData.first_weight
                        )
                        : !(formData.truck_driver_id && formData.truck_id && formData.first_weight)
                    }
                    color="primary"
                    size="md"
                  >
                    Preview & Create Ticket
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default TruckDetailsStep;
