import React, { useEffect, useState } from "react";
import { Alert, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  BranchLocationModalDetails,
  CompanyRepresentativeDetails,
  ProductModalDetails,
  ProductTypeModalDetails,
  UnitMeasurementDetails,
  UserAccountPropertyDetails,
  UserModalDetails,
} from "./modal_details_data/modal_details_data";
import * as app_consts from "../../../../../constants/react_app_consts";
import { Row } from "../../../../../components/Component";

const ModalDetails = ({ modalTitle, modalOpen, setModalOpen, modalType, modalData }) => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [type, setType] = useState();

  useEffect(() => {
    setModal(modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    setTitle(modalTitle);
  }, [modalTitle]);

  useEffect(() => {
    setData(modalData);
  }, [modalData]);

  useEffect(() => {
    setType(modalType);
  }, [modalType]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal} className="modal-dialog-centered" size="lg">
      <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
      <ModalBody>
        <div className="nk-tnx-details mt-sm-3">
          <Row className="gy-3">
            {type === app_consts.USER_LIST_TYPE ? (
              <UserModalDetails data={data} />
            ) : type === app_consts.BRANCH_LOCATION_LIST ? (
              <BranchLocationModalDetails data={data} />
            ) : type === app_consts.PRODUCT_LIST ? (
              <ProductModalDetails data={data} />
            ) : type === app_consts.PRODUCT_TYPE_LIST ? (
              <ProductTypeModalDetails data={data} />
            ) : type === app_consts.UNIT_MEASUREMENT_LIST ? (
              <UnitMeasurementDetails data={data} />
            ) : type === app_consts.ONBOARD_USER_PROPERTY ? (
              <UserAccountPropertyDetails data={data} />
            ) : type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE ? (
              <CompanyRepresentativeDetails data={data} />
            ) : (
              ""
            )}
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalDetails;
