import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import PageHeading from "../../components/page_heading/PageHeading";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewCard,
  RSelect,
  Row,
} from "../../../../components/Component";
import TextInput from "../../../components/inputs/TextInput";
import { Alert, Spinner } from "reactstrap";
import { dashboard_actions_helper } from "../../../../actions/dashboard_actions_helper";
import { useDispatch } from "react-redux";
import {
  ADD_BRANCH_LOCATION_ACCESS_DENIED_MSG,
  ADD_BRANCH_LOCATION_ERR_MSG,
  ADD_BRANCH_LOCATION_SUCCESS_MSG,
  RETRIEVE_PROVINCES_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_PROVINCES_LIST_ERR_MSG,
  RETRIEVE_PROVINCES_LIST_SUCCESS_MSG,
} from "../../../../constants/success_error_messages_consts";
import { ADD_BRANCH_LOCATION, RETRIEVE_ZAMBIAN_PROVINCES_LIST } from "../../../../api/dashboard_api_data";
import { useSelector } from "react-redux";
import {
  fail_provinces_list,
  loading_provinces_list,
  reset_provinces_list,
  success_provinces_list,
} from "../../../../reducers/dashboard_reducers/location/provinces_list_Slice";
import { messageToast } from "../../../../utils/Utils";
import * as app_consts from "../../../../constants/react_app_consts";
import { ZAMIBAN_PHONE_NUMBER_REGEX } from "../../../../utils/RegexUtils";
import ModalPreview from "../../components/modal/modal_preview/ModalPreview";
import {
  fail_add_branch_location,
  loading_add_branch_location,
  reset_add_branch_location,
  success_add_branch_location,
} from "../../../../reducers/dashboard_reducers/branch_locations/add_branch_location_Slice";
import { useNavigate } from "react-router";

const initialState = { branch_area: "", branch_physical_address: "", branch_mobile_number: "", province_id: "" };

const AddBranchLocation = () => {
  const [errorVal, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const [openPreview, setOpenPreview] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Retrieve Zambian Provinces
  useEffect(() => {
    // Retrieve User's Information
    dispatch(
      dashboard_actions_helper(
        loading_provinces_list,
        success_provinces_list,
        fail_provinces_list,
        RETRIEVE_PROVINCES_LIST_ERR_MSG,
        RETRIEVE_PROVINCES_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_ZAMBIAN_PROVINCES_LIST,
        null,
        RETRIEVE_PROVINCES_LIST_ACCESS_DENIED_MSG,
        app_consts.MAP,
        app_consts.PROVINCES_MAP
      )
    );
  }, []);

  // Listen to Changes in  Provinces Reducers
  const provinces_list = useSelector((state) => state.provinces_list.provinces_list);

  useEffect(() => {
    if (provinces_list.status !== app_consts.IDLE && provinces_list.status !== app_consts.LOADING) {
      if (provinces_list.status === app_consts.FAIL) {
        messageToast(provinces_list.message, app_consts.FAIL);
      } else if (provinces_list.status === app_consts.SUCCESS) {
        setProvinces(provinces_list.data);
      }
      dispatch(reset_provinces_list());
    }
  }, [provinces_list]);

  //Checking Valid Phone Number
  const [phone_number, setPhoneNumber] = useState("");
  const [validPNumber, setValidPNumber] = useState(false);
  const [inputPNumberFocus, setInputPNumberFocus] = useState(false);

  // Handle Change Function
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "branch_mobile_number") {
      setPhoneNumber(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  //Checking States to see if phone Number empty or not
  useEffect(() => {
    const result = ZAMIBAN_PHONE_NUMBER_REGEX.test(phone_number);
    setValidPNumber(result);
  }, [phone_number]);

  //Preview Details
  const handlePreview = () => {
    setError("");
    setOpenPreview(true);
    setLoading(false);
  };

  //Preview Details
  const handleSubmit = () => {
    setOpenPreview(false);
    // Add Branch Location
    dispatch(
      dashboard_actions_helper(
        loading_add_branch_location,
        success_add_branch_location,
        fail_add_branch_location,
        ADD_BRANCH_LOCATION_ERR_MSG,
        ADD_BRANCH_LOCATION_SUCCESS_MSG,
        app_consts.POST,
        ADD_BRANCH_LOCATION,
        formData,
        ADD_BRANCH_LOCATION_ACCESS_DENIED_MSG
      )
    );
  };
  // Listen to Reducer Changes in Add Branch Location
  const add_branch_location = useSelector((state) => state.add_branch_location.add_branch_location);

  useEffect(() => {
    console.log(add_branch_location);
    if (add_branch_location.status !== app_consts.IDLE) {
      if (add_branch_location.status === app_consts.LOADING) {
        setLoading(true);
      } else {
        setLoading(false);
        if (add_branch_location.status === app_consts.FAIL) {
          setError(add_branch_location.message);
          messageToast(add_branch_location.message, app_consts.FAIL);
        } else if (add_branch_location.status === app_consts.SUCCESS) {
          messageToast(`Branch Location '${formData.branch_area}' Successfully Added.`, app_consts.SUCCESS);
          navigate("/dashboard/admin/branch_locations");
        }
        dispatch(reset_add_branch_location());
      }
    }
  }, [add_branch_location]);

  return (
    <>
      <Head title="Add Branch Location" />
      <Content>
        <div className="w-90 m-auto">
          <PageHeading
            title="Add Branch Location"
            description="Add Branch Location By Filling in and Submiting the form Below."
            backToText="Branch Locations"
            backToLink={`/dashboard/admin/branch_locations`}
          />
          <Block size="lg">
            <PreviewCard>
              <BlockHead>
                <BlockHeadContent className="text-center">
                  <BlockTitle tag="h4">Branch Location Form</BlockTitle>
                  <p>Please Complete the Form Below</p>
                </BlockHeadContent>
              </BlockHead>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                {errorVal && (
                  <div className="mb-3 w-100">
                    <Alert color="danger" className="alert-icon">
                      <Icon name="alert-circle" /> {errorVal}{" "}
                    </Alert>
                  </div>
                )}
                <Row className="g-gs flex mb-2">
                  <TextInput
                    label="Branch Area"
                    required
                    type="text"
                    id_val="branch_area"
                    maxlength="30"
                    value={formData.branch_area}
                    colSize="6"
                    name="branch_area"
                    handleChange={handleChange}
                  />
                  <TextInput
                    label="Branch Physical Address"
                    required
                    type="text"
                    id_val="branch_physical_address"
                    maxlength="100"
                    value={formData.branch_physical_address}
                    colSize="6"
                    name="branch_physical_address"
                    handleChange={handleChange}
                  />
                  <TextInput
                    label="Branch Mobile Number"
                    required
                    type="text"
                    id_val="branch_mobile_number"
                    maxlength="12"
                    value={formData.branch_mobile_number}
                    colSize="6"
                    name="branch_mobile_number"
                    handleChange={handleChange}
                    error_stat={inputPNumberFocus && phone_number && !validPNumber ? true : false}
                    err_Msg={
                      "Zambian Phone Number should start with 260, folowed by (95|96|97|77|76|75) and should consist of 12 Digits."
                    }
                    onFocus={() => setInputPNumberFocus(true)}
                  />

                  <Col lg="6" className="mb-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="province_id">
                        Province
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={provinces}
                          className="w-100%"
                          id="province_id"
                          onChange={(e) =>
                            setFormData({ ...formData, ["province_id"]: e.value, ["province_name"]: e.label })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl="12" className="d-flex justify-content-end">
                    <Button
                      onClick={handlePreview}
                      size="lg"
                      type="submit"
                      color="primary"
                      disabled={
                        loading
                          ? loading
                          : !(
                              formData.branch_area &&
                              validPNumber &&
                              formData.branch_physical_address &&
                              formData.province_id
                            )
                      }
                    >
                      {loading ? <Spinner size="sm" color="light" /> : "Preview and Add Branch Location"}
                    </Button>
                  </Col>
                </Row>
              </form>
            </PreviewCard>
          </Block>
        </div>
      </Content>
      {/* Modal For Previewing Before Create */}
      <ModalPreview
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
        modalTitle={`Add Branch Location Preview`}
        modalDescription={`Please Confirm Branch Location Details and Select 'Add Branch' to Add Branch Location.`}
        moduleButtonText="Add Branch"
        prevType={app_consts.PREVIEW_ADD_BRANCH_LOCATION}
        prevData={formData}
        buttonFunction={handleSubmit}
      />
    </>
  );
};

export default AddBranchLocation;
