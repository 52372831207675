import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CreateUserModal from "./modal_add_data/CreateUserModal";
import * as app_consts from "../../../../../constants/react_app_consts";
import AddProductModal from "./modal_add_data/AddProductModal";
import AddProductTypeModal from "./modal_add_data/AddProductTypeModal";
import AddUnitMeasurementModal from "./modal_add_data/AddUnitMeasurementModal";
import AddUserPropertyModal from "./modal_add_data/AddUserPropertyModal";
import AddCompanyRepresentativeModal from "./modal_add_data/AddCompanyRepresentativeModal";
import AddCompRepRoleModal from "./modal_add_data/AddCompRepRoleModal";

const ModalAdd = ({
  openModal,
  setOpenModal,
  type,
  modalTitle,
  modalDescription,
  succFunc,
  size,
  accountId,
  openOtherModalFunc,
}) => {
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    setModal(openModal);
  }, [openModal]);

  const toggleModal = () => {
    setOpenModal(!modal);
  };

  return (
    <Modal
      isOpen={modal}
      // toggle={!modalLoading ? toggleModal : ""}
      className="modal-dialog-centered"
      size={size ? size : "lg"}
    >
      <ModalHeader toggle={!modalLoading ? toggleModal : ""}>{modalTitle}</ModalHeader>
      <ModalBody>
        {
          // Users
          type === app_consts.USER_LIST_TYPE ? (
            <CreateUserModal successFunction={succFunc} setModalLoading={setModalLoading} />
          ) : // Products
          type === app_consts.PRODUCT_LIST ? (
            <AddProductModal successFunction={succFunc} openModal={modal} setModalLoading={setModalLoading} />
          ) : // Product Types
          type === app_consts.PRODUCT_TYPE_LIST ? (
            <AddProductTypeModal successFunction={succFunc} openModal={modal} setModalLoading={setModalLoading} />
          ) : // Unit Measurement
          type === app_consts.UNIT_MEASUREMENT_LIST ? (
            <AddUnitMeasurementModal successFunction={succFunc} openModal={modal} setModalLoading={setModalLoading} />
          ) : // User Porperty
          type === app_consts.ONBOARD_USER_PROPERTY ? (
            <AddUserPropertyModal
              accountId={accountId}
              successFunction={succFunc}
              openModal={modal}
              setModalLoading={setModalLoading}
            />
          ) : // Company Representative
          type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE ? (
            <AddCompanyRepresentativeModal
              accountId={accountId}
              successFunction={succFunc}
              openModal={modal}
              setModalLoading={setModalLoading}
              openOtherModalFunc={openOtherModalFunc}
            />
          ) : // Company Representative Role
          type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE_ROLE ? (
            <AddCompRepRoleModal
              accountId={accountId}
              successFunction={succFunc}
              openModal={modal}
              setModalLoading={setModalLoading}
              openOtherModalFunc={openOtherModalFunc}
            />
          ) : (
            ""
          )
        }
      </ModalBody>
    </Modal>
  );
};

export default ModalAdd;
