import React, { useEffect, useState } from "react";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../../components/Component";
import { Link } from "react-router-dom";
import { onOptionsSearch } from "../../../../../../../utils/Utils";
import { Spinner } from "reactstrap";
import * as app_consts from "../../../../../../../constants/react_app_consts";
import RadioInput from "../../../../input/RadioInput";
import TextInput from "../../../../../../components/inputs/TextInput";

const VerifyOTPStep = ({ data, formData, setFormData, buttonClickFunc, isLoading, selectedUser }) => {
  const [user_list_data, setUserListData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputData, setInputData] = useState(formData);
  const [btnLoading, setBtnLoading] = useState(isLoading);

  // UseEffect for FormData
  useEffect(() => {
    setInputData(formData);
  }, [formData]);

  // UseEffect for Data
  useEffect(() => {
    if (data) {
      setUserListData(data[0]);
    }
  }, [data]);

  // UseEffect for Button Loading
  useEffect(() => {
    setBtnLoading(isLoading);
  }, [isLoading]);

  // UseEffect for Selected Option
  useEffect(() => {
    if (selectedUser) {
      setSelectedOption(selectedUser);
    }
  }, [selectedUser]);

  // Filter Selected User Based On Selected Option

  // Manage Changes When An Option is Selected
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Row>
          <Col>
            <div className="form-group">
              <p>
                Please Enter the OTP Sent to{" "}
                <span className="fw-bold">
                  {selectedUser.first_name && `${selectedUser.first_name} ${selectedUser.surname}`}.
                </span>
              </p>
              <div className="mb-3">
                <TextInput
                  label="OTP"
                  type="password"
                  value={formData.otp}
                  colSize="12"
                  name="otp"
                  handleChange={handleChange}
                  maxlength="6"
                />
              </div>
            </div>
          </Col>
          <Col xl="12">
            <div className="form-group mt-2">
              <div className="form-control-wrap-flex-wrap justify-center">
                <Button
                  disabled={btnLoading ? btnLoading : !(inputData.phone_number && inputData.otp)}
                  color="primary"
                  onClick={buttonClickFunc}
                  size="lg"
                >
                  <span>{btnLoading ? <Spinner size="sm" /> : "Verify OTP"}</span>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default VerifyOTPStep;
