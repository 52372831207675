import React from 'react'
import { Icon, RSelect } from '../../../../components/Component';
import { Link } from "react-router-dom";
import { Badge } from 'reactstrap';

const ListText = ({ label, value, type, clickEvent, setClickedImage, linkUrl, badge, badgeColor, editableControl, editToggle, dataItem }) => {
    return (
        <>
            <li className={dataItem ? dataItem : "data-item"}>
                <div className="data-col">
                    <div className="data-label">{label}</div>
                    <div className={`data-value`}>
                        {value}
                    </div>
                </div>
            </li>
        </>
    )
}

export default ListText;