import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Row } from "../../../../../components/Component";
import * as app_consts from "../../../../../constants/react_app_consts";
import ModalAssignUserSearch from "./modal_search_data/ModalAssignUserSearch";
import ModalCompleteKycRegWiz from "../modal_wizard/ModalCompleteKycRegWiz";
import ModalOnboardCompanySearch from "./modal_search_data/ModalOnboardCompanySearch";

const ModalSearch = ({ modalTitle, modalOpen, setModalOpen, modalType, modalData }) => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [type, setType] = useState();

  useEffect(() => {
    setModal(modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    setTitle(modalTitle);
  }, [modalTitle]);

  useEffect(() => {
    setData(modalData);
  }, [modalData]);

  useEffect(() => {
    setType(modalType);
  }, [modalType]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Modal isOpen={modal} /* toggle={toggleModal} */>
      <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
      <ModalBody>
        <div className="nk-tnx-details mt-sm-3">
          <Row className="gy-3">
            {type === app_consts.ASSIGN_USER_ACCOUNTS ? (
              <ModalAssignUserSearch isOpen={modal} setIsOpen={setModal} />
            ) : type === app_consts.COMPLETE_KYC_REGISTRATION ? (
              <ModalCompleteKycRegWiz isOpen={modal} setIsOpen={setModal} />
            ) : type === app_consts.ONBOARD_COMPANY ? (
              <ModalOnboardCompanySearch isOpen={modal} setIsOpen={setModal} />
            ) : (
              ""
            )}
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalSearch;
