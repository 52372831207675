import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import PageHeading from "../../components/page_heading/PageHeading";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
} from "../../../../components/Component";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import * as app_consts from "../../../../constants/react_app_consts";
import { CardLinkInfo } from "../../components/cards/CardLinks";
import {
  RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ACCESS_DENIED_MSG,
  RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ERR_MSG,
  RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_SUCCESS_MSG,
} from "../../../../constants/success_error_messages_consts";
import { RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED } from "../../../../api/dashboard_api_data";
import { dashboard_actions_helper } from "../../../../actions/dashboard_actions_helper";
import {
  fail_specific_user_details,
  loading_specific_user_details,
  reset_specific_user_details,
  success_specific_user_details,
} from "../../../../reducers/dashboard_reducers/user/specific_user_details_Slice";
import { reset_verify_user_kyc_status } from "../../../../reducers/dashboard_reducers/user_kyc/verify_user_kyc_status_Slice";

const AssignUserIndex = () => {
  // Read Phone Number from URL
  const { phone_number } = useParams();
  const dispatch = useDispatch();
  const [errorVal, setError] = useState("");
  const [acc_info, setAccInfo] = useState([]);
  const [page_data, setPageData] = useState([]);
  const [componentData, setComponentData] = useState([]);
  //
  useEffect(() => {
    dispatch(
      dashboard_actions_helper(
        loading_specific_user_details,
        success_specific_user_details,
        fail_specific_user_details,
        RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ERR_MSG,
        RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_SUCCESS_MSG,
        app_consts.POST,
        RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED,
        { phone_number: phone_number },
        RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ACCESS_DENIED_MSG
      )
    );

    dispatch(reset_verify_user_kyc_status());
  }, []);

  // UseSelector to Listen to Changes in Retrival of Users
  const retrieved_user_account = useSelector((state) => state.specific_user_details.specific_user_details);
  //
  useEffect(() => {
    if (retrieved_user_account.status !== app_consts.IDLE) {
      if (retrieved_user_account.status !== app_consts.LOADING) {
        if (retrieved_user_account.status === app_consts.FAIL) {
          setError(retrieved_user_account.message);
        } else if (retrieved_user_account.status === app_consts.SUCCESS) {
          setAccInfo(retrieved_user_account.data);
        }
        dispatch(reset_specific_user_details());
      }
    }
  }, [retrieved_user_account]);

  // Retrieve Module Actions Data
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);
  // UseEffect to Place Page Data
  useEffect(() => {
    if (action_roles.status === app_consts.SUCCESS) {
      setPageData(action_roles.data);
      if (action_roles.data.onboarding) {
        setComponentData(action_roles.data.onboarding.module_actions);
      }
    }
  }, [action_roles]);

  return (
    <>
      <Head
        title={
          acc_info
            ? `Assign User Account - ${acc_info.first_name || ""} ${acc_info.surname || ""}/${
                acc_info.phone_number || ""
              }`
            : "Assign User Account"
        }
      />
      <Content>
        <PageHeading
          title="Assign User Account"
          title_addition={
            acc_info.first_name ? `/ ${acc_info.first_name} ${acc_info.surname} - ${acc_info.phone_number}` : ""
          }
          description="Complete User KYC Registration by Completing All the Steps."
          backToText="User Onboarding"
          backToLink={`/dashboard/onboarding/user`}
        />
        {errorVal && (
          <div className="mb-3 w-100">
            <Alert color="danger" className="alert-icon">
              <Icon name="alert-circle" /> {errorVal}{" "}
            </Alert>
          </div>
        )}
        <Block size="lg">
          <PreviewCard>
            <BlockHead>
              <BlockHeadContent className="text-center">
                <BlockTitle tag="h4">Select an Option</BlockTitle>
                <p>Assign a Truck Driver, A Customer, Employee or A Company to a User</p>
              </BlockHeadContent>
            </BlockHead>
            <Row className="g-gs flex mb-2">
              {page_data && page_data.onboarding && (
                <>
                  {componentData && (
                    <>
                      {/* Assiging Customer */}
                      {componentData.assign_customer && (
                        <CardLinkInfo
                          colSize="4"
                          iconType="in-built"
                          icon="user-add"
                          cardTitle="Assign Customer Account"
                          cardTextInfo="Assign A Registered User A Customer Account Only if KYC Registration is Complete."
                          buttonLink={`/dashboard/onboarding/user/assign/${phone_number}/customer`}
                          buttonText="Proceed"
                          buttonType={app_consts.BUTTON_OPEN_PAGE_TYPE}
                        />
                      )}
                      {/* Assiging Truck Driver */}
                      {componentData.assign_truck_driver && (
                        <CardLinkInfo
                          colSize="4"
                          iconType="in-built"
                          icon="user-check"
                          cardTitle="Assign Truck Driver Account."
                          cardTextInfo="Assign A Registered User A Truck Driver Account."
                          buttonLink={`/dashboard/onboarding/user/assign/${phone_number}/truck_driver`}
                          buttonText="Proceed"
                          comp_data={{ modal_type: app_consts.ASSIGN_USER_ACCOUNTS }}
                          buttonType={app_consts.BUTTON_OPEN_PAGE_TYPE}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Row>
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default AssignUserIndex;
