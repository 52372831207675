import moment from "moment";
import * as app_consts from "../../../../../constants/react_app_consts";


export const export_to_csv_fun = async (data, type) => {
    let csv_data = [];
    let title;
    let filename;
    const cur_dt = moment().format("DD-MM-YYYY h:mm:ss a");

    switch (type) {
        case app_consts.USER_LIST_TYPE:
            title = "United African Grain Users List"
            filename = `United African Grain Users List (as of ${cur_dt})`

            // Restructure Export Data 
            csv_data = await restruc_csv_data(data, type)
            break;
    }

    console.log(data);

    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        filename: filename,
        useKeysAsHeaders: true,
    }

    return { csv_data, options }
}

// Restructure CVS ata
const restruc_csv_data = async (data, type) => {
    let csv_data;

    switch (type) {
        case app_consts.USER_LIST_TYPE:
            // Restructure Export Data 
            csv_data = data?.map((user_data) => ({
                first_name: user_data.first_name,
                middle_name: user_data.middle_name,
                surname: user_data.surname,
                dob: user_data.dob,
                phone_number: user_data.phone_number,
                email_address: user_data.email_address,
                account_status: user_data.account_status,
                registration_date: moment(user_data.registration_date).format("YYYY-MM-DD h:mm:ss a"),
                branch_physical_address: user_data.branch_physical_address,
                branch_area: user_data.branch_area,
                branch_province_name: user_data.branch_province_name,
                branch_country_name: user_data.branch_country_name,
            }))
            break;
    }

    return csv_data;
} 