import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import PageHeading from "../../components/page_heading/PageHeading";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard, Row } from "../../../../components/Component";
import { CardLinkInfo } from "../../components/cards/CardLinks";
import * as app_consts from "../../../../constants/react_app_consts";
import { useSelector } from "react-redux";
import ModalSearch from "../../components/modal/modal_search/ModalSearch";

const CompanyOnboardingIndex = () => {
  const [page_data, setPageData] = useState([]);
  const [componentData, setComponentData] = useState([]);
  const [modalSearchOpen, setOpenSearchModal] = useState(false);
  const [modalSearchType, setModuleSearchType] = useState("");

  // Retrieve Module Actions Data
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);
  // UseEffect to Place Page Data
  useEffect(() => {
    if (action_roles.status === app_consts.SUCCESS) {
      setPageData(action_roles.data);
      if (action_roles.data.onboarding) {
        setComponentData(action_roles.data.onboarding.module_actions);
      }
    }
  }, [action_roles]);

  // Funtion to Open User Search Module
  const open_search_module = (search_module_data) => {
    setModuleSearchType(search_module_data.modal_type);
    setOpenSearchModal(true);
  };

  return (
    <>
      <Head title="Company Onboarding" />
      <Content>
        <PageHeading
          title="Company Onbaording"
          description="Onboard Companies and Assign Onboarded Users as Company Representatives."
        />
        <Block size="lg">
          <PreviewCard>
            <BlockHead>
              <BlockHeadContent className="text-center">
                <BlockTitle tag="h4">Select an Option</BlockTitle>
                <p>Onboard a Company or Attach a Registered User a Company as One of its Representatives.</p>
              </BlockHeadContent>
            </BlockHead>
            <Row className="g-gs flex mb-2">
              {page_data && page_data.onboarding && (
                <>
                  {componentData && (
                    <>
                      {/* User Onboarding */}
                      {componentData.onboard_company && (
                        <CardLinkInfo
                          colSize="4"
                          iconType="in-built"
                          icon="building"
                          cardTitle="Company Onboarding"
                          cardTextInfo="Create a Company Account via a Registered Affiliated Member. KYC Status should be 'Completed'."
                          buttonLink="/dashboard/onboarding/company"
                          buttonText="Proceed"
                          comp_data={{ modal_type: app_consts.ONBOARD_COMPANY }}
                          buttonType={app_consts.BUTTON_OPEN_MODAL_TYPE}
                          buttonModuleFunction={open_search_module}
                        />
                      )}
                      {/* Assiging User */}
                      {componentData.assign_company_representative && (
                        <CardLinkInfo
                          colSize="4"
                          iconType="in-built"
                          icon="user-check"
                          cardTitle="Assign Company Representative(s)"
                          cardTextInfo="Assign Company Representatives to A Company. KYC Status should be 'Completed'."
                          buttonLink="dashboard/onboarding/user/assign"
                          buttonText="Proceed"
                          comp_data={{ modal_type: app_consts.ASSIGN_USER_ACCOUNTS }}
                          buttonType={app_consts.BUTTON_OPEN_MODAL_TYPE}
                          buttonModuleFunction={open_search_module}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Row>
          </PreviewCard>
        </Block>
      </Content>
      {/* Search Module */}
      <ModalSearch
        modalOpen={modalSearchOpen}
        setModalOpen={setOpenSearchModal}
        modalTitle="Search for Active User to Continue"
        modalType={modalSearchType}
      />
    </>
  );
};

export default CompanyOnboardingIndex;
