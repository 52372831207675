import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  add_user_account_property: { status: "idle" },
};

export const add_user_account_property_Slice = createSlice({
  name: "add_user_account_property",
  initialState,
  reducers: {
    success_add_user_account_property: (state, action) => {
      state.add_user_account_property = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_add_user_account_property: (state, action) => {
      state.add_user_account_property = { status: app_consts.FAIL, message: action.payload };
    },
    reset_add_user_account_property: (state) => {
      state.add_user_account_property = { status: app_consts.IDLE };
    },
    loading_add_user_account_property: (state) => {
      state.add_user_account_property = { status: app_consts.LOADING };
    },
  },
});

export const {
  success_add_user_account_property,
  fail_add_user_account_property,
  reset_add_user_account_property,
  loading_add_user_account_property,
} = add_user_account_property_Slice.actions;

export default add_user_account_property_Slice.reducer;
