import axios from "axios";

const API_URL = process.env.REACT_APP_EMPLOYEE_DASHBOARD_API_ENDPOINT;

axios.interceptors.request.use((req) => {
  if (localStorage.getItem("access")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access")).access_token}`;
  }
  return req;
});

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

// Retrieve Uncomplete KYC Reg Status List
export const retrieve_uncomplete_kyc_list_api = () =>
  axios.get(API_URL + "user_kyc/retrieve_uncomplete_kyc_list", { withCredentials: false });
