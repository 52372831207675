import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  branch_location_list: { status: "idle" },
};

export const branch_location_list_Slice = createSlice({
  name: "branch_location_list",
  initialState,
  reducers: {
    success_branch_location_list: (state, action) => {
      state.branch_location_list = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_branch_location_list: (state, action) => {
      state.branch_location_list = { status: app_consts.FAIL, message: action.payload };
    },
    reset_branch_location_list: (state) => {
      state.branch_location_list = { status: app_consts.IDLE };
    },
    loading_branch_location_list: (state) => {
      state.branch_location_list = { status: app_consts.LOADING };
    },
  },
});

export const {
  success_branch_location_list,
  fail_branch_location_list,
  reset_branch_location_list,
  loading_branch_location_list,
} = branch_location_list_Slice.actions;

export default branch_location_list_Slice.reducer;
