import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Spinner } from "reactstrap";
import { Button, Col, Icon } from "../../../../../../../../components/Component";
import { CREATE_CUSTOMER_ACCOUNT } from "../../../../../../../../api/dashboard_api_data";
import {
  fail_create_customer_account,
  loading_create_customer_account,
  reset_create_customer_account,
  success_create_customer_account,
} from "../../../../../../../../reducers/dashboard_reducers/user_accounts/create_customer_account_Slice";
import { dashboard_actions_helper } from "../../../../../../../../actions/dashboard_actions_helper";
import { useDispatch } from "react-redux";
import {
  CREATE_CUSTOMER_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG,
  CREATE_CUSTOMER_USER_ACCOUNT_LIST_ERR_MSG,
  CREATE_CUSTOMER_USER_ACCOUNT_LIST_SUCCESS_MSG,
} from "../../../../../../../../constants/success_error_messages_consts";
import { useSelector } from "react-redux";
import { messageToast } from "../../../../../../../../utils/Utils";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { useParams } from "react-router";

const initialState = { phone_number: "" };

const GenerateCustomerIDStep = ({ togglestep, nextStep, data, setUniqueID }) => {
  const [errorVal, setError] = useState("");
  const [formData, setFormData] = useState(initialState);
  const [user_details, setUserDeatils] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { phone_number } = useParams();

  // Reading Data Passed Along
  useEffect(() => {
    if (data) {
      setFormData({ ...formData, ["phone_number"]: phone_number });
      setUserDeatils(data);
    }
  }, [data]);

  const handleSubmit = () => {
    setError("");
    dispatch(
      dashboard_actions_helper(
        loading_create_customer_account,
        success_create_customer_account,
        fail_create_customer_account,
        CREATE_CUSTOMER_USER_ACCOUNT_LIST_ERR_MSG,
        CREATE_CUSTOMER_USER_ACCOUNT_LIST_SUCCESS_MSG,
        app_consts.POST,
        CREATE_CUSTOMER_ACCOUNT,
        { phone_number: phone_number },
        CREATE_CUSTOMER_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Reducers For Creating Customer Account
  const create_customer_account = useSelector((state) => state.create_customer_account.create_customer_account);
  //
  useEffect(() => {
    if (create_customer_account.status !== app_consts.IDLE) {
      if (create_customer_account.status === app_consts.LOADING) {
        setLoading(true);
      } else {
        setLoading(false);
        if (create_customer_account.status === app_consts.FAIL) {
          messageToast(create_customer_account.message, app_consts.FAIL);
          setError(create_customer_account.message, app_consts.FAIL);
        } else if (create_customer_account.status === app_consts.SUCCESS) {
          setUniqueID(create_customer_account.data);
          messageToast(CREATE_CUSTOMER_USER_ACCOUNT_LIST_SUCCESS_MSG, app_consts.SUCCESS);
          nextStep();
        }
        dispatch(reset_create_customer_account());
      }
    }
  }, [create_customer_account]);

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Generate Unique Customer ID</CardTitle>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Col lg="12" className="mb-3">
            <div className="form-group d-flex justify-content-center flex-column align-items-center">
              <span className="w-80 form-label text-center mt-4 fs-16px text-soft">
                To Create A Customer Account, Please Select the Button Below to Generate a Unique Customer ID for <br />
                {user_details.first_name ? (
                  <strong className="text-primary">{`${user_details.first_name} ${user_details.surname}.`}</strong>
                ) : (
                  ""
                )}
              </span>
              <span className="w-80 form-label text-center fs-12px text-soft">
                (There After you will be Directed to Step 2 Where Customer Property(s) Information is to be Entered.)
              </span>
            </div>
          </Col>
          <Col xl="12" className="mb-3 mt-3">
            <div className="form-group">
              <div className="form-control-wrap-flex-wrap justify-center">
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  size="lg"
                  disabled={loading ? loading : !formData.phone_number}
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Generate Unique ID"}
                </Button>
              </div>
            </div>
          </Col>
        </form>
      </Card>
    </>
  );
};

export default GenerateCustomerIDStep;
