// Feedback Messages
export const SUCCESS = "SUCCESS";
export const LOADING = "LOADING";
export const FAIL = "FAIL";
export const IDLE = "IDLE";
export const PAGE_ACCESS_DENIED = "PAGE_ACCESS_DENIED";

export const SEND_OTP_MOBILE = "SEND_OTP_MOBILE";
export const SEND_OTP_EMAIL = "SEND_OTP_EMAIL";
export const PAGE_INACTIVE = "PAGE_INACTIVE";
export const PAGE_UNDER_MAINTENACNE = "PAGE_UNDER_MAINTENACNE";
export const INDEX = "INDEX";
export const USER_LIST_TYPE = "USER_LIST_TYPE";
export const ACTIVATE_ACCOUNT_ID = 1;
export const SUSPEND_ACCOUNT_ID = 2;
export const DEACTIVATE_ACCOUNT_ID = 3;
export const ACTIVATE_ACCOUNT = "Activate";
export const SUSPEND_ACCOUNT = "Suspend";
export const DEACTIVATE_ACCOUNT = "Deactivate";
export const BUTTON_OPEN_PAGE_TYPE = "BUTTON_OPEN_PAGE_TYPE";
export const BUTTON_OPEN_MODAL_TYPE = "BUTTON_OPEN_MODAL_TYPE";
export const ASSIGN_USER_ACCOUNTS = "ASSIGN_USER_ACCOUNT";
export const COMPLETE_KYC_REGISTRATION = "COMPLETE_KYC_REGISTRATION";
export const ASSIGN_CUSTOMER_ACCOUNT = "ASSIGN_CUSTOMER_ACCOUNT";
export const RAW = "RAW";
export const MAP = "MAP";
export const BRANCH_LOCATION_LIST = "BRANCH_LOCATION_LIST";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const PRODUCT_TYPE_LIST = "PRODUCT_TYPE_LIST";
export const UNIT_MEASUREMENT_LIST = "UNIT_MEASUREMENT_LIST";
export const ONBOARD_USER_PROPERTY = "ONBOARD_USER_PROPERTY";
export const ONBOARD_COMPANY = "ONBOARD_COMPANY";
export const ONBOARD_COMPANY_REPRESENTATIVE = "ONBOARD_COMPANY_REPRESENTATIVE";
export const ONBOARD_COMPANY_REPRESENTATIVE_ROLE = "ONBOARD_COMPANY_REPRESENTATIVE_ROLE";
export const WEIGH_BRIDGE_TICKET_LIST = "WEIGH_BRIDGE_TICKET_LIST";
export const COMPANY_USER_ACCOUNTS_LIST = "COMPANY_USER_ACCOUNTS_LIST";
export const CUSTOMER_USER_ACCOUNTS_LIST = "CUSTOMER_USER_ACCOUNTS_LIST";
export const TRUCK_USER_ACCOUNTS_LIST = "TRUCK_USER_ACCOUNTS_LIST";

// Wizard Types
export const CREATE_GOODS_RECEIVED_TICKET = "CREATE_GOODS_RECEIVED_TICKET";

// Consigner Types
export const UAG_CONSIGNER_TYPE = 1;
export const COMPANY_CONSIGNER_TYPE = 2;
export const CUSTOMER_CONSIGNER_TYPE = 3;

// Refresh Lists
export const REFRESH_USER_ACCOUNTS = "REFRESH_USER_ACCOUNTS";

// Default Country Code
export const DEFAULT_ISO_CODE_2 = "ZM";

// Image Type
export const IMAGE_SINGLE = "IMAGE_SINGLE";

// Preview Type
export const PREVIEW_ONBOARD_TRUCK = "PREVIEW_ONBOARD_TRUCK";
export const PREVIEW_ONBOARD_TRAILER = "PREVIEW_ONBOARD_TRAILER";
export const PREVIEW_ADD_BRANCH_LOCATION = "PREVIEW_ADD_BRANCH_LOCATION";

// API Request Methods
export const POST = "post";
export const GET = "get";

// Rselct Map Types
export const USER_MAP = "USER_MAP";
export const GENDER_MAP = "GENDER_MAP";
export const MARITAL_STATUS_MAP = "MARITAL_STATUS_MAP";
export const USER_TITLE_MAP = "USER_TITLE_MAP";
export const PROVINCES_MAP = "PROVINCES_MAP";
export const PRODUCT_TYPE_MAP = "PRODUCT_TYPE_MAP";
export const AUTH_ROUTES_MAP = "AUTH_ROUTES_MAP";
export const COMPANY_REPRESENTATIVE_ROLES_MAP = "COMPANY_REPRESENTATIVE_ROLES_MAP";
export const WEIGH_BRIDGE_TICKET_TYPE_MAP = "WEIGH_BRIDGE_TICKET_TYPE_MAP";

// Split Variable
export const FULLNAME_SPLIT = "FULLNAME_SPLIT";
export const PHONE_NUMBER_SPLIT = "PHONE_NUMBER_SPLIT";
export const FIRST_NAME_SPLIT = "FIRST_NAME_SPLIT";
export const SURNAME_SPLIT = "SURNAME_SPLIT";
