import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    active_users_list: { status: "idle" },
}

export const active_users_list_Slice = createSlice({
    name: "active_users_list",
    initialState,
    reducers: {
        success_active_users_list: (state, action) => {
            state.active_users_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_active_users_list: (state, action) => {
            state.active_users_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_active_users_list: (state) => {
            state.active_users_list = { status: app_consts.IDLE }
        },
        loading_active_users_list: (state) => {
            state.active_users_list = { status: app_consts.LOADING }
        },
    }
})

export const { success_active_users_list, fail_active_users_list, reset_active_users_list, loading_active_users_list } = active_users_list_Slice.actions;

export default active_users_list_Slice.reducer;