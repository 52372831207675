import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Spinner } from "reactstrap";
import { Button, Col, Icon, Row } from "../../../../../../../../components/Component";
import Dropzone from "react-dropzone";
import { compressAndConvertToBase64, compressImage, messageToast } from "../../../../../../../../utils/Utils";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import ModalImage from "../../../../../modal/modal_image/ModalImage";
import { useDispatch } from "react-redux";
import {
  fail_update_image_info_user_kyc,
  loading_update_image_info_user_kyc,
  reset_update_image_info_user_kyc,
  success_update_image_info_user_kyc,
} from "../../../../../../../../reducers/dashboard_reducers/user_kyc/update_image_info_user_kyc_Slice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  UPDATE_IMAGE_KYC_INFO_ACCESS_DENIED_MSG,
  UPDATE_IMAGE_KYC_INFO_ERR_MSG,
  UPDATE_IMAGE_KYC_INFO_SUCCESS_MSG,
} from "../../../../../../../../constants/success_error_messages_consts";
import { UPDATE_IMAGE_KYC_INFO } from "../../../../../../../../api/dashboard_api_data";
import { dashboard_actions_helper } from "../../../../../../../../actions/dashboard_actions_helper";

const initialState = { phone_number: "", id_front: "", id_back: "", profile_img: "" };
const initialPageImages = { id_front: "", id_back: "", profile_img: "" };

const ImageInfoStep = ({ togglestep, nextStep, data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorVal, setError] = useState("");

  const [id_front, setIdFront] = useState([]);
  const [id_back, setIdBack] = useState([]);
  const [profile_img, setProfImg] = useState([]);
  const [clickedImage, setClickedImage] = useState("");
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState("");
  const [page_images, setPageImages] = useState(initialPageImages);
  const [loading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(initialState);

  // Reading Data Passed Along
  useEffect(() => {
    if (data) {
      setFormData({ ...formData, ["phone_number"]: data.phone_number });
      setStep(togglestep);
    }
  }, [data, togglestep]);

  // Function to Handle Image Upload
  const handleDropChange = (acceptedFiles, set, type) => {
    // convert to base 64 and store in State
    const image = acceptedFiles[0];
    if (!image) {
      messageToast("Select valid image (.jpeg or .jpg).", app_consts.FAIL);
      return false;
    }
    if (!image.name.match(/\.(jpg|jpeg)$/)) {
      messageToast("Select valid image (.jpeg or .jpg).", app_consts.FAIL);
      return false;
    } else {
      compressAndConvertToBase64(image, type, (error, compressedBase64, compressedFile) => {
        if (error) {
          console.error(error);
          // Handle the error case
        } else {
          setFormData({ ...formData, [type]: compressedFile });
          setPageImages({ ...page_images, [type]: compressedBase64 });
          set(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        }
      });
    }
  };

  // Image Modal
  const viewImage = () => {
    setOpen(true);
  };

  // Submit Form
  const onFormSubmit = () => {
    // Update Address Info
    dispatch(
      dashboard_actions_helper(
        loading_update_image_info_user_kyc,
        success_update_image_info_user_kyc,
        fail_update_image_info_user_kyc,
        UPDATE_IMAGE_KYC_INFO_ERR_MSG,
        UPDATE_IMAGE_KYC_INFO_SUCCESS_MSG,
        app_consts.POST,
        UPDATE_IMAGE_KYC_INFO,
        formData,
        UPDATE_IMAGE_KYC_INFO_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to the Update NOK Reducer
  const update_image_info_user_kyc = useSelector(
    (state) => state.update_image_info_user_kyc.update_image_info_user_kyc
  );
  //
  useEffect(() => {
    if (update_image_info_user_kyc.status !== app_consts.IDLE) {
      if (update_image_info_user_kyc.status === app_consts.LOADING) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
        if (update_image_info_user_kyc.status === app_consts.FAIL) {
          messageToast(update_image_info_user_kyc.message, app_consts.FAIL);
          setError(update_image_info_user_kyc.message, app_consts.FAIL);
        } else if (update_image_info_user_kyc.status === app_consts.SUCCESS) {
          messageToast("Image Info Successfully Updated", app_consts.SUCCESS);
          navigate(`/dashboard/onboarding/user`);
        }
        dispatch(reset_update_image_info_user_kyc());
      }
    }
  }, [update_image_info_user_kyc]);

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Image Upload</CardTitle>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row className="g-4">
            <Col sm="6">
              <label className="form-label">Profile Image</label>
              <Dropzone
                onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setProfImg, "profile_img")}
                maxFiles={1}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                      <input {...getInputProps()} />
                      {profile_img.length === 0 && (
                        <div className="dz-message">
                          <span className="dz-message-text">Drag and drop file</span>
                          <span className="dz-message-or">or</span>
                          <Button color="primary">SELECT</Button>
                        </div>
                      )}
                      {profile_img.map((file) => (
                        <div key={file.name} className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                          <div className="dz-image">
                            <img src={file.preview} alt="preview" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                )}
              </Dropzone>
              {page_images.profile_img ? (
                <>
                  <div className="form-group mt-2">
                    <div className="form-control-wrap-flex-wrap justify-end">
                      <Button
                        onClick={(ev) => {
                          setClickedImage(page_images.profile_img);
                          viewImage();
                        }}
                        color="light"
                        outline
                        className="bg-white"
                        size="md"
                      >
                        View
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
            <Col sm="6">
              <label className="form-label">ID Front</label>
              <Dropzone
                onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setIdFront, "id_front")}
                maxFiles={1}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                      <input {...getInputProps()} />
                      {id_front.length === 0 && (
                        <div className="dz-message">
                          <span className="dz-message-text">Drag and drop file</span>
                          <span className="dz-message-or">or</span>
                          <Button color="primary">SELECT</Button>
                        </div>
                      )}
                      {id_front.map((file) => (
                        <div key={file.name} className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                          <div className="dz-image">
                            <img src={file.preview} alt="preview" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                )}
              </Dropzone>
              {page_images.id_front ? (
                <>
                  <div className="form-group mt-2">
                    <div className="form-control-wrap-flex-wrap justify-end">
                      <Button
                        onClick={(ev) => {
                          setClickedImage(page_images.id_front);
                          viewImage();
                        }}
                        color="light"
                        outline
                        className="bg-white"
                        size="md"
                      >
                        View
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
            <Col sm="6">
              <label className="form-label">ID Back</label>
              <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setIdBack, "id_back")} maxFiles={1}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                      <input {...getInputProps()} />
                      {id_back.length === 0 && (
                        <div className="dz-message">
                          <span className="dz-message-text">Drag and drop file</span>
                          <span className="dz-message-or">or</span>
                          <Button color="primary">SELECT</Button>
                        </div>
                      )}
                      {id_back.map((file) => (
                        <div key={file.name} className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                          <div className="dz-image">
                            <img src={file.preview} alt="preview" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                )}
              </Dropzone>
              {page_images.id_back ? (
                <>
                  <div className="form-group mt-2">
                    <div className="form-control-wrap-flex-wrap justify-end">
                      <Button
                        onClick={(ev) => {
                          setClickedImage(page_images.id_back);
                          viewImage();
                          console.log(page_images.id_back);
                        }}
                        color="light"
                        outline
                        className="bg-white"
                        size="md"
                      >
                        View
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
            <Col xl="12">
              <div className="form-group">
                <div className="form-control-wrap-flex-wrap justify-end">
                  <Button
                    onClick={onFormSubmit}
                    color="primary"
                    size="lg"
                    disabled={loading ? loading : !(formData.profile_img && formData.id_back && formData.id_front)}
                  >
                    {loading ? <Spinner size="sm" color="light" /> : "Finish"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
      {/* Image Modal */}
      <ModalImage
        modalOpen={open}
        setModalOpen={setOpen}
        img_type={app_consts.IMAGE_SINGLE}
        selectedImage={clickedImage}
      />
    </>
  );
};

export default ImageInfoStep;
