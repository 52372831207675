import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    login_otp_verify: { message: "idle" },
}

export const login_otp_verify_Slice = createSlice({
    name: "login_otp_verify",
    initialState,
    reducers: {
        success_login_otp_verify: (state, action) => {
            state.login_otp_verify = { status: true };
        },
        fail_login_otp_verify: (state, action) => {
            state.login_otp_verify = { status: false, message: action.payload };
        },
        reset_login_otp_verify: (state) => {
            state.login_otp_verify = { status: "idle" }
        },
        loading_login_otp_verify: (state) => {
            state.login_otp_verify = { status: "loading" }
        },
    }
})

export const { success_login_otp_verify, fail_login_otp_verify, reset_login_otp_verify, loading_login_otp_verify } = login_otp_verify_Slice.actions;

export default login_otp_verify_Slice.reducer;