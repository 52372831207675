import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Spinner } from "reactstrap";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../../../components/Component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { messageToast } from "../../../../../../../../utils/Utils";
import {
  fail_gender_type_list,
  loading_gender_type_list,
  reset_gender_type_list,
  success_gender_type_list,
} from "../../../../../../../../reducers/dashboard_reducers/gender/gender_type_list_Slice";
import {
  fail_update_basic_kyc_info,
  loading_update_basic_kyc_info,
  reset_update_basic_kyc_info,
  success_update_basic_kyc_info,
} from "../../../../../../../../reducers/dashboard_reducers/user_kyc/update_basic_kyc_info_Slice";
import { dashboard_actions_helper } from "../../../../../../../../actions/dashboard_actions_helper";
import {
  RETRIEVE_GENDER_TYPE_ACCESS_DENIED_MSG,
  RETRIEVE_GENDER_TYPE_ERR_MSG,
  RETRIEVE_GENDER_TYPE_SUCCESS_MSG,
  RETRIEVE_MARITAL_STATUS_ACCESS_DENIED_MSG,
  RETRIEVE_MARITAL_STATUS_ERR_MSG,
  RETRIEVE_MARITAL_STATUS_SUCCESS_MSG,
  RETRIEVE_USER_TITLE_ACCESS_DENIED_MSG,
  RETRIEVE_USER_TITLE_ERR_MSG,
  RETRIEVE_USER_TITLE_SUCCESS_MSG,
  UPDATE_BASIC_KYC_INFO_ACCESS_DENIED_MSG,
  UPDATE_BASIC_KYC_INFO_ERR_MSG,
  UPDATE_BASIC_KYC_INFO_SUCCESS_MSG,
} from "../../../../../../../../constants/success_error_messages_consts";
import {
  RETRIEVE_GENDER_TYPE_LIST,
  RETRIEVE_MARITAL_STATUS_LIST,
  RETRIEVE_USER_TITLE_LIST,
  UPDATE_BASIC_KYC_INFO,
} from "../../../../../../../../api/dashboard_api_data";
import {
  fail_marital_status_list,
  loading_marital_status_list,
  success_marital_status_list,
} from "../../../../../../../../reducers/dashboard_reducers/marital_status/marital_status_list_Slice";
import {
  fail_user_title_list,
  loading_user_title_list,
  success_user_title_list,
} from "../../../../../../../../reducers/dashboard_reducers/user_title/user_title_list_Slice";

const initialState = { gender_type_id: "", marital_status_id: "", user_title_id: "", phone_number: "" };

const BasicInfoStep = ({ togglestep, nextStep, data }) => {
  const [errorVal, setError] = useState("");
  const [step, setStep] = useState(togglestep);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialState);

  const [gender, setGender] = useState([]);
  const [marital_status, setMaritalStatus] = useState([]);
  const [title, setTitle] = useState([]);
  const [loading, setIsLoading] = useState(false);

  // Only Run Code When Step is equal to One - 1
  useEffect(() => {
    if (togglestep === 1) {
      // Retrieve DropDown Data
      // Gender
      dispatch(
        dashboard_actions_helper(
          loading_gender_type_list,
          success_gender_type_list,
          fail_gender_type_list,
          RETRIEVE_GENDER_TYPE_ERR_MSG,
          RETRIEVE_GENDER_TYPE_SUCCESS_MSG,
          app_consts.GET,
          RETRIEVE_GENDER_TYPE_LIST,
          null,
          RETRIEVE_GENDER_TYPE_ACCESS_DENIED_MSG,
          app_consts.MAP,
          app_consts.GENDER_MAP
        )
      );
      // Marital Status
      dispatch(
        dashboard_actions_helper(
          loading_marital_status_list,
          success_marital_status_list,
          fail_marital_status_list,
          RETRIEVE_MARITAL_STATUS_ERR_MSG,
          RETRIEVE_MARITAL_STATUS_SUCCESS_MSG,
          app_consts.GET,
          RETRIEVE_MARITAL_STATUS_LIST,
          null,
          RETRIEVE_MARITAL_STATUS_ACCESS_DENIED_MSG,
          app_consts.MAP,
          app_consts.MARITAL_STATUS_MAP
        )
      );
      // User Title
      dispatch(
        dashboard_actions_helper(
          loading_user_title_list,
          success_user_title_list,
          fail_user_title_list,
          RETRIEVE_USER_TITLE_ERR_MSG,
          RETRIEVE_USER_TITLE_SUCCESS_MSG,
          app_consts.GET,
          RETRIEVE_USER_TITLE_LIST,
          null,
          RETRIEVE_USER_TITLE_ACCESS_DENIED_MSG,
          app_consts.MAP,
          app_consts.USER_TITLE_MAP
        )
      );
      setStep(togglestep);
    }
  }, [togglestep]);

  // Reading Data Passed Along
  useEffect(() => {
    if (data) {
      setFormData({ ...formData, ["phone_number"]: data.phone_number });
    }
  }, [data]);

  // Listen to Changes in Gender List Retrieval
  const gender_type_list = useSelector((state) => state.gender_type_list.gender_type_list);
  //
  useEffect(() => {
    if (gender_type_list.status !== app_consts.IDLE && gender_type_list.status !== app_consts.LOADING) {
      if (gender_type_list.status === app_consts.FAIL) {
        messageToast(gender_type_list.message, app_consts.FAIL);
      } else if (gender_type_list.status === app_consts.SUCCESS) {
        setGender(gender_type_list.data);
      }
      dispatch(reset_gender_type_list());
    }
  }, [gender_type_list]);

  // Listen to Changes in Martial Statuses List Retrieval
  const marital_status_list = useSelector((state) => state.marital_status_list.marital_status_list);
  //
  useEffect(() => {
    if (marital_status_list.status !== app_consts.IDLE && marital_status_list.status !== app_consts.LOADING) {
      if (marital_status_list.status === app_consts.FAIL) {
        messageToast(marital_status_list.message, app_consts.FAIL);
      } else if (marital_status_list.status === app_consts.SUCCESS) {
        setMaritalStatus(marital_status_list.data);
      }
    }
  }, [marital_status_list]);

  // Listen to Changes in User Title List Retrieval
  const user_title_list = useSelector((state) => state.user_title_list.user_title_list);
  //
  useEffect(() => {
    if (user_title_list.status !== app_consts.IDLE && user_title_list.status !== app_consts.LOADING) {
      if (user_title_list.status === app_consts.FAIL) {
        messageToast(user_title_list.message, app_consts.FAIL);
      } else if (user_title_list.status === app_consts.SUCCESS) {
        setTitle(user_title_list.data);
      }
    }
  }, [user_title_list]);

  // Submit Form
  const submitBasicInfo = () => {
    setError("");
    // Update Basic Info
    dispatch(
      dashboard_actions_helper(
        loading_update_basic_kyc_info,
        success_update_basic_kyc_info,
        fail_update_basic_kyc_info,
        UPDATE_BASIC_KYC_INFO_ERR_MSG,
        UPDATE_BASIC_KYC_INFO_SUCCESS_MSG,
        app_consts.POST,
        UPDATE_BASIC_KYC_INFO,
        formData,
        UPDATE_BASIC_KYC_INFO_ACCESS_DENIED_MSG
      )
    );
  };
  // Listen to State of Basic Info
  const update_basic_kyc_info = useSelector((state) => state.update_basic_kyc_info.update_basic_kyc_info);
  //
  useEffect(() => {
    if (update_basic_kyc_info.status !== app_consts.IDLE) {
      if (update_basic_kyc_info.status === app_consts.LOADING) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
        if (update_basic_kyc_info.status === app_consts.FAIL) {
          messageToast(update_basic_kyc_info.message, app_consts.FAIL);
          setError(update_basic_kyc_info.message, app_consts.FAIL);
        } else if (update_basic_kyc_info.status === app_consts.SUCCESS) {
          messageToast("User Basic Info Successfully Updated", app_consts.SUCCESS);
          nextStep();
        }
        dispatch(reset_update_basic_kyc_info());
      }
    }
  }, [update_basic_kyc_info]);

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Basic Information</CardTitle>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <Col lg="6" className="mb-3">
              <div className="form-group">
                <label className="form-label" htmlFor="gender_type">
                  Gender
                </label>
                <div className="form-control-wrap">
                  <RSelect
                    options={gender}
                    className="w-100%"
                    id="gender_type"
                    onChange={(e) => setFormData({ ...formData, ["gender_type_id"]: e.value })}
                  />
                </div>
              </div>
            </Col>
            <Col lg="6" className="mb-3">
              <div className="form-group">
                <label className="form-label" htmlFor="marital_status">
                  Marital Status
                </label>
                <div className="form-control-wrap">
                  <RSelect
                    options={marital_status}
                    className="w-100%"
                    id="marital_status"
                    onChange={(e) => setFormData({ ...formData, ["marital_status_id"]: e.value })}
                  />
                </div>
              </div>
            </Col>
            <Col lg="6" className="mb-3">
              <div className="form-group">
                <label className="form-label" htmlFor="title">
                  Title
                </label>
                <div className="form-control-wrap">
                  <RSelect
                    options={title}
                    className="w-100%"
                    id="title"
                    onChange={(e) => setFormData({ ...formData, ["user_title_id"]: e.value })}
                  />
                </div>
              </div>
            </Col>
            <Col xl="12" className="mb-3">
              <div className="form-group">
                <div className="form-control-wrap-flex-wrap justify-end">
                  <Button
                    onClick={submitBasicInfo}
                    color="primary"
                    size="lg"
                    disabled={
                      loading
                        ? loading
                        : !(
                            formData.user_title_id &&
                            formData.gender_type_id &&
                            formData.marital_status_id &&
                            formData.phone_number
                          )
                    }
                  >
                    {loading ? <Spinner size="sm" color="light" /> : "Next"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default BasicInfoStep;
