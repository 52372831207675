import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    verify_user_kyc_status: { status: "idle" },
}

export const verify_user_kyc_status_Slice = createSlice({
    name: "verify_user_kyc_status",
    initialState,
    reducers: {
        success_verify_user_kyc_status: (state, action) => {
            state.verify_user_kyc_status = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_verify_user_kyc_status: (state, action) => {
            state.verify_user_kyc_status = { status: app_consts.FAIL, message: action.payload };
        },
        reset_verify_user_kyc_status: (state) => {
            state.verify_user_kyc_status = { status: app_consts.IDLE }
        },
        loading_verify_user_kyc_status: (state) => {
            state.verify_user_kyc_status = { status: app_consts.LOADING }
        },
        page_access_denied_verify_user_kyc_status: (state) => {
            state.verify_user_kyc_status = { status: app_consts.PAGE_ACCESS_DENIED }
        }
    }
})

export const { success_verify_user_kyc_status, fail_verify_user_kyc_status, reset_verify_user_kyc_status, loading_verify_user_kyc_status } = verify_user_kyc_status_Slice.actions;

export default verify_user_kyc_status_Slice.reducer;