import React from "react";
import ReadOnlyTableRow from "./ReadOnlyTableRow";
import { DataTableItem } from "../../../../../components/Component";

export const ReadOnlyProperyData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.property_id}>
                <ReadOnlyTableRow row_type="first_col" item={item.property_number} />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.property_name ? (
                      <>
                        {item.property_name.length > 20
                          ? `${item.property_name.substring(0, 20)}...`
                          : item.property_name}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.property_physical_address ? (
                      <>
                        {item.property_physical_address.length > 20
                          ? `${item.property_physical_address.substring(0, 20)}...`
                          : item.property_physical_address}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item.property_id}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

export const ReadOnlyCompanyRepData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.member_access_id}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={
                    item.first_name ? (
                      <>
                        {`${item.first_name} ${item.surname}`.length > 20
                          ? `${`${item.first_name} ${item.surname}`.substring(0, 20)}...`
                          : `${item.first_name} ${item.surname}`}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.phone_number ? (
                      <>
                        {item.phone_number.length > 20 ? `${item.phone_number.substring(0, 20)}...` : item.phone_number}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.company_representative_role_name ? (
                      <>
                        {item.company_representative_role_name.length > 20
                          ? `${item.company_representative_role_name.substring(0, 20)}...`
                          : item.company_representative_role_name}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item.member_access_id}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};
