import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import * as app_consts from "../../../../../constants/react_app_consts";

const ModalImage = ({ modalOpen, setModalOpen, img_type, selectedImage }) => {

    const [open, setIsOpen] = useState(modalOpen);
    const [type, setType] = useState("");
    const [image, setImage] = useState("");

    const toggle = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        setIsOpen(modalOpen);
    }, [modalOpen])

    useEffect(() => {
        setType(img_type)
    }, [img_type])

    useEffect(() => {
        setImage(selectedImage)
    }, [selectedImage])

    return (
        <>
            <Modal isOpen={open} toggle={toggle} size="large">
                <button type="button" className="mfp-close" onClick={toggle}>
                    ×
                </button>
                {
                    type === app_consts.IMAGE_SINGLE ?
                        <img className="w-100 rounded-top" style={{ height: "100%" }} src={image ? image : ""} alt="image" />
                        :
                        ""
                }
            </Modal>
        </>
    )
}

export default ModalImage