import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import PageHeading from "../../components/page_heading/PageHeading";
import { Alert } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewCard } from "../../../../components/Component";
import {
  fail_verify_user_kyc_status,
  loading_verify_user_kyc_status,
  reset_verify_user_kyc_status,
  success_verify_user_kyc_status,
} from "../../../../reducers/dashboard_reducers/user_kyc/verify_user_kyc_status_Slice";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../constants/react_app_consts";
import { useNavigate, useParams } from "react-router";
import ModalInfo from "../../components/modal/modal_info/ModalInfo";
import {
  RETRIEVE_USER_KYC_STATUS_ACCESS_DENIED_MSG,
  RETRIEVE_USER_KYC_STATUS_ERR_MSG,
  RETRIEVE_USER_KYC_STATUS_SUCCESS_MSG,
} from "../../../../constants/success_error_messages_consts";
import { RETRIEVE_USER_KYC_STATUS } from "../../../../api/dashboard_api_data";
import { dashboard_actions_helper } from "../../../../actions/dashboard_actions_helper";
import WizardComp from "../../components/wizard/WizardComp";

const AssignCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { phone_number } = useParams();

  const [errorVal, setError] = useState();
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [acc_info, setAccInfo] = useState([]);

  // Close Info Modal Function
  const modalInfoCloseFunction = () => {
    setOpenInfoModal(false);
    navigate(`/dashboard/onboarding/user`);
  };

  // Check if User KYC Status is Complete
  useEffect(() => {
    dispatch(
      dashboard_actions_helper(
        loading_verify_user_kyc_status,
        success_verify_user_kyc_status,
        fail_verify_user_kyc_status,
        RETRIEVE_USER_KYC_STATUS_ERR_MSG,
        RETRIEVE_USER_KYC_STATUS_SUCCESS_MSG,
        app_consts.POST,
        RETRIEVE_USER_KYC_STATUS,
        { phone_number: phone_number },
        RETRIEVE_USER_KYC_STATUS_ACCESS_DENIED_MSG
      )
    );
  }, []);

  // Listen to Changes in Verify User KYC Reg Status Reducer
  const verify_user_kyc_status = useSelector((state) => state.verify_user_kyc_status.verify_user_kyc_status);
  //
  useEffect(() => {
    if (verify_user_kyc_status.status !== app_consts.IDLE) {
      if (verify_user_kyc_status.status !== app_consts.LOADING) {
        if (verify_user_kyc_status.status === app_consts.FAIL) {
          setError(verify_user_kyc_status.message);
          navigate(`/dashboard/onboarding/user`);
        } else if (verify_user_kyc_status.status === app_consts.SUCCESS) {
          const res_data = verify_user_kyc_status.data;
          if (res_data.kyc_reg_complete) {
            setAccInfo(verify_user_kyc_status.data);
          } else if (!res_data.kyc_reg_complete) {
            setOpenInfoModal(true);
          }
        }
        // It will be Reseted In the Wizard
        // dispatch(reset_verify_user_kyc_status());
      }
    }
  }, [verify_user_kyc_status]);

  return (
    <>
      <Head title="Assign Customer Account" />
      <Content>
        <PageHeading
          title="Assign Customer Account"
          title_addition={
            acc_info.first_name ? `/ ${acc_info.first_name} ${acc_info.surname} - ${acc_info.phone_number}` : ""
          }
          description="Create Customer Account for User By Filling in and Submiting the form Below."
          backToText="Assign Account"
          backToLink={`/dashboard/onboarding/user/assign/${phone_number}`}
        />
        <WizardComp type={app_consts.ASSIGN_CUSTOMER_ACCOUNT} />
      </Content>

      {/* Open This Modal if User KYC is Incomplete or Pending Updates */}
      <ModalInfo
        modalDescription="Customer Account Cannot be Created Until User Status is Active and User KYC Registration Status is Complete."
        modalTitle="KYC Registration Not Complete"
        openInfoModal={openInfoModal}
        modalInfoCloseFunction={modalInfoCloseFunction}
        modalButtonText="Return"
      />
    </>
  );
};

export default AssignCustomer;
