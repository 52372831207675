import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    update_basic_kyc_info: { status: "idle" },
}

export const update_basic_kyc_info_Slice = createSlice({
    name: "update_basic_kyc_info",
    initialState,
    reducers: {
        success_update_basic_kyc_info: (state, action) => {
            state.update_basic_kyc_info = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_update_basic_kyc_info: (state, action) => {
            state.update_basic_kyc_info = { status: app_consts.FAIL, message: action.payload };
        },
        reset_update_basic_kyc_info: (state) => {
            state.update_basic_kyc_info = { status: app_consts.IDLE }
        },
        loading_update_basic_kyc_info: (state) => {
            state.update_basic_kyc_info = { status: app_consts.LOADING }
        },
    }
})

export const { success_update_basic_kyc_info, fail_update_basic_kyc_info, reset_update_basic_kyc_info, loading_update_basic_kyc_info } = update_basic_kyc_info_Slice.actions;

export default update_basic_kyc_info_Slice.reducer;