import moment from "moment";
import { DataTableItem } from "../../../../../components/Component";
import TableRow from "../table_components/TableRow";

export const UserListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.member_access_id}>
                <TableRow item={item} avatar type="rowMain" />
                <TableRow item={item.phone_number} type="rowNormal" />
                <TableRow item={item.email_address} type="rowNormal" />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                      ? "warning"
                      : item.account_status_id === 3
                      ? "danger"
                      : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  view_details
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Branch Location Rows
export const BranchLocationRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.branch_location_id}>
                <TableRow item={item} table_type={type} type="rowMain" />
                <TableRow
                  item={
                    item.branch_physical_address
                      ? item.branch_physical_address.length > 20
                        ? `${item.branch_physical_address.substring(0, 20)}...`
                        : item.branch_area
                      : ""
                  }
                  type="rowNormal"
                />
                <TableRow item={item.branch_mobile_number} type="rowNormal" />
                <TableRow item={item.province_name} type="rowNormal" />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                      ? "warning"
                      : item.account_status_id === 3
                      ? "danger"
                      : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  table_type={type}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

export const ProductRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.branch_location_id}>
                <TableRow item={item} type="rowMain" table_type={type} />
                <TableRow
                  item={
                    item.product_type
                      ? item.product_type.length > 20
                        ? `${item.product_type.substring(0, 20)}...`
                        : item.product_type
                      : ""
                  }
                  type="rowNormal"
                />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                      ? "warning"
                      : item.account_status_id === 3
                      ? "danger"
                      : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  table_type={type}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

export const ProductTypeRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.branch_location_id}>
                <TableRow item={item} table_type={type} type="rowMain" />
                <TableRow item={item.products_count} type="rowNormal" />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                      ? "warning"
                      : item.account_status_id === 3
                      ? "danger"
                      : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

export const UnitMeasurementRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.unit_measurement_id}>
                <TableRow item={item} type="rowMain" table_type={type} />
                <TableRow item={item.unit_measurement_abbrev} type="rowNormal" />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                      ? "warning"
                      : item.account_status_id === 3
                      ? "danger"
                      : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};
