import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  add_product_type: { status: "idle" },
};

export const add_product_type_Slice = createSlice({
  name: "add_product_type",
  initialState,
  reducers: {
    success_add_product_type: (state, action) => {
      state.add_product_type = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_add_product_type: (state, action) => {
      state.add_product_type = { status: app_consts.FAIL, message: action.payload };
    },
    reset_add_product_type: (state) => {
      state.add_product_type = { status: app_consts.IDLE };
    },
    loading_add_product_type: (state) => {
      state.add_product_type = { status: app_consts.LOADING };
    },
  },
});

export const { success_add_product_type, fail_add_product_type, reset_add_product_type, loading_add_product_type } =
  add_product_type_Slice.actions;

export default add_product_type_Slice.reducer;
