import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    create_trailer: { status: "idle" },
}

export const create_trailer_Slice = createSlice({
    name: "create_trailer",
    initialState,
    reducers: {
        success_create_trailer: (state, action) => {
            state.create_trailer = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_create_trailer: (state, action) => {
            state.create_trailer = { status: app_consts.FAIL, message: action.payload };
        },
        reset_create_trailer: (state) => {
            state.create_trailer = { status: app_consts.IDLE }
        },
        loading_create_trailer: (state) => {
            state.create_trailer = { status: app_consts.LOADING }
        },
    }
})

export const { success_create_trailer, fail_create_trailer, reset_create_trailer, loading_create_trailer } = create_trailer_Slice.actions;

export default create_trailer_Slice.reducer;