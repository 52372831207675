//REGEX for EMPTY or NULL input
export const EMPTYNULLREGEX = /^$|\s+/;

//REGEX for Zambian NRC
export const NRC_REGEX = /^([0-9]{6})\/([0-9]{2})\/([1-3]{1})$/;

//REGEX for Email
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Regex for Zambian Phone Number
export const ZAMIBAN_PHONE_NUMBER_REGEX = /^(26[0-9])(95|96|97|77|76|75)\d{7}$/;

// Regex for Checking if Characters Are Just a String With No Whitespace (Letters Only)
export const NOT_NULL_LETTERS_ONLY_STRING = /^[a-zA-Z]+$/;

// Regex for Checking if Characters have White Space (all Characters)
export const NO_WHITESPACE_LETTERS_NUMBERS_ONLY_REGEX = /^[a-zA-Z0-9]+$/;

// Regex for Numbers Only
export const NUMBERS_ONLY_REGEX = /^[0-9]+$/;

// Regex for Numbers Only
export const NO_WHITESPACE_ONLY_REGEX = /\s/;
