import { useEffect, useState } from "react";
import ListText from "../../../text/ListText";

// Preview Create User
export const CreateUserPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="First Name" value={data.first_name} />
          <ListText
            dataItem="data-item mySmall"
            label="Middle Name (Optional)"
            value={data.middle_name ? data.middle_name : "--"}
          />
          <ListText dataItem="data-item mySmall" label="Surname" value={data.surname} />
          <ListText dataItem="data-item mySmall" label="Email Address" value={data.email_address} />
          <ListText dataItem="data-item mySmall" label="Phone Number" value={data.phone_number} />
          <ListText dataItem="data-item mySmall" label="Date of Birth" value={data.dob} />
        </>
      )}
    </>
  );
};

// Preview Create Truck
export const CreateTruckPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="Truck License Plate" value={data.truck_license_plate} />
          <ListText dataItem="data-item mySmall" label="Ownership Status" value={data.ownership_status} />
        </>
      )}
    </>
  );
};

// Preview Create Trailer
export const CreateTrailerPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="Trailer License Plate" value={data.trailer_plate} />
          <ListText dataItem="data-item mySmall" label="Ownership Status" value={data.ownership_status} />
        </>
      )}
    </>
  );
};

// Preview Add Branch Location
export const CreateBranchLocationPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="Branch Area" value={data.branch_area} />
          <ListText
            dataItem="data-item mySmall"
            label="Bracnch Physical Address"
            value={data.branch_physical_address}
          />
          <ListText dataItem="data-item mySmall" label="Bracnch Mobile Number" value={data.branch_mobile_number} />
          <ListText dataItem="data-item mySmall" label="Branch Province Name" value={data.province_name} />
        </>
      )}
    </>
  );
};
