import React, { useEffect, useState } from 'react'
import { Alert, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import * as app_consts from "../../../../../constants/react_app_consts";
import { Button, Col, Icon } from '../../../../../components/Component';

const ModalConfirmCancel = ({ openModal, setOpenModal, type, modalTitle, modalDescription, succFunc, buttonConfirm, buttonCancel, errAmend, setErrAmend }) => {

    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorVal, setError] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        setError(errAmend)
        if (errAmend) {
            setLoading(false)
        }
    }, [errAmend])

    useEffect(() => {
        setModal(openModal)
        setErrAmend("");
        setLoading(false);
        setDescription(modalDescription)
        setTitle(modalTitle)
    }, [openModal])

    useEffect(() => {
        setDescription(modalDescription)
    }, [modalDescription])

    useEffect(() => {
        setTitle(modalTitle)
    }, [modalTitle])

    const toggleModal = () => {
        cancleClick()
    }

    // Cancel and Confirm Functions
    const confirmClick = () => {
        setLoading(true);
        succFunc();
        setErrAmend("");
    }

    const cancleClick = () => {
        setOpenModal(false);
        setErrAmend("");
    }

    return (
        <Modal isOpen={modal} toggle={!loading && (toggleModal)}>
            <ModalHeader
                toggle={!loading && (toggleModal)}
            >
                {title}
            </ModalHeader>
            <ModalBody>
                {errorVal && (
                    <div className="mb-3 w-100">
                        <Alert color="danger" className="alert-icon">
                            <Icon name="alert-circle" /> {errorVal}{" "}
                        </Alert>
                    </div>
                )}
                <p>{description}</p>
                <Col xl="12" className="d-flex justify-between mt-4 mr-2">
                    <Button onClick={cancleClick} disabled={loading} className="btn btn-dim btn-outline-secondary">
                        {
                            buttonCancel
                        }

                    </Button>
                    <Button onClick={confirmClick} disabled={loading} className="btn btn-dim btn-outline-danger">
                        {loading ? <Spinner size="sm" /> : buttonConfirm}
                    </Button>
                </Col>
            </ModalBody>
        </Modal>
    )
}

export default ModalConfirmCancel;