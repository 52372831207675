import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
  unit_measurement_list: { status: "idle" },
};

export const unit_measurement_list_Slice = createSlice({
  name: "unit_measurement_list",
  initialState,
  reducers: {
    success_unit_measurement_list: (state, action) => {
      state.unit_measurement_list = { status: app_consts.SUCCESS, data: action.payload };
    },
    fail_unit_measurement_list: (state, action) => {
      state.unit_measurement_list = { status: app_consts.FAIL, message: action.payload };
    },
    reset_unit_measurement_list: (state) => {
      state.unit_measurement_list = { status: app_consts.IDLE };
    },
    loading_unit_measurement_list: (state) => {
      state.unit_measurement_list = { status: app_consts.LOADING };
    },
  },
});

export const {
  success_unit_measurement_list,
  fail_unit_measurement_list,
  reset_unit_measurement_list,
  loading_unit_measurement_list,
} = unit_measurement_list_Slice.actions;

export default unit_measurement_list_Slice.reducer;
