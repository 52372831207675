const menu = [
  {
    heading: "Warehouse Management",
  },
  {
    text: "Weigh Bridge Tickets",
    link: "/dashboard/weigh_bridge_tickets",
    icon: "file-docs",
  },
  {
    text: "Warehouse Invetory",
    link: "/project-list",
    icon: "card-view",
  },
  {
    heading: "OnBoarding",
  },
  {
    icon: "plus-c",
    text: "Onboarding",
    active: false,
    subMenu: [
      {
        text: "User Onboarding",
        link: "/dashboard/onboarding/user",
        icon: "users",
      },
      {
        text: "Vehicle Onboarding",
        link: "/dashboard/onboarding/vehicle",
        icon: "truck",
      },
      {
        text: "Company Onboarding",
        link: "/dashboard/onboarding/company",
        icon: "building",
      },
    ],
  },
  {
    heading: "System Users",
  },
  {
    icon: "users",
    text: "Users",
    active: false,
    subMenu: [
      {
        text: "User List",
        link: "/dashboard/user",
        icon: "user-list",
      },
      {
        text: "User Account List",
        link: "/project-list",
        icon: "user-check-fill",
      },
    ],
  },
  {
    heading: "Admin Panel",
  },
  {
    icon: "account-setting",
    text: "Employees",
    active: false,
    subMenu: [
      {
        text: "Employees",
        link: "/dashboard/admin/employees",
        icon: "user-alt",
      },
    ],
  },
  {
    icon: "building",
    text: "Branch Locations",
    active: false,
    subMenu: [
      {
        text: "Branch Locations",
        link: "/dashboard/admin/branch_locations",
        icon: "building-fill",
      },
    ],
  },
  {
    icon: "note-add",
    text: "Products",
    active: false,
    subMenu: [
      {
        text: "Products",
        link: "/dashboard/admin/products",
        icon: "package-fill",
        // Remember to Put Custom Icon gi GiGrain
      },
      {
        text: "Product Types",
        link: "/dashboard/admin/product_types",
        icon: "package-fill",
        // Remember to Put Custom Icon gi GiGrain
      },
      {
        text: "Unit Measurements",
        link: "/dashboard/admin/unit_measurements",
        icon: "meter",
        // Remember to Put Custom Icon  FaScaleBalanced
      },
    ],
  },
];
export default menu;
